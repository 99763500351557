import { useTranslation } from 'react-i18next';

import RequestOfferFormTwo, { StepTwoProps } from '../form/request-offer-form-2';

function StepTwo(props: StepTwoProps) {
	const { t } = useTranslation();

	return (
		<div>
			<h4 className="text-[16px] sm:text-[20px] md:text-22 leading-[26px] text-[#3D3F40] mb-6 font-bold text-center">
				{t('your_personal_information')}
			</h4>
			<p className="text-center text-base md:text-lg leading-[26px] text-[#3D3F40] md:px-30">
				{t('enter_personal_information_description')}
			</p>
			<div className="bg-[#F4F5F8] p-[25px_15px_25px] md:p-[40px_40px_20px] rounded-[10px] mt-25 md:mt-10">
				<RequestOfferFormTwo {...props} />
			</div>
		</div>
	);
}

export default StepTwo;
