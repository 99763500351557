import cx from 'classnames';
import { useParams } from 'react-router-dom';

import { useGetCar } from 'src/api/car';
import CarSpec from 'src/components/auto-subscribe/car-spec';
import RequestStepsSection from 'src/components/auto-subscribe/request-steps-section';

function AutoSubscribe() {
	const { id, km, term } = useParams();

	const { car, carLoading } = useGetCar(id as string);

	if (carLoading) {
		return null;
	}

	return (
		<div className={cx('pt-100 bg-[#F4F5F8]', 'lg:pt-150')}>
			<CarSpec data={car} km={parseInt(km as string, 10)} term={parseInt(term as string, 10)} />
			<RequestStepsSection />
		</div>
	);
}

export default AutoSubscribe;
