import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import cx from 'classnames';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';

interface PhoneInputProps extends UseFormRegisterReturn {
	label: string;
	value?: string;
	wrapperClass?: string;
	isError?: boolean;
	isOptional?: boolean;
	placeholder?: string;
	onPhoneNumberChange?: (val: string) => void;
}

const PhoneNumberInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
	(
		{
			label,
			wrapperClass,
			isError,
			name,
			placeholder,
			isOptional,
			value,
			onPhoneNumberChange,
			...rest
		}: PhoneInputProps,
		ref
	) => (
		<div
			className={cx(
				'w-full bg-white rounded-15 pt-2.5 pb-5 px-15 flex flex-col items-start border',
				{ 'border-red-700': isError },
				wrapperClass
			)}>
			<label className="text-[#8D8E93] text-13">
				{label} {isOptional ? '(optional)' : ''}
			</label>
			<PhoneInput
				key={value !== undefined ? 'key' : 'undefined'}
				country={'ch'}
				specialLabel=""
				inputClass="!w-full  !border-none !shadow-none !py-[4.5px]"
				dropdownClass="!text-left"
				{...rest}
				onChange={(value) => {
					onPhoneNumberChange?.(value);
				}}
				inputProps={{ ref }}
			/>
		</div>
	)
);

PhoneNumberInput.displayName = 'PhoneNumberInput';

export default PhoneNumberInput;
