import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import Header from './header';
import Footer from './footer';

import CookieConsentModal from 'src/components/cookie-consent';

function Layout() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<div className="relative">
			<Header />
			<main>
				<Outlet />
			</main>
			<Footer />
			{/* <CookieConsentModal /> */}
		</div>
	);
}

export default Layout;
