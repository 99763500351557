import cx from 'classnames';

import AboutUsImage from 'src/assets/images/about-us.jpg';
import CircleCheck from 'src/assets/icons/circle-check.svg';

function AboutUsSection() {
	return (
		<section className="mb-50 lg:mb-0">
			<div className="custom-container">
				<div className={cx('flex flex-col items-center gap-30', 'lg:flex-row')}>
					<div className="lg:w-1/2">
						<div
							className={cx(
								'relative float-right lg:my-[60px] lg:mr-70 lg:w-[50vw]',
								'after:content-none lg:after:content-[""] after:h-full after:bg-[#fef2f6] after:absolute after:top-0 after:w-[50vw] after:-z-[1] after:-right-[20px] after:rotate-[8deg] after:rounded-e-[100px]'
							)}>
							<img src={AboutUsImage} className="rounded-[30px] lg:rounded-e-[100px]" />
						</div>
					</div>
					<div className="lg:w-1/2">
						<div className="mb-15">
							<h6 className={cx('inline-block text-lg font-semibold text-primary', 'lg:text-22')}>
								About Us
							</h6>
							<h2
								className={cx(
									'text-22 lg:text-30 md:text-[40px] font-extrabold leading-tight text-[#141752] mb-[20px] font-red-hat',
									'lg:text-6xl'
								)}>
								Build Your Business Website Better
							</h2>
							<p className="text-15 leading-6 text-[#5e5e5e]">
								Proin laoreet leo vel enim gravida, at porttitor metus ultricies. Cras eu velit
								enim. Vivamus blandit, dolor ut aliquet rutrum, ex elit mattis sapien, non molestie
								felis neque et nulla. Sed euismod turpis id nibh suscipit semper. Pellentesque
								dapibus risus arcu.
							</p>
						</div>
						<div>
							<div className="flex items-center gap-15 py-2 px-5 text-[#5e5e5e]">
								<img className="size-6" src={CircleCheck} />
								<span>Proin laoreet leo vel enim gravida at porttitor metus ultricies. </span>
							</div>
							<div className="flex items-center gap-15 py-2 px-5 text-[#5e5e5e]">
								<img className="size-6" src={CircleCheck} />
								<span>Proin laoreet leo vel enim gravida at porttitor metus ultricies. </span>
							</div>
							<div className="flex items-center gap-15 py-2 px-5 text-[#5e5e5e]">
								<img className="size-6" src={CircleCheck} />
								<span>Proin laoreet leo vel enim gravida at porttitor metus ultricies. </span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default AboutUsSection;
