import { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

interface Props {
	id: string;
}

function ScrollButtons({ id }: Props) {
	const [toRight, setToRight] = useState(true);

	function handleScrollLeft() {
		const element = document.getElementById(id);
		if (element) {
			element.scrollTo({ left: 0, behavior: 'smooth' });
			setToRight(true);
		}
	}

	function handleScrollRight() {
		const element = document.getElementById(id);
		if (element) {
			const left = element.scrollWidth;
			element.scrollTo({ left, behavior: 'smooth' });
			setToRight(false);
		}
	}

	return (
		<div className="md:hidden">
			{!toRight ? (
				<span
					className="absolute top-1/2 bottom-0 flex items-center justify-center size-10 rounded-full bg-white shadow-[0px_0px_5px_rgba(0,0,0,0.5)] text-primary text-14 -mt-[21px]"
					onClick={handleScrollLeft}>
					<FaArrowLeft />
				</span>
			) : (
				<span
					className="absolute top-1/2 bottom-0 flex items-center justify-center size-10 rounded-full bg-white shadow-[0px_0px_5px_rgba(0,0,0,0.5)] text-primary text-14 -mt-[21px] right-0"
					onClick={handleScrollRight}>
					<FaArrowRight />
				</span>
			)}
		</div>
	);
}

export default ScrollButtons;
