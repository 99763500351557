import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import ServiceImage1 from 'src/assets/images/simpcar-auto-abo-versicherung.webp';
import ServiceImage2 from 'src/assets/images/simpcar-auto-abo-strassenverkehrssteuer.webp';
import ServiceImage3 from 'src/assets/images/simpcar-auto-abo-service-werkstatt.webp';
import ServiceImage4 from 'src/assets/images/simpcar-auto-abo-bereifung.webp';
import ServiceImage5 from 'src/assets/images/simpcar-auto-abo-zulassung.webp';
import ServiceImage6 from 'src/assets/images/simpcar-auto-abo-autobahnvignette.webp';

interface Props {
	image: string;
	alt: string;
	title: string;
	label: string;
	description: string;
}

function ServiceCard({ image, alt, title, label, description }: Props) {
	return (
		<div
			className={cx(
				'flex items-center flex-col text-center rounded-14 shadow-[0_2px_48px_#00000014] p-7 lg:p-50',
				'group hover:bg-primary-100'
			)}>
			<img src={image} alt={alt} title={title} className="w-1/3 mb-5 lg:mb-50" />
			<h4 className="mb-1 text-xl font-bold group-hover:text-primary">{label}</h4>
			<p className="text-base text-[#8D8E93] leading-5 group-hover:text-primary">{description}</p>
		</div>
	);
}

function ServiceSection() {
	const { t } = useTranslation();

	const services: Props[] = [
		{
			image: ServiceImage1,
			alt: 'Auto Abo mit Versicherung',
			title: 'Auto Abo mit Versicherung',
			label: t('car_insurance'),
			description: t('vehicle_fully_comprehensive_insured_protected')
		},
		{
			image: ServiceImage2,
			alt: 'Strassenverkehrssteuer',
			title: 'Strassenverkehrssteuer',
			label: t('car_tax'),
			description: t('we_pay_tax')
		},
		{
			image: ServiceImage3,
			alt: 'Auto Abo Servie und Wartung',
			title: 'Auto Abo Servie und Wartung',
			label: t('service_and_maintenance'),
			description: t('we_care_carry_out_service_maintenance')
		},
		{
			image: ServiceImage4,
			alt: 'Auto Abo Reifen',
			title: 'Auto Abo Reifen',
			label: t('tire_service'),
			description: t('summer_winter_always_safe')
		},
		{
			image: ServiceImage5,
			alt: 'Auto Abo mit Strassenzulassung',
			title: 'Auto Abo mit Strassenzulassung',
			label: t('registration'),
			description: t('license_plates_vehicle_registration_certificate')
		},
		{
			image: ServiceImage6,
			alt: 'Autobahnvignette',
			title: 'Autobahnvignette',
			label: t('vignette'),
			description: t('every_year_we_provide_motorway_vignette_anew')
		}
	];

	return (
		<section>
			<h2 className="text-center text-22 lg:text-33 text-[#3e4143] mb-2 font-red-hat font-medium leading-tight">
				{t('your')} <span className="text-primary">{t('car_space_subscription')}</span>{' '}
				{t('all_in_one_worry_free')}
			</h2>
			<h3 className="text-center mt-2.5 text-base lg:text-lg text-[#0C111F] font-red-hat">
				{t('everything_except_fuel')}
			</h3>
			<p className="text-center text-15 w-full max-w-[980px] mt-7 lg:mt-10 mb-50 px-4 leading-6 mx-auto">
				{t('enjoy_limitless_freedom_sentence')}
			</p>
			<div className="grid grid-cols-1 lg:grid-cols-3">
				{services.map((item, index) => (
					<div className="mb-6 lg:px-15 lg:mb-50" key={index}>
						<ServiceCard
							image={item.image}
							alt={item.alt}
							title={item.title}
							label={item.label}
							description={item.description}
						/>
					</div>
				))}
			</div>
		</section>
	);
}

export default ServiceSection;
