import ReactDatePicker from 'react-datepicker';
import cx from 'classnames';
import { FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaAngleDoubleLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { registerLocale } from 'react-datepicker';
import { de, fr, es } from 'date-fns/locale';
registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('en', es);

import 'react-datepicker/dist/react-datepicker.css';
import './date-picker.css';

import CalendarIcon from 'src/assets/icons/calendar-icon.svg';

interface Props {
	wrapperClassName?: string;
	label: string;
	from?: Date;
	value?: Date;
	onChange?: (val: Date) => void;
}

function range(start: number, end: number, interval: number) {
	let result = [];
	for (let i = start; i <= end; i += interval) {
		result.push(i);
	}
	return result;
}

function DatePicker({ label, wrapperClassName, from, value, onChange }: Props) {
	const { t, i18n } = useTranslation();
	const lng = i18n.language;

	const fromMonth = from?.getMonth() ?? 0;
	const fromYear = from?.getFullYear() ?? 2000;
	const months = [
		t('january'),
		t('february'),
		t('march'),
		t('april'),
		t('may'),
		t('june'),
		t('july'),
		t('august'),
		t('september'),
		t('october'),
		t('november'),
		t('december')
	];

	const today = new Date();
	const btnClass =
		'bg-primary text-white text-sm rounded-lg border border-gray-100 px-5 disabled:opacity-50';

	const isWeekday = (date: Date) => {
		const day = date.getDay();

		return day !== 0;
	};

	return (
		<div
			className={cx(
				'relative flex flex-col w-full p-[10px_15px_5px] rounded-15 border border-[#8D8E93]',
				wrapperClassName
			)}>
			<label className="text-[#8D8E93] text-13">{label}</label>
			<ReactDatePicker
				className="py-5 w-full text-base font-bold text-[#8D8E93] outline-none bg-transparent"
				popperClassName="custom-date-picker-popper"
				excludeDateIntervals={[{ start: new Date(1970, 1, 1), end: today }]}
				locale={lng}
				filterDate={isWeekday}
				dateFormat="dd.MM.yyyy"
				renderCustomHeader={({
					date,
					changeMonth,
					decreaseYear,
					increaseYear,
					decreaseMonth,
					increaseMonth,
					prevMonthButtonDisabled,
					nextMonthButtonDisabled
				}) => (
					<div className="flex justify-between gap-5 pt-[20px] pb-2.5 px-15">
						<button
							className={btnClass}
							onClick={() => {
								if (date.getFullYear() === fromYear + 1 && date.getMonth() < fromMonth) {
									changeMonth(fromMonth);
								}
								decreaseYear();
							}}
							disabled={prevMonthButtonDisabled || date.getFullYear() <= fromYear}>
							<FaAngleDoubleLeft />
						</button>
						<button
							className={btnClass}
							onClick={decreaseMonth}
							disabled={
								prevMonthButtonDisabled ||
								(date.getMonth() <= fromMonth && date.getFullYear() === fromYear) ||
								date.getMonth() < 1
							}>
							<FaAngleLeft />
						</button>

						<div className="px-1 py-[1px] rounded-5 text-primary text-base flex-1 mx-5 text-center font-bold flex gap-5">
							<select
								className="bg-transparent text-primary outline-none text-center hover:animate-pulse"
								value={months[date.getMonth()]}
								onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
								{months.slice(date.getFullYear() > fromYear ? 0 : fromMonth).map((option) => (
									<option key={option} value={option}>
										{option}
									</option>
								))}
							</select>
							<span>{date.getFullYear()}</span>
						</div>

						<button
							className={btnClass}
							onClick={increaseMonth}
							disabled={nextMonthButtonDisabled || date.getMonth() >= 11}>
							<FaAngleRight />
						</button>

						<button className={btnClass} onClick={increaseYear}>
							<FaAngleDoubleRight />
						</button>
					</div>
				)}
				selected={value}
				onChange={(date: Date) => {
					onChange?.(date);
				}}
			/>

			<CalendarIcon className="absolute top-1/2 -translate-y-1/2 right-[20px]" />
		</div>
	);
}

export default DatePicker;
