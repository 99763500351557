import TopSection from 'src/components/contact/top-section';
import ContactDetailsSection from 'src/components/contact/contact-details-section';
import ContactFormSection from 'src/components/contact/contact-form-section';

function Contact() {
	return (
		<>
			<TopSection />
			<ContactDetailsSection />
			<ContactFormSection />
		</>
	);
}

export default Contact;
