import { useEffect, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import CheckIcon from 'src/assets/icons/check-icon.svg';
import ExclamationMarkCircleIcon from 'src/assets/icons/exclamation-mark-circle.svg';
import DatePicker from '../date-picker';
import { ICarItem } from 'src/types/car';
import { fDate } from 'src/utils/date';
import { isAfter } from 'date-fns';

export interface StepOneData {
	deliveryMode?: 'not_deliver' | 'deliver';
	deliveryDate?: Date;
}

interface DeliverItemProps {
	checked?: boolean;
	label: string;
	description: string;
}

interface Props {
	submitted?: boolean;
	formData?: StepOneData;
	data: ICarItem;
	onChange?: (val: StepOneData) => void;
}

function DeliverItem({ checked, label, description }: DeliverItemProps) {
	return (
		<div
			className={cx(
				'bg-[#F4F5F8] rounded-10 h-[150px] w-[94%] flex justify-center items-center flex-col text-center mx-[2%] cursor-pointer',
				{ 'border-2 border-primary !bg-primary-100': checked },
				'hover:bg-primary-100',
				'md:h-[235px]'
			)}>
			<span className="size-26 bg-primary rounded-full p-1 text-white flex items-center justify-center mb-30">
				{checked && <CheckIcon />}
			</span>
			<div className={cx('text-15 font-semibold text-[#3D3F40] mb-15', 'md:text-lg')}>{label}</div>
			<div className="text-15 md:text-lg">{description}</div>
		</div>
	);
}

function StepOne({ submitted, formData, data, onChange }: Props) {
	const { t } = useTranslation();

	const { availableFrom, make, model } = data;

	const [deliveryMode, setDeliveryMode] = useState<'not_deliver' | 'deliver' | undefined>(
		formData?.deliveryMode
	);
	const [deliveryDate, setDeliveryDate] = useState<Date | undefined>(formData?.deliveryDate);

	useEffect(() => {
		onChange?.({ deliveryMode: deliveryMode, deliveryDate: deliveryDate });
	}, [deliveryDate, deliveryMode]);

	return (
		<div>
			<h4
				className={cx(
					'text-[16px] leading-[26px] text-[#3D3F40] mb-6 font-bold text-center',
					'sm:text-[20px]',
					'md:text-22'
				)}>
				{t('handle_over_your_car')}
			</h4>
			<p
				className={cx('text-center text-15 leading-[26px] text-[#3D3F40] ', 'md:text-lg md:px-30')}>
				{t('car_delivery_description')}
			</p>
			<div className="text-center flex items-center justify-center my-50 mx-auto flex-wrap max-w-600">
				<div
					className="w-1/2"
					onClick={() => {
						setDeliveryMode('not_deliver');
					}}>
					<DeliverItem
						checked={deliveryMode === 'not_deliver'}
						label={t('pick_up_in_sariswil')}
						description={`(${t('for_free')})`}
					/>
				</div>
				<div
					className="w-1/2"
					onClick={() => {
						setDeliveryMode('deliver');
					}}>
					<DeliverItem
						checked={deliveryMode === 'deliver'}
						label={t('home_delivery')}
						description={`(${t('one_off_180_chf')})`}
					/>
				</div>
				{submitted && !deliveryMode && (
					<span className="mt-15 text-danger ">{t('required_field')}</span>
				)}
			</div>
			<h4
				className={cx(
					'text-[20px] leading-[26px] text-[#3D3F40] mb-6 font-bold text-center',
					'md:text-22'
				)}>
				{t('preferred_data_hand_over')}
			</h4>
			{availableFrom && isAfter(availableFrom, new Date()) && (
				<p
					className={cx(
						'flex md:items-center justify-center gap-5 md:gap-15 text-center text-15 leading-26 text-[#3D3F40] md:px-30',
						'md:text-lg'
					)}>
					<span className="size-18">
						<ExclamationMarkCircleIcon className="size-18" />
					</span>
					{t('your_car_available_at', { car: `${make} ${model}` })}: {fDate(availableFrom)}
				</p>
			)}
			<div>
				<DatePicker
					label={t('select_handover_date')}
					wrapperClassName="mt-10 max-w-[350px] mx-auto"
					from={new Date()}
					value={deliveryDate}
					onChange={(val) => {
						setDeliveryDate(val);
					}}
				/>
				{submitted && !deliveryDate && (
					<div className="mt-15 text-danger text-center">{t('required_field')}</div>
				)}
			</div>
		</div>
	);
}

export default StepOne;
