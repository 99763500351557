import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

interface SelectItemProps {
	label: string;
	value: string | number;
}

interface SelectProps extends UseFormRegisterReturn {
	label: string;
	wrapperClass?: string;
	isError?: boolean;
	isOptional?: boolean;
	data: SelectItemProps[];
}

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
	({ label, wrapperClass, isError, name, isOptional, data, ...rest }: SelectProps, ref) => {
		const { t } = useTranslation();

		return (
			<div
				className={cx(
					'w-full bg-white rounded-15 pt-2.5 pb-5 px-15 flex flex-col items-start border',
					{ 'border-red-700': isError },
					wrapperClass
				)}>
				<label className="text-[#8D8E93] text-13">
					{label} {isOptional ? '(optional)' : ''}
				</label>
				<select
					className="w-full border-none bg-none text-base font-bold text-[#8D8E93] py-5 outline-none"
					id={name}
					name={name}
					{...rest}
					ref={ref}>
					<option value="">{t('select')}</option>
					{data.map((item, index) => (
						<option key={index} value={item.value}>
							{item.label}
						</option>
					))}
				</select>
			</div>
		);
	}
);

Select.displayName = 'select';

export default Select;
