import { useTranslation } from 'react-i18next';

function Imprint() {
	const { t } = useTranslation();

	return (
		<div className="imprint">
			<div className="h-400 bg-[#edf3e1]">
				<div className="custom-container h-full">
					<div className="flex justify-center items-center h-full">
						<h1 className="text-32 mb-2.5 pt-24 font-bold text-[#3e4143] font-red-hat">
							{t('impressum')}
						</h1>
					</div>
				</div>
			</div>
			<div className="bg-white">
				<div className="text-[#555] py-20 custom-container">
					<div className="w-full container-80 mx-auto">
						<p className="text-15">
							<b>
								{t('responsible_for_website')} <span className="text-primary">v2.simpcar.ch</span>
							</b>
							<br />
							<br />
							<b>{t('company_name')}:</b> simpcar ag
							<br />
							<b>{t('address')}:</b> Sägeweg 30, 3283 Kallnach
							<br />
							<b>UID:</b> CHE-406.237.689
							<br />
							<b>{t('managing_director')}:</b> Ismail Ismajli
							<br />
							<br />
							<b>{t('telephone')}:</b>{' '}
							<a href="tel:+41315582500" className="text-primary">
								031 558 25 00
							</a>
							<br />
							<b>{t('email')}:</b>{' '}
							<a href="mailto:info@simpcar.ch" className="text-primary">
								info@simpcar.ch
							</a>
							<br />
						</p>
						<h2 className="text-27 font-red-hat text-[#3e4143] mb-2 mt-12">{t('disclaimer1')}</h2>
						<p className="mb-[20px] mt-4 text-15">{t('imprint_text_1')}</p>
						<h2 className="text-27 font-red-hat text-[#3e4143] mb-2 mt-12">{t('copyright')}</h2>
						<p className="mb-[20px] mt-4 text-15">{t('imprint_text_2')}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Imprint;
