import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useTranslation } from 'react-i18next';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import './packages-card.css';

import ServiceImage1 from 'src/assets/images/simpcar-auto-abo-versicherung.webp';
import ServiceImage2 from 'src/assets/images/simpcar-auto-abo-strassenverkehrssteuer.webp';
import ServiceImage3 from 'src/assets/images/simpcar-auto-abo-service-werkstatt.webp';
import ServiceImage4 from 'src/assets/images/simpcar-auto-abo-bereifung.webp';
import ServiceImage5 from 'src/assets/images/simpcar-auto-abo-zulassung.webp';
import ServiceImage6 from 'src/assets/images/simpcar-auto-abo-autobahnvignette.webp';

interface Props {
	image: string;
	label: string;
	description: string;
	alt: string;
	title: string;
}

function PackagesCard() {
	const { t } = useTranslation();

	const services: Props[] = [
		{
			image: ServiceImage1,
			alt: 'Auto Abo mit Versicherung',
			title: 'Auto Abo mit Versicherung',
			label: t('car_insurance'),
			description: t('vehicle_fully_comprehensive_insured_protected')
		},
		{
			image: ServiceImage2,
			alt: 'Strassenverkehrssteuer',
			title: 'Strassenverkehrssteuer',
			label: t('car_tax'),
			description: t('we_pay_tax')
		},
		{
			image: ServiceImage3,
			alt: 'Auto Abo Servie und Wartung',
			title: 'Auto Abo Servie und Wartung',
			label: t('service_and_maintenance'),
			description: t('we_care_carry_out_service_maintenance')
		},
		{
			image: ServiceImage4,
			alt: 'Auto Abo Reifen',
			title: 'Auto Abo Reifen',
			label: t('tire_service'),
			description: t('summer_winter_always_safe')
		},
		{
			image: ServiceImage5,
			alt: 'Auto Abo mit Strassenzulassung',
			title: 'Auto Abo mit Strassenzulassung',
			label: t('registration'),
			description: t('license_plates_vehicle_registration_certificate')
		},
		{
			image: ServiceImage6,
			alt: 'Autobahnvignette',
			title: 'Autobahnvignette',
			label: t('vignette'),
			description: t('every_year_we_provide_motorway_vignette_anew')
		}
	];

	return (
		<div className="pb-12 bg-white rounded-14">
			<Swiper
				className="packages-swiper"
				slidesPerView={1}
				spaceBetween={50}
				navigation={true}
				modules={[Navigation]}>
				{services.map((item, index) => (
					<SwiperSlide key={index}>
						<div className="p-[20px] bg-transparent flex flex-col justify-center items-center gap-[20px] text-center">
							<span className="text-[#23262D] text-19 md:text-22 font-bold mb-50">
								{item.label}
							</span>
							<img className="max-w-[105px]" src={item.image} alt={item.alt} title={item.title} />
							<p className="mt-30 mb-[20px]">{item.description}</p>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}

export default PackagesCard;
