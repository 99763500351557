import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import GreenlineImage from 'src/assets/images/green_line_075.svg';

function TopSection() {
	const { t, i18n } = useTranslation();
	const lng = i18n.language;

	return (
		<div className={cx('bg-primary-100 h-80', 'sm:h-400')}>
			<div className="custom-container h-full">
				<div className="flex items-center justify-center h-full">
					<div className="pt-24">
						{lng === 'de' && (
							<h1
								className={cx(
									'text-xl mb-2.5 font-red-hat font-normal text-[#3e4143] text-center',
									'sm:text-32 sm:font-bold'
								)}>
								Auto Abo mit{' '}
								<span className="text-primary relative">
									simpcar
									<img
										className="absolute w-full left-0 -bottom-[8px]"
										src={GreenlineImage}
										alt="green_line"
										title="green_line"
									/>
								</span>
							</h1>
						)}
						{lng === 'en' && (
							<h1
								className={cx(
									'text-xl mb-2.5 font-red-hat font-normal text-[#3e4143] text-center',
									'sm:text-32 sm:font-bold'
								)}>
								Car subscription with{' '}
								<span className="text-primary relative">
									simpcar
									<img
										className="absolute w-full left-0 -bottom-[8px]"
										src={GreenlineImage}
										alt="green_line"
										title="green_line"
									/>
								</span>
							</h1>
						)}

						{lng === 'fr' && (
							<h1
								className={cx(
									'text-xl mb-2.5 font-red-hat font-normal text-[#3e4143] text-center',
									'sm:text-32 sm:font-bold'
								)}>
								Abonnement voiture avec{' '}
								<span className="text-primary relative">
									simpcar
									<img
										className="absolute w-full left-0 -bottom-[8px]"
										src={GreenlineImage}
										alt="green_line"
										title="green_line"
									/>
								</span>
							</h1>
						)}
						<div className="leading-8 text-center">{t('we_put_line_under_it')}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TopSection;
