import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import './packages-section.css';

import ServiceImage1 from 'src/assets/images/simpcar-auto-abo-versicherung.webp';
import ServiceImage2 from 'src/assets/images/simpcar-auto-abo-strassenverkehrssteuer.webp';
import ServiceImage3 from 'src/assets/images/simpcar-auto-abo-service-werkstatt.webp';
import ServiceImage4 from 'src/assets/images/simpcar-auto-abo-bereifung.webp';
import ServiceImage5 from 'src/assets/images/simpcar-auto-abo-zulassung.webp';
import ServiceImage6 from 'src/assets/images/simpcar-auto-abo-autobahnvignette.webp';
import { useState } from 'react';

interface Props {
	image: string;
	alt: string;
	title: string;
	label: string;
	description: string;
}

function PackagesSection() {
	const { t } = useTranslation();
	const [selected, setSelected] = useState(0);

	const services: Props[] = [
		{
			image: ServiceImage1,
			alt: 'Auto Abo mit Versicherung',
			title: 'Auto Abo mit Versicherung',
			label: t('car_insurance'),
			description: t('vehicle_fully_comprehensive_insured_protected')
		},
		{
			image: ServiceImage2,
			alt: 'Strassenverkehrssteuer',
			title: 'Strassenverkehrssteuer',
			label: t('car_tax'),
			description: t('we_pay_tax')
		},
		{
			image: ServiceImage3,
			alt: 'Auto Abo Servie und Wartung',
			title: 'Auto Abo Servie und Wartung',
			label: t('service_and_maintenance'),
			description: t('we_care_carry_out_service_maintenance')
		},
		{
			image: ServiceImage4,
			alt: 'Auto Abo Reifen',
			title: 'Auto Abo Reifen',
			label: t('tire_service'),
			description: t('summer_winter_always_safe')
		},
		{
			image: ServiceImage5,
			alt: 'Auto Abo mit Strassenzulassung',
			title: 'Auto Abo mit Strassenzulassung',
			label: t('registration'),
			description: t('license_plates_vehicle_registration_certificate')
		},
		{
			image: ServiceImage6,
			alt: 'Autobahnvignette',
			title: 'Autobahnvignette',
			label: t('vignette'),
			description: t('every_year_we_provide_motorway_vignette_anew')
		}
	];

	return (
		<section className="mb-10 lg:py-50 lg:mb-0">
			<div className="custom-container">
				<div className={cx('flex flex-col items-center gap-30', 'lg:flex-row')}>
					<div className="lg:w-1/2">
						<h1
							className={cx(
								'text-22 lg:text-32 font-bold mb-2 font-red-hat text-primary leading-tight',
								'lg:text-50'
							)}>
							{t('free_package_sentence')}
						</h1>
						<p className={cx('text-[#5e5e5e] text-sm', 'lg:text-base')}>
							{t('enjoy_limitless_freedom_sentence')}
						</p>
					</div>
					<div className="lg:w-1/2">
						<div
							className={cx(
								'flex items-center flex-col justify-center relative p-[20px] border-dashed border-primary rounded-full',
								'md:size-[470px] md:border md:m-50'
							)}>
							<div className="flex flex-wrap justify-center gap-2 mb-10 lg:mb-0">
								{services.map((item, index) => (
									<div
										key={index}
										className={cx(
											'service-item text-center bg-white rounded-full shadow-[7px_15px_40px_rgba(42,66,140,0.15)] size-50 flex justify-center items-center cursor-pointer duration-300',
											`deg${index * 60} md:absolute md:top-1/2 md:left-1/2 md:-m-10`,
											'hover:border hover:border-primary',
											{ '!border-primary border-2': selected === index },
											'md:size-20 md:shadow-[7px_15px_50px_rgba(42,66,140,0.25)] '
										)}
										onClick={() => {
											setSelected(index);
										}}>
										<img
											src={item.image}
											alt={item.alt}
											title={item.title}
											className="rounded-full"
										/>
									</div>
								))}
							</div>
							<div className="text-center lg:px-50">
								<div className="w-20 lg:w-[100px] mx-auto mb-[20px]">
									<img
										src={services[selected].image}
										alt={services[selected].alt}
										title={services[selected].title}
									/>
								</div>
								<h4 className="mb-15 text-xl lg:text-2xl font-bold text-[#141752] font-red-hat">
									{services[selected].label}
								</h4>
								<p className="text-[#5e5e5e] px-[20px]">{services[selected].description}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default PackagesSection;
