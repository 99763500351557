import cx from 'classnames';

interface Props {
	className?: string;
}

function ArrowLeftIcon({ className }: Props) {
	return (
		<svg
			width="13"
			height="23"
			viewBox="0 0 13 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={cx(className)}>
			<path
				d="M11.0868 21.062L1.32178 11.297L11.0868 1.532"
				stroke="currentColor"
				fill="none"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"></path>
		</svg>
	);
}

export default ArrowLeftIcon;
