import React, { ReactNode } from 'react';

interface Props {
	children: ReactNode;
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function Drawer({ children, isOpen, setIsOpen }: Props) {
	return (
		<main
			className={
				' fixed overflow-hidden z-drawer bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ' +
				(isOpen
					? ' transition-opacity opacity-100 duration-500 translate-x-0  '
					: ' transition-all delay-500 opacity-0 translate-x-full  ')
			}>
			<section
				className={
					' w-screen max-w-3xl right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  ' +
					(isOpen ? ' translate-x-0 ' : ' translate-x-full ')
				}>
				<article className="relative w-screen max-w-3xl pb-10 flex flex-col space-y-4 lg:space-y-6 overflow-y-scroll h-full">
					{children}
				</article>
			</section>
			<section
				className=" w-screen h-full cursor-pointer "
				onClick={() => {
					setIsOpen(false);
				}}></section>
		</main>
	);
}

export default Drawer;
