import { useTranslation } from 'react-i18next';

function TopSection() {
	const { t, i18n } = useTranslation();

	const lng = i18n.language;

	return (
		<section className="h-300 sm:h-400 sm:bg-primary-100">
			<div className="h-full custom-container">
				<div className="flex items-center justify-center h-full">
					<div className="pt-24">
						{lng === 'de' && (
							<h1 className="text-[20px] sm:text-32 mb-2.5 font-red-hat sm:font-bold text-[#3e4143] text-center sm:text-left">
								<span className="text-primary">Kontaktcenter</span> für Kunden
							</h1>
						)}
						{lng === 'en' && (
							<h1 className="text-[20px] sm:text-32 mb-2.5 font-red-hat sm:font-bold text-[#3e4143] text-center sm:text-left">
								<span className="text-primary">Contact Center</span> for Customers
							</h1>
						)}
						{lng === 'fr' && (
							<h1 className="text-[20px] sm:text-32 mb-2.5 font-red-hat sm:font-bold text-[#3e4143] text-center sm:text-left">
								<span className="text-primary">Centre de contact</span> pour les clients
							</h1>
						)}
						<div className="text-15 text-center lg:text-left leading-8 text-[#212529]">
							{t('customer_service_hearing_from_you')}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default TopSection;
