interface Props {
	className?: string;
}

export default function CloseIcon({ className }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21.414"
			height="21.414"
			viewBox="0 0 21.414 21.414"
			className={className}>
			<g id="Group_864" data-name="Group 864" transform="translate(-46.293 -371.293)">
				<line
					id="Line_6"
					data-name="Line 6"
					x1="20"
					y2="20"
					transform="translate(47 372)"
					fill="none"
					stroke="currentColor"
					strokeWidth="2"></line>
				<line
					id="Line_7"
					data-name="Line 7"
					x2="20"
					y2="20"
					transform="translate(47 372)"
					fill="none"
					stroke="currentColor"
					strokeWidth="2"></line>
			</g>
		</svg>
	);
}
