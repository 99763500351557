import { SvgIconProps } from 'src/types';

function CalendarIcon({ className }: SvgIconProps) {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="28"
			height="28"
			viewBox="0 0 28 28">
			<g
				id="_x33_1_x2C__calendar_x2C__date_x2C__time_and_date_x2C__time_x2C__schedule"
				transform="translate(-1 -1)">
				<g id="Group_199" data-name="Group 199" transform="translate(1 1)">
					<path
						id="Path_773"
						data-name="Path 773"
						d="M26.8,66.8H3.2a2.2,2.2,0,0,1-2.2-2.2V43.2A2.2,2.2,0,0,1,3.2,41H4.843a.549.549,0,0,1,.549.549v1.072a.567.567,0,0,0,.529.575.548.548,0,0,0,.569-.549v-1.1A.549.549,0,0,1,7.039,41h3.843a.549.549,0,0,1,.549.549v1.072a.567.567,0,0,0,.529.575.548.548,0,0,0,.569-.549v-1.1A.549.549,0,0,1,13.078,41h3.843a.549.549,0,0,1,.549.549v1.072A.567.567,0,0,0,18,43.2a.548.548,0,0,0,.569-.549v-1.1A.549.549,0,0,1,19.118,41h3.843a.549.549,0,0,1,.549.549v1.072a.567.567,0,0,0,.529.575.548.548,0,0,0,.569-.549v-1.1A.549.549,0,0,1,25.157,41H26.8A2.2,2.2,0,0,1,29,43.2V64.608A2.2,2.2,0,0,1,26.8,66.8ZM3.2,42.1a1.1,1.1,0,0,0-1.1,1.1V64.608a1.1,1.1,0,0,0,1.1,1.1H26.8a1.1,1.1,0,0,0,1.1-1.1V43.2a1.1,1.1,0,0,0-1.1-1.1h-1.1v.549a1.647,1.647,0,1,1-3.294-.026V42.1H19.667v.549a1.647,1.647,0,1,1-3.294-.026V42.1H13.627v.549a1.647,1.647,0,1,1-3.294-.026V42.1H7.588v.549a1.647,1.647,0,1,1-3.294-.026V42.1Z"
						transform="translate(-1 -38.804)"
						fill="#909090"
					/>
					<path
						id="Path_774"
						data-name="Path 774"
						d="M28.451,142.1H1.549a.549.549,0,1,1,0-1.1h26.9a.549.549,0,1,1,0,1.1Z"
						transform="translate(-1 -133.314)"
						fill="#909090"
					/>
					<path
						id="Path_775"
						data-name="Path 775"
						d="M392.647,6.49A1.649,1.649,0,0,1,391,4.843v-2.2a1.647,1.647,0,1,1,3.294,0v2.2A1.649,1.649,0,0,1,392.647,6.49Zm0-4.392a.55.55,0,0,0-.549.549v2.2a.549.549,0,0,0,1.1,0v-2.2A.549.549,0,0,0,392.647,2.1Z"
						transform="translate(-369.588 -1)"
						fill="#909090"
					/>
					<path
						id="Path_776"
						data-name="Path 776"
						d="M282.647,6.49A1.649,1.649,0,0,1,281,4.843v-2.2a1.647,1.647,0,1,1,3.294,0v2.2A1.649,1.649,0,0,1,282.647,6.49Zm0-4.392a.55.55,0,0,0-.549.549v2.2a.549.549,0,0,0,1.1,0v-2.2A.549.549,0,0,0,282.647,2.1Z"
						transform="translate(-265.627 -1)"
						fill="#909090"
					/>
					<path
						id="Path_777"
						data-name="Path 777"
						d="M172.647,6.49A1.649,1.649,0,0,1,171,4.843v-2.2a1.647,1.647,0,0,1,3.294,0v2.2A1.649,1.649,0,0,1,172.647,6.49Zm0-4.392a.55.55,0,0,0-.549.549v2.2a.549.549,0,1,0,1.1,0v-2.2A.549.549,0,0,0,172.647,2.1Z"
						transform="translate(-161.667 -1)"
						fill="#909090"
					/>
					<path
						id="Path_778"
						data-name="Path 778"
						d="M62.647,6.49A1.649,1.649,0,0,1,61,4.843v-2.2a1.647,1.647,0,0,1,3.294,0v2.2A1.649,1.649,0,0,1,62.647,6.49Zm0-4.392a.55.55,0,0,0-.549.549v2.2a.549.549,0,1,0,1.1,0v-2.2A.549.549,0,0,0,62.647,2.1Z"
						transform="translate(-57.706 -1)"
						fill="#909090"
					/>
					<path
						id="Path_779"
						data-name="Path 779"
						d="M74.294,194.843H71.549a.549.549,0,0,1-.549-.549v-2.745a.549.549,0,0,1,.549-.549h2.745a.549.549,0,0,1,.549.549v2.745A.549.549,0,0,1,74.294,194.843Zm-2.2-1.1h1.647V192.1H72.1Z"
						transform="translate(-67.157 -180.569)"
						fill="#909090"
					/>
					<path
						id="Path_780"
						data-name="Path 780"
						d="M174.294,194.843h-2.745a.549.549,0,0,1-.549-.549v-2.745a.549.549,0,0,1,.549-.549h2.745a.549.549,0,0,1,.549.549v2.745A.549.549,0,0,1,174.294,194.843Zm-2.2-1.1h1.647V192.1H172.1Z"
						transform="translate(-161.667 -180.569)"
						fill="#909090"
					/>
					<path
						id="Path_781"
						data-name="Path 781"
						d="M274.294,194.843h-2.745a.549.549,0,0,1-.549-.549v-2.745a.549.549,0,0,1,.549-.549h2.745a.549.549,0,0,1,.549.549v2.745A.549.549,0,0,1,274.294,194.843Zm-2.2-1.1h1.647V192.1H272.1Z"
						transform="translate(-256.176 -180.569)"
						fill="#909090"
					/>
					<path
						id="Path_782"
						data-name="Path 782"
						d="M374.294,194.843h-2.745a.549.549,0,0,1-.549-.549v-2.745a.549.549,0,0,1,.549-.549h2.745a.549.549,0,0,1,.549.549v2.745A.549.549,0,0,1,374.294,194.843Zm-2.2-1.1h1.647V192.1H372.1Z"
						transform="translate(-350.686 -180.569)"
						fill="#909090"
					/>
					<path
						id="Path_783"
						data-name="Path 783"
						d="M274.294,294.843h-2.745a.549.549,0,0,1-.549-.549v-2.745a.549.549,0,0,1,.549-.549h2.745a.549.549,0,0,1,.549.549v2.745A.549.549,0,0,1,274.294,294.843Zm-2.2-1.1h1.647V292.1H272.1Z"
						transform="translate(-256.176 -275.078)"
						fill="#909090"
					/>
					<path
						id="Path_784"
						data-name="Path 784"
						d="M374.294,294.843h-2.745a.549.549,0,0,1-.549-.549v-2.745a.549.549,0,0,1,.549-.549h2.745a.549.549,0,0,1,.549.549v2.745A.549.549,0,0,1,374.294,294.843Zm-2.2-1.1h1.647V292.1H372.1Z"
						transform="translate(-350.686 -275.078)"
						fill="#909090"
					/>
					<path
						id="Path_785"
						data-name="Path 785"
						d="M274.294,394.843h-2.745a.549.549,0,0,1-.549-.549v-2.745a.549.549,0,0,1,.549-.549h2.745a.549.549,0,0,1,.549.549v2.745A.549.549,0,0,1,274.294,394.843Zm-2.2-1.1h1.647V392.1H272.1Z"
						transform="translate(-256.176 -369.588)"
						fill="#909090"
					/>
					<path
						id="Path_786"
						data-name="Path 786"
						d="M374.294,394.843h-2.745a.549.549,0,0,1-.549-.549v-2.745a.549.549,0,0,1,.549-.549h2.745a.549.549,0,0,1,.549.549v2.745A.549.549,0,0,1,374.294,394.843Zm-2.2-1.1h1.647V392.1H372.1Z"
						transform="translate(-350.686 -369.588)"
						fill="#909090"
					/>
					<path
						id="Path_787"
						data-name="Path 787"
						d="M74.294,294.843H71.549a.549.549,0,0,1-.549-.549v-2.745a.549.549,0,0,1,.549-.549h2.745a.549.549,0,0,1,.549.549v2.745A.549.549,0,0,1,74.294,294.843Zm-2.2-1.1h1.647V292.1H72.1Z"
						transform="translate(-67.157 -275.078)"
						fill="#909090"
					/>
					<path
						id="Path_788"
						data-name="Path 788"
						d="M174.294,294.843h-2.745a.549.549,0,0,1-.549-.549v-2.745a.549.549,0,0,1,.549-.549h2.745a.549.549,0,0,1,.549.549v2.745A.549.549,0,0,1,174.294,294.843Zm-2.2-1.1h1.647V292.1H172.1Z"
						transform="translate(-161.667 -275.078)"
						fill="#909090"
					/>
					<path
						id="Path_789"
						data-name="Path 789"
						d="M74.294,394.843H71.549a.549.549,0,0,1-.549-.549v-2.745a.549.549,0,0,1,.549-.549h2.745a.549.549,0,0,1,.549.549v2.745A.549.549,0,0,1,74.294,394.843Zm-2.2-1.1h1.647V392.1H72.1Z"
						transform="translate(-67.157 -369.588)"
						fill="#909090"
					/>
					<path
						id="Path_790"
						data-name="Path 790"
						d="M174.294,394.843h-2.745a.549.549,0,0,1-.549-.549v-2.745a.549.549,0,0,1,.549-.549h2.745a.549.549,0,0,1,.549.549v2.745A.549.549,0,0,1,174.294,394.843Zm-2.2-1.1h1.647V392.1H172.1Z"
						transform="translate(-161.667 -369.588)"
						fill="#909090"
					/>
				</g>
			</g>
		</svg>
	);
}

export default CalendarIcon;
