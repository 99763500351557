import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

interface ButtonProps extends React.ComponentProps<'button'> {
	label?: string;
	href?: string;
	active?: boolean;
	variant?: 'default' | 'revert';
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			className,
			label,
			children,
			href,
			active,
			variant = 'default',
			disabled,
			...props
		}: ButtonProps,
		ref
	) => {
		const element = (
			<button
				className={cx(
					'bg-primary text-15 lg:text-17 font-medium border-2 border-primary py-3 px-25 duration-300 outline-none rounded-full text-white flex justify-center items-center leading-5',
					{ 'hover:bg-white hover:text-primary': !disabled && (href || !!active) },
					{ 'border-white text-white hover:bg-white hover:text-primary': variant === 'revert' },
					{ 'opacity-50': disabled },
					className
				)}
				{...props}
				disabled={disabled}
				ref={ref}>
				{label || children}
			</button>
		);

		if (href) return <Link to={href}>{element}</Link>;
		return element;
	}
);

Button.displayName = 'Button';

export default Button;
