import { SvgIconProps } from 'src/types';

export default function CheckIcon({ className }: SvgIconProps) {
	return (
		<svg
			className={className}
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			width="15"
			height="15"
			viewBox="0 0 15 15">
			<path
				d="M14.7,2.5c0.4,0.4,0.4,1.1,0,1.5l-8.6,8.6C5.7,13,5,13,4.6,12.6L0.3,8.3c-0.4-0.4-0.4-1.1,0-1.5s1.1-0.4,1.5,0
		l3.5,3.5l7.8-7.8C13.6,2.1,14.3,2.1,14.7,2.5L14.7,2.5z"
				fill="currentColor"
			/>
		</svg>
	);
}
