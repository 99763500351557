import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import cx from 'classnames';

interface InputProps extends UseFormRegisterReturn {
	label: string;
	wrapperClass?: string;
	isError?: boolean;
	isOptional?: boolean;
	placeholder?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ label, wrapperClass, isError, name, placeholder, isOptional, ...rest }: InputProps, ref) => (
		<div
			className={cx(
				'w-full bg-white rounded-15 pt-2.5 pb-5 px-15 flex flex-col items-start border',
				{ 'border-red-700': isError },
				wrapperClass
			)}>
			<label className="text-[#8D8E93] text-13">
				{label} {isOptional ? '(optional)' : ''}
			</label>
			<input
				className="w-full border-none bg-none text-base font-bold text-[#8D8E93] py-5 outline-none"
				id={name}
				name={name}
				placeholder={placeholder}
				{...rest}
				ref={ref}
			/>
		</div>
	)
);

Input.displayName = 'Input';

export default Input;
