import PostCard from './post-card';
import { useSearchBlogs } from '../../api/blog';
import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import './pagination.css';
import { useTranslation } from 'react-i18next';

export function PostList() {
	const [page, setPage] = useState(0);
	const [perPage, setPerPage] = useState(5);

	const { t } = useTranslation();

	const {
		searchResults: posts,
		total,
		refetch
	} = useSearchBlogs({ page, perPage, status: 'published' });
	const pageCount = Math.ceil(total / perPage);

	const handlePageChange = (event: { selected: number }) => {
		setPage(event.selected);

		refetch();
	};

	// console.log(posts);
	return (
		<>
			{/* {author && (
				<h1 className="my-4 text-4xl font-bold leading-tight tracking-tight text-zinc-700 ">
					Posts by {author.title}
				</h1>
			)}
			{!posts && 'You must add at least one Post to your Bucket'} */}
			{posts &&
				posts.map((post) => {
					return (
						<div key={post.id}>
							<PostCard post={post} />
						</div>
					);
				})}
			<ReactPaginate
				breakLabel="..."
				nextLabel={`${t('next')} >`}
				containerClassName="pagination"
				activeClassName="active"
				pageClassName="page-item"
				pageLinkClassName="page-link"
				previousClassName="page-item"
				previousLinkClassName="page-link"
				nextClassName="page-item"
				nextLinkClassName="page-link"
				breakClassName="page-item"
				breakLinkClassName="page-link"
				onPageChange={handlePageChange}
				pageRangeDisplayed={5}
				pageCount={pageCount}
				previousLabel={`< ${t('previous')}`}
				renderOnZeroPageCount={null}
			/>
		</>
	);
}
