import useSWR from 'swr';
import { useMemo } from 'react';

import { fetcher, endpoints } from 'src/configs/axios';
import { IBlogItem, SearchParams } from 'src/types/blog';

export function useGetBlog(id: string) {
	const URL = id ? [endpoints.blog.details, { params: { id } }] : '';

	const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

	const memoizedValue = useMemo(
		() => ({
			blog: data?.data as IBlogItem,
			blogLoading: isLoading,
			blogError: error,
			blogValidating: isValidating
		}),
		[data?.data, error, isLoading, isValidating]
	);

	return memoizedValue;
}

export function useGetSuggested(id: string) {
	const URL = id ? [endpoints.blog.suggested, { params: { id } }] : '';

	const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

	const memoizedValue = useMemo(
		() => ({
			blogs: data?.data as IBlogItem[],
			blogLoading: isLoading,
			blogError: error,
			blogValidating: isValidating
		}),
		[data?.data, error, isLoading, isValidating]
	);

	return memoizedValue;
}

export function useSearchBlogs(params?: SearchParams) {
	const modifiedParams = params
		? {
				...params,
				categories: params.categories?.join(',')
			}
		: {};
	const URL = [endpoints.blog.search, { params: modifiedParams }];

	const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, {
		keepPreviousData: true
	});

	const memoizedValue = useMemo(
		() => ({
			total: data?.total || 0,
			searchResults: (data?.data as IBlogItem[]) || [],
			searchLoading: isLoading,
			searchError: error,
			searchValidating: isValidating,
			searchEmpty: !isLoading && !data?.data.length,
			refetch: mutate
		}),
		[data?.data, data?.total, error, isLoading, isValidating, mutate]
	);

	return memoizedValue;
}
