import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

import './info-section.css';

import InfoIcon1 from 'src/assets/icons/antrieb.svg';
import InfoIcon2 from 'src/assets/icons/getriebe.svg';
import InfoIcon3 from 'src/assets/icons/treibstroff.svg';
import { ICarItem } from 'src/types/car';
import { getImageUrl } from 'src/utils/string';
import { useGetCarPrice } from 'src/hooks/use-car';

interface Props {
	data: ICarItem;
}

function InfoSection({ data }: Props) {
	const { t } = useTranslation();
	const { make, model, drive, fuel, transmission, bannerImages, prices } = data;

	const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);
	const { price } = useGetCarPrice(prices);

	return (
		<section className={cx('pb-30', 'lg:pb-150')}>
			<div className="custom-container">
				<h1
					className={cx(
						'text-25 mb-[20px] font-red-hat font-bold text-[#3e4143] leading-tight',
						'lg:text-33'
					)}>
					{make} <span className="text-primary">{model}</span> {t('in_car_subscription')}
				</h1>
				<div className="flex items-center gap-25 w-full">
					<div className="flex gap-2 items-center">
						<span className="flex justify-center items-center size-10 bg-white rounded-5 text-center">
							<img className="m-auto size-4 lg:size-22" src={InfoIcon1} />
						</span>
						<span className="text-xs lg:text-sm text-[#8D8E93]">{t(drive)}</span>
					</div>
					<div className="flex gap-2 items-center">
						<span className="flex justify-center items-center size-8 bg-white rounded-5 text-center">
							<img className="m-auto size-4 lg:size-22" src={InfoIcon2} />
						</span>
						<span className="text-xs lg:text-sm text-[#8D8E93]">{t(transmission)}</span>
					</div>
					<div className="flex gap-2 items-center">
						<span className="flex justify-center items-center size-8 bg-white rounded-5 text-center">
							<img className="m-auto size-4 lg:size-22" src={InfoIcon3} />
						</span>
						<span className="text-xs lg:text-sm text-[#8D8E93]">{t(fuel)}</span>
					</div>
				</div>
				<div className="relative">
					<div className="hidden size-170 bg-white lg:flex flex-col justify-center items-center rounded-full leading-none absolute right-0 -top-20">
						<span className="text-26 text-[#16191E] font-medium">ab</span>
						<p className="text-46 text-[#16191E] font-bold leading-[52px]">
							{price}
							<span className="text-primary">.-</span>
						</p>
						<span className="font-bold text-[#16191E]">{t('per_month')}</span>
					</div>
					<div
						className={cx(
							'max-w-[750px] mx-auto w-full relative',
							'after:content-[""] after:absolute after:-top-55 after:left-1/2 after:-translate-x-1/2 lg:after:w-[330px] lg:after:h-[460px] after:bg-[url("src/assets/images/location.png")] after:bg-no-repeat after:bg-center after:bg-cover',
							'after:top-0 after:w-1/2 after:h-[70%]'
						)}>
						<Swiper
							spaceBetween={10}
							navigation={true}
							thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
							modules={[FreeMode, Navigation, Thumbs]}
							className="info-preview-swiper mt-16 lg:mt-0">
							{bannerImages.map((item, index) => (
								<SwiperSlide key={index}>
									<img src={getImageUrl(item)} />
								</SwiperSlide>
							))}
						</Swiper>
						<Swiper
							onSwiper={(swiper: SwiperClass) => {
								setThumbsSwiper(swiper);
							}}
							spaceBetween={10}
							slidesPerView={4}
							freeMode={true}
							watchSlidesProgress={true}
							modules={[FreeMode, Navigation, Thumbs]}
							className="info-thumbs-swiper">
							{bannerImages.map((item, index) => (
								<SwiperSlide key={index}>
									<img src={getImageUrl(item)} />
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				</div>
			</div>
		</section>
	);
}

export default InfoSection;
