import { Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';

import TeleImage from 'src/assets/images/simpcar_telefon.webp';
import EmailImage from 'src/assets/images/simpcar_email.webp';
import AddrImage from 'src/assets/images/simpcar_adresse.webp';

interface ItemProps {
	image: string;
	alt: string;
	title: string;
	label: string;
	html: string;
}

function DetailsItem({ image, alt, title, label, html }: ItemProps) {
	return (
		<div className="flex-1 w-full md:w-1/3 [&:nth-child(5n)]:border-r-0 sm:border-r border-[rgba(0,0,0,0.2)] flex items-center gap-[20px] sm:block">
			<div className="h-10 leading-10">
				<img className="w-10 sm:mx-auto" src={image} alt={alt} title={title} />
			</div>
			<div className="sm:mt-15">
				<h4 className="text-17 sm:text-xl text-[#212529] font-red-hat font-normal mb-2 sm:text-center">
					{label}
				</h4>
				<h5 className="text-primary text-15 sm:text-lg font-red-hat font-normal mb-2 sm:text-center">
					<div dangerouslySetInnerHTML={{ __html: html }} />
				</h5>
			</div>
		</div>
	);
}

function ContactDetailsSection() {
	const { t } = useTranslation();

	const items: ItemProps[] = [
		{
			image: TeleImage,
			alt: t('simpcar_telephone_number'),
			title: t('simpcar_telephone_number'),
			label: t('telephone'),
			html: '<a href="tel:0315582500">031 558 25 00</a>'
		},
		{
			image: EmailImage,
			alt: t('simpcar_email'),
			title: t('simpcar_email'),
			label: t('ask_via_email'),
			html: `<a href="mailto:info@simpcar.ch?Subject=${t('ask_simpcar_about_car_description')}">info@simpcar.ch</a>`
		},
		{
			image: AddrImage,
			alt: t('simpcar_address'),
			title: t('simpcar_address'),
			label: t('our_address'),
			html: '<span>Sägeweg 30, 3283 Kallnach</span>'
		}
	];

	return (
		<section className="bg-primary-100 sm:-mt-50 pt-30 pb-65 sm:pb-100">
			<div className="custom-container">
				<div className="flex items-center flex-col sm:flex-row">
					{items.map((item, index) => (
						<Fragment key={index}>
							<DetailsItem {...item} />
							<hr className="last:hidden sm:hidden w-full my-4 border border-[rgba(0,0,0,0.1)]" />
						</Fragment>
					))}
				</div>
			</div>
		</section>
	);
}

export default ContactDetailsSection;
