import { ChangeEvent, useState, useTransition } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FaChevronDown } from 'react-icons/fa';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { SelectItem } from 'src/types';
import CloseSvg from 'src/assets/icons/close.svg';
import Button from './button';
import SearchIcon from 'src/assets/icons/search.svg';

const MenuButton = Menu.Button;
const MenuItems = Menu.Items;
const MenuItem = Menu.Item;

interface MultiSelectProps {
	label: string;
	data: SelectItem[];
	selected: SelectItem[];
	onChange: (selected: SelectItem[]) => void;
}

function MultiSelect({ label, data, selected, onChange }: MultiSelectProps) {
	const { t } = useTranslation();
	const [search, setSearch] = useState('');
	const [selectedValues, setSelectedValues] = useState<SelectItem[]>(selected);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value.toLowerCase());
	};

	return (
		<div className="text-right">
			<Menu>
				<MenuButton className="inline-flex flex-col justify-center ">
					<span className="text-15 text-[#909090] mb-5">{label}</span>
					<div className="flex justify-between items-center bg-none border-none text-[#313131] max-w-[135px] min-w-[125px] lg:min-w-[135px] w-full text-left text-13">
						<span className="truncate">
							{selected.length === 0 ? t('select') : selected.map((item) => item.label).join(', ')}
						</span>
						{selected.length > 0 && (
							<span className="text-white bg-primary rounded-[3px] text-10 mr-2.5 text-center px-5 min-w-[18px] leading-5">
								{selected.length}
							</span>
						)}
						<FaChevronDown className="size-3 fill-[#313131]" />
					</div>
				</MenuButton>
				<Transition
					className="relative"
					enter="transition ease-out duration-75"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95">
					<MenuItems className="z-10 absolute top-30 -left-50 w-60 origin-top-right bg-white rounded-xl p-4 shadow-[0_3px_6px_#00000026] mt-2.5">
						<div className="relative mb-3">
							<input
								className="bg-[#F4F5F8] border-none rouned-7 w-full py-2 pl-3 pr-9 text-[#495057] outline-none"
								placeholder={`${t('search')}...`}
								value={search}
								onChange={handleChange}
							/>
							<span className="absolute -translate-y-1/2 cursor-pointer right-15 top-1/2">
								{search && (
									<img
										src={CloseSvg}
										className="w-3"
										onClick={() => {
											setSearch('');
										}}
									/>
								)}
								{!search && <SearchIcon />}
							</span>
						</div>
						<div className="flex items-center mb-22">
							<button
								className="px-1 border-none bg-none text-11"
								onClick={() => {
									setSelectedValues(data);
								}}>
								{t('select_all')}
							</button>{' '}
							•
							<button
								className="px-1 border-none bg-none text-11"
								onClick={() => {
									setSelectedValues([]);
								}}>
								{t('reset_default')}
							</button>
						</div>
						<div>
							{data
								.filter((item) => item.label.toLowerCase().includes(search))
								.map((item, index) => (
									<div
										key={index}
										className={cx(
											'mb-5 leading-snug tracking-[0.7px] text-[#333] text-[12px] cursor-pointer border border-transparent rounded-md p-5 bg-[#f0f0f0] relative text-left',
											{
												'!border-[#cccccc] font-bold after:content-[""] after:absolute after:right-2.5 after:bg-[url("src/assets/icons/icon-check-dark.svg")] after:bg-no-repeat after:h-15 after:w-3 after:top-1/2 after:-mt-[7.5px]':
													selectedValues.findIndex((fItem) => fItem.value === item.value) > -1
											}
										)}
										onClick={() => {
											const fIndex = selectedValues.findIndex(
												(fItem) => fItem.value === item.value
											);
											if (fIndex > -1) {
												setSelectedValues([
													...selectedValues.slice(0, fIndex),
													...selectedValues.slice(fIndex + 1)
												]);
											} else {
												setSelectedValues([...selectedValues, item]);
											}
										}}>
										{item.label}
									</div>
								))}
						</div>
						<MenuItem>
							{({ close }) => (
								<Button
									className="mt-14 h-10 text-white !text-13 rounded-md !font-bold w-full"
									onClick={() => {
										onChange(selectedValues);
										close();
									}}>
									{t('search')}
								</Button>
							)}
						</MenuItem>
					</MenuItems>
				</Transition>
			</Menu>
		</div>
	);
}

export default MultiSelect;
