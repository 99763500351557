import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

import './slider.css';

interface Props {
	id: string;
	label?: string;
	steps: number[];
	defaultValue?: number;
	wrapperClassName?: string;
	onChange?: (val: number) => void;
}

function Slider({ id, label, steps, wrapperClassName, defaultValue, onChange }: Props) {
	const [value, setValue] = useState(defaultValue || 0);

	const max = steps.length - 1;
	const min = 0;

	useEffect(() => {
		setValue(defaultValue || 0);
	}, [defaultValue]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(parseInt(e.target.value));
		onChange?.(parseInt(e.target.value, 10));
	};

	const handleTick = (val: number) => {
		setValue(val);
		onChange?.(val);
	};

	const stepPos = useMemo(() => {
		const wEl = document.getElementsByClassName(`steps-${id}`)[0];
		if (wEl) {
			const wRt = wEl.getBoundingClientRect();
			const left = ((value - min) / (max - min)) * (wRt.width - 15 - 15) + 15; //const left = (((value - minValue) / (valueMax - valueMin)) * ((totalInputWidth - thumbHalfWidth) - thumbHalfWidth)) + thumbHalfWidth;
			return left - 10;
		}
		return 0;
	}, [value]);

	return (
		<div className={cx('slider w-full', wrapperClassName)}>
			{label && (
				<label htmlFor="rangeInput" className="block text-sm font-medium text-gray-700 mb-2">
					{label}
				</label>
			)}
			{steps && steps.length > 0 && (
				<div className={cx('flex items-center justify-between mb-5 relative', `steps-${id}`)}>
					{steps.map((step, index) => (
						<span
							key={step}
							className={cx(
								'w-50 tracking-[0.4px] text-nowrap text-primary font-semibold text-center cursor-pointer',
								`step-${id}`
							)}
							onClick={() => handleTick(index)}>
							{step.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}
						</span>
					))}
				</div>
			)}
			<div className="relative w-full">
				<input
					type="range"
					id="rangeInput"
					className="w-full !bg-white"
					min={0}
					max={max}
					value={value}
					onChange={handleChange}
				/>
				<div
					key={value}
					style={{ width: `${stepPos}px` }}
					className={cx(
						'absolute h-2.5 top-1/2 -translate-y-1/2 left-0 bg-primary rounded-[4px] overflow-hidden z-0'
					)}></div>
			</div>
		</div>
	);
}

export default Slider;
