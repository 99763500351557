import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import cx from 'classnames';

import './privacy-policy.css';

interface Answer {
	tag: string;
	text?: string;
	data?: string[];
}

interface Faq {
	question: string;
	answer: Answer[];
}

interface Props {
	data: Faq;
	selected?: boolean;
	index?: number;
	onClick?: (val: number) => void;
}

interface SentenceProps {
	tag: string;
	text?: string;
	data?: string[];
	faqs?: Faq[];
}

function Divider() {
	return (
		<div className='h-5 my-8 bg-[url("src/assets/images/shadow.png")] bg-[length:100%_auto]'>
			&nbsp;
		</div>
	);
}

function QuestionItem({ data, selected, index = 0, onClick }: Props) {
	return (
		<div className="mb-5">
			<Disclosure defaultOpen={selected}>
				{({ open }) => (
					<>
						<Disclosure.Button
							className={cx(
								'flex justify-between items-center w-full py-3 px-6 text-[#212529] font-semibold rounded-10 text-base leading-normal gap-2 text-left',
								'hover:bg-[#EAF2DA]',
								{ 'bg-[#EAF2DA]': open }
							)}
							onClick={() => {
								onClick?.(index);
							}}>
							{data.question}
							<div className="w-2">{!open ? <FaPlus /> : <FaMinus />}</div>
						</Disclosure.Button>
						<Transition
							enter="duration-300 ease-out"
							enterFrom="opacity-0 h-0"
							enterTo="opacity-100 h-full"
							leave="duration-150 ease-out"
							leaveFrom="opacity-100 h-full"
							leaveTo="opacity-0 h-0">
							{data.answer && (
								<Disclosure.Panel className="p-15">
									{data.answer.map((aItem, aIndex) => (
										<div key={aIndex}>
											{aItem.tag === 'p' && <p>{aItem.text}</p>}
											{aItem.tag === 'ul' && (
												<ul>
													{aItem.data?.map((uItem, uIndex) => (
														<li key={`${aIndex}-${uIndex}`}>{uItem}</li>
													))}
												</ul>
											)}
										</div>
									))}
								</Disclosure.Panel>
							)}
						</Transition>
					</>
				)}
			</Disclosure>
		</div>
	);
}

function PrivacyPolicy() {
	const { t } = useTranslation();

	const [selected, setSelected] = useState(-1);

	const sentences: SentenceProps[] = [
		{
			tag: 'h4',
			text: t('generally')
		},
		{
			tag: 'p',
			text: t('generally_1')
		},
		{
			tag: 'p',
			text: t('generally_2')
		},
		{
			tag: 'p',
			text: t('generally_3')
		},
		{
			tag: 'p',
			text: t('generally_4')
		},
		{ tag: 'divider' },
		{ tag: 'h4', text: t('processing_personal_data') },
		{ tag: 'p', text: t('processing_personal_data_1') },
		{ tag: 'p', text: t('processing_personal_data_2') },
		{
			tag: 'ol',
			text: t('processing_personal_data_3'),
			data: [
				t('processing_personal_data_3_1'),
				t('processing_personal_data_3_2'),
				t('processing_personal_data_3_3'),
				t('processing_personal_data_3_4'),
				t('processing_personal_data_3_5')
			]
		},
		{ tag: 'p', text: t('processing_personal_data_4') },
		{ tag: 'divider' },
		{ tag: 'h4', text: t('cookies') },
		{ tag: 'p', text: t('cookies_1') },
		{ tag: 'p', text: t('cookies_2') },
		{ tag: 'divider' },
		{ tag: 'h4', text: t('with_ssl_tls_encryption') },
		{ tag: 'p', text: t('with_ssl_tls_encryption_1') },
		{ tag: 'p', text: t('with_ssl_tls_encryption_2') },
		{ tag: 'divider' },
		{ tag: 'h4', text: t('server_log_files') },
		{ tag: 'p', text: t('server_log_files_1') },
		{
			tag: 'ul',
			text: t('server_log_files_2_1'),
			data: [
				t('server_log_files_2_1'),
				t('server_log_files_2_2'),
				t('server_log_files_2_3'),
				t('server_log_files_2_4'),
				t('server_log_files_2_5')
			]
		},
		{ tag: 'p', text: t('server_log_files_3') },
		{ tag: 'divider' },
		{ tag: 'h4', text: t('third_party_services') },
		{ tag: 'p', text: t('third_party_services_1') },
		{ tag: 'p', text: t('third_party_services_2') },
		{ tag: 'p', text: t('third_party_services_3') },
		{ tag: 'p', text: t('third_party_services_4') },
		{ tag: 'divider' },
		{ tag: 'h4', text: t('contact_form') },
		{ tag: 'p', text: t('contact_form_1') },
		{ tag: 'divider' },
		{ tag: 'h4', text: t('newsletter') },
		{ tag: 'p', text: t('newsletter_1') },
		{ tag: 'p', text: t('newsletter_2') },
		{ tag: 'divider' },
		{ tag: 'h4', text: t('store_ip_address') },
		{ tag: 'p', text: t('store_ip_address_1') },
		{ tag: 'divider' },
		{
			tag: 'faq',
			text: t('rights_of_data'),
			faqs: [
				{
					question: t('rights_of_data_1_q'),
					answer: [
						{
							tag: 'p',
							text: t('rights_of_data_1_a')
						}
					]
				},
				{
					question: t('rights_of_data_2_q'),
					answer: [
						{ tag: 'p', text: t('rights_of_data_2_a') },
						{
							tag: 'ul',
							data: [
								t('rights_of_data_2_a_1_1'),
								t('rights_of_data_2_a_1_2'),
								t('rights_of_data_2_a_1_3'),
								t('rights_of_data_2_a_1_4'),
								t('rights_of_data_2_a_1_5')
							]
						},
						{
							tag: 'p',
							text: t('rights_of_data_2_a_2')
						},
						{
							tag: 'p',
							text: t('rights_of_data_2_a_3')
						}
					]
				},
				{
					question: t('rights_of_data_3_q'),
					answer: [
						{ tag: 'p', text: t('rights_of_data_3_a_1') },
						{ tag: 'p', text: t('rights_of_data_3_a_2') }
					]
				},
				{
					question: t('rights_of_data_4_q'),
					answer: [
						{
							tag: 'p',
							text: t('rights_of_data_4_a_1')
						},

						{
							tag: 'ul',
							data: [
								t('rights_of_data_4_a_2_1'),
								t('rights_of_data_4_a_2_2'),
								t('rights_of_data_4_a_2_3'),
								t('rights_of_data_4_a_2_4'),
								t('rights_of_data_4_a_2_5'),
								t('rights_of_data_4_a_2_6'),
								t('rights_of_data_4_a_2_7')
							]
						}
					]
				},
				{
					question: t('rights_of_data_5_q'),
					answer: [
						{ tag: 'p', text: t('rights_of_data_5_a_1') },
						{ tag: 'p', text: t('rights_of_data_5_a_2') },
						{ tag: 'p', text: t('rights_of_data_5_a_3') },
						{ tag: 'p', text: t('rights_of_data_5_a_4') }
					]
				},
				{
					question: t('rights_of_data_6_q'),
					answer: [{ tag: 'p', text: t('rights_of_data_6_a') }]
				},
				{
					question: t('rights_of_data_7_q'),
					answer: [
						{ tag: 'p', text: t('rights_of_data_7_a_1') },
						{ tag: 'p', text: t('rights_of_data_7_a_2') }
					]
				},
				{
					question: t('rights_of_data_8_q'),
					answer: [{ tag: 'p', text: t('rights_of_data_8_a') }]
				},
				{
					question: t('rights_of_data_9_q'),
					answer: [
						{ tag: 'p', text: t('rights_of_data_9_a_1') },
						{ tag: 'p', text: t('rights_of_data_9_a_2') }
					]
				},
				{
					question: t('rights_of_data_10_q'),
					answer: [
						{ tag: 'p', text: t('rights_of_data_10_a_1') },
						{ tag: 'p', text: t('rights_of_data_10_a_2') },
						{ tag: 'p', text: t('rights_of_data_10_a_3') }
					]
				}
			]
		},
		{
			tag: 'h4',
			text: t('google_maps')
		},
		{ tag: 'p', text: t('google_maps_1') },
		{ tag: 'p', text: t('google_maps_2') },
		{ tag: 'p', text: t('google_maps_3') },
		{ tag: 'p', text: t('google_maps_4') },
		{
			tag: 'h4',
			text: t('google_ads')
		},
		{ tag: 'p', text: t('google_ads_1') },
		{ tag: 'p', text: t('google_ads_2') },
		{ tag: 'p', text: t('google_ads_3') },
		{ tag: 'p', text: t('google_ads_4') },
		{ tag: 'p', text: t('google_ads_5') },
		{ tag: 'h4', text: t('google_analytics') },
		{ tag: 'p', text: t('google_analytics_1') },
		{ tag: 'p', text: t('google_analytics_2') },
		{ tag: 'p', text: t('google_analytics_3') },
		{ tag: 'p', text: t('google_analytics_4') },
		{ tag: 'p', text: t('google_analytics_5') },
		{ tag: 'p', text: t('google_analytics_6') },
		{ tag: 'h4', text: t('google_adsense') },
		{ tag: 'p', text: t('google_adsense_1') },
		{ tag: 'p', text: t('google_adsense_2') },
		{ tag: 'p', text: t('google_adsense_3') },
		{ tag: 'p', text: t('google_adsense_4') },
		{ tag: 'p', text: t('google_adsense_5') },
		{ tag: 'p', text: t('google_adsense_6') },
		{
			tag: 'ul',
			data: [
				t('google_adsense_7_1'),
				t('google_adsense_7_2'),
				t('google_adsense_7_3'),
				t('google_adsense_7_4'),
				t('google_adsense_7_5')
			]
		},
		{
			tag: 'h4',
			text: t('google_tag_manager')
		},
		{
			tag: 'p',
			text: t('google_tag_manager_1')
		},
		{
			tag: 'h4',
			text: t('facebook')
		},
		{
			tag: 'p',
			text: t('facebook_1')
		},
		{
			tag: 'h4',
			text: t('twitter')
		},
		{
			tag: 'p',
			text: t('twitter_1')
		},
		{
			tag: 'h4',
			text: t('instagram')
		},
		{
			tag: 'p',
			text: t('instagram_1')
		},
		{ tag: 'h4', text: t('linkedin') },
		{ tag: 'p', text: t('linkedin_1') },
		{ tag: 'p', text: t('linkedin_2') },
		{ tag: 'p', text: t('linkedin_3') },
		{ tag: 'p', text: t('linkedin_4') },
		{ tag: 'p', text: t('linkedin_5') },
		{ tag: 'p', text: t('linkedin_6') },
		{ tag: 'p', text: t('linkedin_7') },
		{ tag: 'h4', text: t('external_payment_service') },
		{ tag: 'p', text: t('external_payment_service_1') },
		{
			tag: 'ul',
			data: [
				'PostFinance (https://www.postfinance.ch/de/detail/rechtliches-barrierefreiheit.html)',
				'Visa https://www.visa.de/legal/privacy-policy.html',
				'Mastercard (https://www.mastercard.ch/de-ch/datenschutz.html)',
				'American Express (https://www.americanexpress.com/de/legal/online-datenschutzerklarung.html)',
				'Paypal (https://www.paypal.com/de/webapps/mpp/ua/privacy-full)',
				'Apple Pay (https://support.apple.com/de-ch/ht203027)',
				'Stripe (https://stripe.com/ch/privacy)',
				'Klarna (https://www.klarna.com/de/datenschutz/)'
			]
		},
		{ tag: 'p', text: t('external_payment_service_3') },
		{ tag: 'p', text: t('external_payment_service_4') },
		{ tag: 'p', text: t('external_payment_service_5') },
		{ tag: 'p', text: t('external_payment_service_6') },
		{ tag: 'h4', text: t('youtube') },
		{ tag: 'p', text: t('youtube_1') },
		{ tag: 'p', text: t('youtube_2') },
		{ tag: 'h4', text: t('online_shop') },
		{ tag: 'p', text: t('online_shop_1') },
		{ tag: 'p', text: t('online_shop_2') },
		{ tag: 'p', text: t('online_shop_3') },
		{ tag: 'p', text: t('online_shop_4') },
		{ tag: 'p', text: t('online_shop_5') },
		{ tag: 'p', text: t('online_shop_6') },
		{ tag: 'p', text: t('online_shop_7') },
		{ tag: 'h4', text: t('data_transfer_to_usa') },
		{ tag: 'p', text: t('data_transfer_to_usa_1') },
		{ tag: 'p', text: t('data_transfer_to_usa_2') },
		{ tag: 'p', text: t('data_transfer_to_usa_3') },
		{ tag: 'h4', text: t('disclaimer') },
		{ tag: 'p', text: t('disclaimer_1') },
		{ tag: 'p', text: t('disclaimer_2') },
		{ tag: 'p', text: t('disclaimer_3') }
	];

	return (
		<div className="privacy-policy">
			<div className="h-400 bg-[#edf3e1]">
				<div className="custom-container h-full">
					<div className="flex justify-center items-center h-full">
						<h1 className="text-32 mb-2.5 pt-24 font-bold text-[#3e4143] font-red-hat">
							{t('privacy_policy_long')}
						</h1>
					</div>
				</div>
			</div>
			<div className="bg-white">
				<div className="text-[#555] py-20 custom-container">
					<div className="w-full sm:max-w-[80%] mx-auto">
						<p>
							<b>{t('company_name')}:</b> simpcar ag
							<br />
							<b>{t('address')}:</b> Heidmoosweg 15, 3049 Säriswil
							<br />
							<b>UID:</b> CHE-406.237.689
							<br />
						</p>
						<p>{t('head_sentence_1')}</p>
						<p>{t('head_sentence_2')}</p>
						<Divider />
						{sentences.map((item, index) => (
							<div key={index}>
								{item.tag === 'h4' && <h4>{item.text}</h4>}
								{item.tag === 'p' && <p>{item.text}</p>}
								{item.tag === 'ul' && (
									<ul>
										{item.data?.map((uItem, uIndex) => (
											<li key={`ul-${index}-${uIndex}`}>{uItem}</li>
										))}
									</ul>
								)}
								{item.tag === 'ol' && (
									<ol>
										{item.data?.map((oItem, oIndex) => (
											<li key={`ol-${index}-${oIndex}`}>{oItem}</li>
										))}
									</ol>
								)}
								{item.tag === 'divider' && <Divider />}
								{item.tag === 'faq' && (
									<>
										<h4>{item.text}</h4>
										{item.faqs?.map((fItem, index) => (
											<QuestionItem
												key={`${index}-${selected}`}
												selected={index === selected}
												index={index}
												data={fItem}
												onClick={(val) => {
													setSelected(val);
												}}
											/>
										))}
									</>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default PrivacyPolicy;
