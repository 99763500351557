import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import InfoImage1 from 'src/assets/icons/kategorie.svg';
import InfoImage2 from 'src/assets/icons/motor.svg';
import InfoImage3 from 'src/assets/icons/leistung.svg';
import InfoImage4 from 'src/assets/icons/antrieb.svg';
import InfoImage5 from 'src/assets/icons/treibstroff.svg';
import InfoImage6 from 'src/assets/icons/getriebe.svg';
import InfoImage7 from 'src/assets/icons/car-seats.svg';
import CheckIcon from 'src/assets/icons/check-icon.svg';

import CarsPreview from './cars-preview';
import { ICarItem } from 'src/types/car';

interface TechItem {
	image: string;
	alt: string;
	title: string;
	label: string;
	value: string;
}

interface Props {
	car: ICarItem;
}

function LeftSection({ car }: Props) {
	const { t } = useTranslation();
	const {
		category,
		seats,
		engine,
		powerHp,
		drive,
		fuel,
		transmission,
		consumptionElectricity,
		co2Emission,
		energyEfficiencyClass,
		make,
		model,
		description
	} = car;

	const data: TechItem[] = [
		{
			image: InfoImage1,
			alt: t('category'),
			title: t('category'),
			label: t('category'),
			value: category
		},
		{
			image: InfoImage2,
			alt: t('engine'),
			title: t('engine'),
			label: t('engine'),
			value: engine
		},
		{
			image: InfoImage3,
			alt: 'leistung',
			title: 'leistung',
			label: t('power_in_hp'),
			value: `${powerHp}`
		},
		{
			image: InfoImage4,
			alt: t('wheel'),
			title: t('wheel'),
			label: t('wheel'),
			value: t(drive)
		},
		{
			image: InfoImage5,
			alt: t('fuel'),
			title: t('fuel'),
			label: t('fuel'),
			value: t(fuel)
		},
		{
			image: InfoImage6,
			alt: t('mechanism'),
			title: t('mechanism'),
			label: t('mechanism'),
			value: t(transmission)
		},
		{
			image: InfoImage7,
			alt: t('number_of_seats'),
			title: t('number_of_seats'),
			label: t('number_of_seats'),
			value: `${seats}`
		}
	];

	return (
		<div className="mt-7 lg:-mt-45">
			<h2 className="text-primary mb-15 lg:mb-10 text-xl lg:text-2xl font-red-hat font-medium">
				{t('vehicle_data_for')} {make} {model}
			</h2>
			<p className="text-[#8D8E93] mb-10 leading-[24px]">{description}</p>
			<h3 className="mb-15 font-red-hat font-normal text-[#3e4143] text-lg lg:text-xl">
				Technische Daten
			</h3>
			<div className="flex items-center flex-wrap">
				{data.map((item, index) => (
					<div key={index} className="flex items-center gap-4 w-1/2 mb-4">
						<div className="size-[60px] border-2 border-[#F2F1F0] rounded-md flex justify-center items-center">
							<img className="w-35" src={item.image} alt={item.alt} title={item.title} />
						</div>
						<div>
							<label className="text-xs lg:text-sm text-[#8D8E93]">{item.label}</label>
							<p className="text-sm lg:text-base text-[#8D8E93] font-bold">{item.value}</p>
						</div>
					</div>
				))}
			</div>
			<div className="mt-25">
				<h3 className="text-lg lg:text-xl mb-15 font-red-hat text-[#3e4143]">Umwelt</h3>
				<div className="flex justify-between bg-[#f5f5f5] p-[20px] rounded-lg gap-15">
					<div className="flex-1 border-r border-[#ccc]">
						<label className="text-xs lg:text-base inline-block mb-2">Ø Verbrauch</label>
						<p>
							<b>{consumptionElectricity}</b>
						</p>
					</div>
					<div className="flex-1 border-r border-[#ccc]">
						<label className="text-xs lg:text-base inline-block mb-2">
							CO<sub>2</sub>-{t('emission')}
						</label>
						<p>
							<b>{co2Emission || '-'}</b>
						</p>
					</div>
					<div className="flex-1">
						<label className="text-xs lg:text-base inline-block mb-2">
							{t('energy_efficiency')}
						</label>
						<p>
							<b>{energyEfficiencyClass}</b>
						</p>
					</div>
				</div>
				<CarsPreview data={car} />
				<div className={cx('mt-10 md:mb-50')}>
					<h3 className="text-[20px] mb-15 font-red-hat text-[#3e4143]">{t('furnishing')}</h3>
					<div className="flex flex-wrap items-center">
						{car.equipmentLine.map((item, index) => (
							<div
								key={index}
								className="w-full md:w-1/2 text-white flex items-center gap-[20px] mb-15">
								<span className="size-26 bg-primary rounded-full p-1 text-white flex items-center justify-center">
									<CheckIcon />
								</span>
								<span className="text-15 text-[#8D8E93]">{item}</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default LeftSection;
