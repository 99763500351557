import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import cx from 'classnames';

import './privacy-policy.css';

interface Answer {
	tag: string;
	text?: string;
	data?: string[];
}

interface Faq {
	question: string;
	answer: Answer[];
}

interface Props {
	data: Faq;
	selected?: boolean;
	no?: string;
	index?: number;
	onClick?: (val: number) => void;
}

interface SentenceProps {
	tag: string;
	text?: string;
	data?: string[];
	faqs?: Faq[];
	no?: string;
}

function Divider() {
	return (
		<div className='h-5 my-8 bg-[url("src/assets/images/shadow.png")] bg-[length:100%_auto]'>
			&nbsp;
		</div>
	);
}

function QuestionItem({ data, selected, index = 0, no, onClick }: Props) {
	return (
		<div className="mb-5">
			<Disclosure defaultOpen={selected}>
				{({ open }) => (
					<>
						<Disclosure.Button
							className={cx(
								'flex justify-between items-center w-full py-3 px-6 text-[#212529] font-semibold rounded-10 text-base leading-normal gap-2 text-left',
								'hover:bg-[#EAF2DA]',
								{ 'bg-[#EAF2DA]': open }
							)}
							onClick={() => {
								onClick?.(index);
							}}>
							{no}. {index + 1} {data.question}
							<div className="w-2">{!open ? <FaPlus /> : <FaMinus />}</div>
						</Disclosure.Button>
						<Transition
							enter="duration-300 ease-out"
							enterFrom="opacity-0 h-0"
							enterTo="opacity-100 h-full"
							leave="duration-150 ease-out"
							leaveFrom="opacity-100 h-full"
							leaveTo="opacity-0 h-0">
							{data.answer && (
								<Disclosure.Panel className="p-15">
									{data.answer.map((aItem, aIndex) => (
										<div key={aIndex}>
											{aItem.tag === 'p' && <p>{aItem.text}</p>}
											{aItem.tag === 'ul' && (
												<ul>
													{aItem.data?.map((uItem, uIndex) => (
														<li key={`${aIndex}-${uIndex}`}>{uItem}</li>
													))}
												</ul>
											)}

											{aItem.tag === 'ol' && (
												<ul>
													{aItem.data?.map((uItem, uIndex) => (
														<li key={`${aIndex}-${uIndex}`}>{uItem}</li>
													))}
												</ul>
											)}
										</div>
									))}
								</Disclosure.Panel>
							)}
						</Transition>
					</>
				)}
			</Disclosure>
		</div>
	);
}

function TermsAndConditions() {
	const { t } = useTranslation();

	const [selected, setSelected] = useState(-1);

	const sentences: SentenceProps[] = [
		{
			tag: 'h4',
			text: t('tc_1_t'),
			no: '1'
		},
		{
			tag: 'p',
			text: t('tc_1_d_1')
		},

		{ tag: 'divider' },
		{
			tag: 'h4',
			text: t('tc_2_t'),
			no: '2'
		},
		{
			tag: 'p',
			text: t('tc_2_d_1')
		},
		{
			tag: 'h4',
			text: t('tc_3_t'),
			no: '3'
		},
		{
			tag: 'p',
			text: t('tc_3_d_1')
		},
		{
			tag: 'p',
			text: t('tc_3_d_2')
		},
		{
			tag: 'p',
			text: t('tc_3_d_3')
		},
		{
			tag: 'p',
			text: t('tc_3_d_4')
		},
		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_4_t'), no: '4' },
		{ tag: 'p', text: t('tc_4_d_1') },
		{ tag: 'p', text: t('tc_4_d_2') },
		{ tag: 'divider' },
		{
			tag: 'faq',
			text: t('tc_5_t'),
			no: '5',
			faqs: [
				{
					question: t('tc_5_d_1_q'),
					answer: [
						{
							tag: 'p',
							text: t('tc_5_d_1_a_1')
						},
						{
							tag: 'p',
							text: t('tc_5_d_1_a_2')
						},
						{
							tag: 'p',
							text: t('tc_5_d_1_a_3')
						}
					]
				},
				{
					question: t('tc_5_d_2_q'),
					answer: [
						{
							tag: 'p',
							text: t('tc_5_d_2_a_1')
						}
					]
				},
				{
					question: t('tc_5_d_3_q'),
					answer: [{ tag: 'p', text: t('tc_5_d_3_a_1') }]
				},
				{
					question: t('tc_5_d_4_q'),
					answer: [
						{
							tag: 'p',
							text: t('tc_5_d_4_a_1')
						},
						{
							tag: 'p',
							text: t('tc_5_d_4_a_2')
						}
					]
				},
				{
					question: t('tc_5_d_5_q'),
					answer: [
						{ tag: 'p', text: t('tc_5_d_5_a_1') },
						{ tag: 'p', text: t('tc_5_d_5_a_2') },
						{ tag: 'p', text: t('tc_5_d_5_a_3') }
					]
				},
				{
					question: t('tc_5_d_6_q'),
					answer: [{ tag: 'p', text: t('tc_5_d_6_a_1') }]
				},
				{
					question: t('tc_5_d_7_q'),
					answer: [
						{ tag: 'p', text: t('tc_5_d_7_a_1') },
						{ tag: 'p', text: t('tc_5_d_7_a_2') },
						{ tag: 'p', text: t('tc_5_d_7_a_3') }
					]
				}
			]
		},

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_6_t'), no: '6' },
		{ tag: 'p', text: t('tc_6_d_1') },

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_7_t'), no: '7' },
		{ tag: 'p', text: t('tc_7_d_1') },

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_8_t'), no: '8' },
		{ tag: 'p', text: t('tc_8_d_1') },
		{ tag: 'p', text: t('tc_8_d_2') },

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_9_t'), no: '9' },
		{ tag: 'p', text: t('tc_9_d_1') },

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_10_t'), no: '10' },
		{ tag: 'p', text: t('tc_10_d_1') },

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_11_t'), no: '11' },
		{ tag: 'p', text: t('tc_11_d_1') },

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_12_t'), no: '12' },
		{ tag: 'p', text: t('tc_12_d_1') },

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_13_t'), no: '13' },
		{ tag: 'p', text: t('tc_13_d_1') },

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_14_t'), no: '14' },
		{ tag: 'p', text: t('tc_14_d_1') },
		{ tag: 'p', text: t('tc_14_d_2') },
		{ tag: 'p', text: t('tc_14_d_3') },
		{ tag: 'p', text: t('tc_14_d_4') },
		{ tag: 'p', text: t('tc_14_d_5') },
		{ tag: 'p', text: t('tc_14_d_6') },

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_15_t'), no: '15' },
		{ tag: 'p', text: t('tc_15_d_1') },

		{ tag: 'divider' },
		{
			tag: 'faq',
			text: t('tc_16_t'),
			no: '16',
			faqs: [
				{
					question: t('tc_16_d_1_q'),
					answer: [
						{
							tag: 'p',
							text: t('tc_16_d_1_a_1')
						},
						{
							tag: 'p',
							text: t('tc_16_d_1_a_2')
						},
						{
							tag: 'p',
							text: t('tc_16_d_1_a_3')
						}
					]
				},
				{
					question: t('tc_16_d_2_q'),
					answer: [
						{ tag: 'p', text: t('tc_16_d_2_a_1') },
						{
							tag: 'p',
							text: t('tc_16_d_2_a_2')
						}
					]
				},
				{
					question: t('tc_16_d_3_q'),
					answer: [
						{ tag: 'p', text: t('tc_16_d_3_a_1') },
						{ tag: 'p', text: t('tc_16_d_3_a_2') },
						{
							tag: 'ol',
							data: [t('tc_16_d_3_a_2_1'), t('tc_16_d_3_a_2_2'), t('tc_16_d_3_a_2_3')]
						},
						{ tag: 'p', text: t('tc_16_d_3_a_3') },
						{ tag: 'p', text: t('tc_16_d_3_a_4') }
					]
				},
				{
					question: t('tc_16_d_4_q'),
					answer: [
						{
							tag: 'p',
							text: t('tc_16_d_4_a_1')
						},
						{
							tag: 'p',
							text: t('tc_16_d_4_a_2')
						}
					]
				}
			]
		},

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_17_t'), no: '17' },
		{ tag: 'p', text: t('tc_17_d_1') },

		{ tag: 'divider' },
		{
			tag: 'faq',
			text: t('tc_18_t'),
			no: '18',
			faqs: [
				{
					question: t('tc_18_d_1_q'),
					answer: [
						{
							tag: 'p',
							text: t('tc_18_d_1_a_1')
						},
						{
							tag: 'p',
							text: t('tc_18_d_1_a_2')
						}
					]
				},
				{
					question: t('tc_18_d_2_q'),
					answer: [{ tag: 'p', text: t('tc_18_d_2_a_1') }]
				},
				{
					question: t('tc_18_d_3_q'),
					answer: [
						{ tag: 'p', text: t('tc_18_d_3_a_1') },
						{
							tag: 'ul',
							data: [
								t('tc_18_d_3_a_2_1'),
								t('tc_18_d_3_a_2_2'),
								t('tc_18_d_3_a_2_3'),
								t('tc_18_d_3_a_2_4'),
								t('tc_18_d_3_a_2_5'),
								t('tc_18_d_3_a_2_6')
							]
						},
						{ tag: 'p', text: t('tc_18_d_3_a_3') }
					]
				}
			]
		},

		{ tag: 'divider' },
		{
			tag: 'faq',
			text: t('tc_19_t'),
			no: '19',
			faqs: [
				{
					question: t('tc_19_d_1_q'),
					answer: [
						{
							tag: 'p',
							text: t('tc_19_d_1_a_1')
						},
						{
							tag: 'ul',
							data: [
								t('tc_19_d_1_a_2_1'),
								t('tc_19_d_1_a_2_2'),
								t('tc_19_d_1_a_2_3'),
								t('tc_19_d_1_a_2_4'),
								t('tc_19_d_1_a_2_5'),
								t('tc_19_d_1_a_2_6'),
								t('tc_19_d_1_a_2_7'),
								t('tc_19_d_1_a_2_8'),
								t('tc_19_d_1_a_2_9'),
								t('tc_19_d_1_a_2_10'),
								t('tc_19_d_1_a_2_11'),
								t('tc_19_d_1_a_2_12')
							]
						}
					]
				},
				{
					question: t('tc_19_d_2_q'),
					answer: [
						{ tag: 'p', text: t('tc_19_d_2_a_1') },
						{
							tag: 'ul',
							data: [t('tc_19_d_2_a_2_1'), t('tc_19_d_2_a_2_2'), t('tc_19_d_2_a_2_3')]
						}
					]
				},
				{
					question: t('tc_19_d_3_q'),
					answer: [{ tag: 'p', text: t('tc_19_d_3_a_1') }]
				}
			]
		},

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_20_t'), no: '20' },
		{ tag: 'p', text: t('tc_20_d_1') },
		{
			tag: 'ul',
			text: t('tc_20_d_1'),
			data: [
				t('tc_20_d_2_1'),
				t('tc_20_d_2_2'),
				t('tc_20_d_2_3'),
				t('tc_20_d_2_4'),
				t('tc_20_d_2_5'),
				t('tc_20_d_2_6'),
				t('tc_20_d_2_7'),
				t('tc_20_d_2_8')
			]
		},
		{ tag: 'p', text: t('tc_20_d_3') },

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_21_t'), no: '21' },
		{ tag: 'p', text: t('tc_21_d_1') },
		{ tag: 'p', text: t('tc_21_d_2') },

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_22_t'), no: '22' },
		{ tag: 'p', text: t('tc_22_d_1') },

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_23_t'), no: '23' },
		{ tag: 'p', text: t('tc_23_d_1') },

		{ tag: 'divider' },
		{ tag: 'h4', text: t('tc_24_t'), no: '24' },
		{ tag: 'p', text: t('tc_24_d_1') },
		{ tag: 'p', text: t('tc_24_d_2') },
		{ tag: 'p', text: t('tc_24_d_3') },

		{ tag: 'divider' },
		{
			tag: 'faq',
			text: t('tc_25_t'),
			no: '25',
			faqs: [
				{
					question: t('tc_25_d_1_q'),
					answer: [
						{
							tag: 'p',
							text: t('tc_25_d_1_a_1')
						},
						{
							tag: 'p',
							text: t('tc_25_d_1_a_2')
						}
					]
				},
				{
					question: t('tc_25_d_2_q'),
					answer: [{ tag: 'p', text: t('tc_25_d_2_a_1') }]
				},
				{
					question: t('tc_25_d_3_q'),
					answer: [{ tag: 'p', text: t('tc_25_d_3_a_1') }]
				},
				{
					question: t('tc_25_d_4_q'),
					answer: [
						{ tag: 'p', text: t('tc_25_d_4_a_1') },
						{ tag: 'p', text: t('tc_25_d_4_a_2') }
					]
				}
			]
		}
	];

	return (
		<div className="privacy-policy">
			<div className="h-400 bg-[#edf3e1]">
				<div className="custom-container h-full">
					<div className="flex justify-center items-center h-full">
						<h1 className="text-32 mb-2.5 pt-24 font-bold text-[#3e4143] font-red-hat">
							{t('terms_conditions')}
						</h1>
					</div>
				</div>
			</div>
			<div className="bg-white">
				<div className="text-[#555] py-20 custom-container">
					<div className="w-full sm:max-w-[80%] mx-auto">
						{sentences.map((item, index) => (
							<div key={index}>
								{item.tag === 'h4' && (
									<h4>
										{item.no}. {item.text}
									</h4>
								)}
								{item.tag === 'p' && <p>{item.text}</p>}
								{item.tag === 'ul' && (
									<ul>
										{item.data?.map((uItem, uIndex) => (
											<li key={`ul-${index}-${uIndex}`}>{uItem}</li>
										))}
									</ul>
								)}
								{item.tag === 'ol' && (
									<ol>
										{item.data?.map((oItem, oIndex) => (
											<li key={`ol-${index}-${oIndex}`}>{oItem}</li>
										))}
									</ol>
								)}
								{item.tag === 'divider' && <Divider />}
								{item.tag === 'faq' && (
									<>
										<h4>
											{item.no}. {item.text}
										</h4>
										{item.faqs?.map((fItem, index) => (
											<QuestionItem
												key={`${index}-${selected}`}
												selected={index === selected}
												index={index}
												no={item.no}
												data={fItem}
												onClick={(val) => {
													setSelected(val);
												}}
											/>
										))}
									</>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default TermsAndConditions;
