import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'src/assets/locales/en.json';
import fr from 'src/assets/locales/fr.json';
import de from 'src/assets/locales/de.json';

let lng = window.location.pathname.split('/')[1];

if (!['de', 'en', 'fr'].includes(lng)) {
	lng = 'de';
}

i18n.use(initReactI18next).init({
	lng,
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false
	},
	resources: {
		en: {
			translation: en
		},
		fr: {
			translation: fr
		},
		de: {
			translation: de
		}
	}
});

export default i18n;
