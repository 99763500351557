import { useTranslation } from 'react-i18next';
import { FaAngleRight } from 'react-icons/fa';

import ThankyouImage from 'src/assets/images/autoabo-schweiz.webp';
import Button from '../button';

function AutoScribeConfirmation() {
	const { t, i18n } = useTranslation();
	const lng = i18n.language;

	return (
		<section className="bg-white text-center py-50">
			<div className="custom-container">
				<div className="container-80 text-center">
					<h5 className="text-27 leading-9 font-red-hat font-normal text-[#3e4143]">
						{t('thank_you_for_subscribe')}
					</h5>
					<img className="my-50 mx-auto w-[142px]" src={ThankyouImage} />
					<p className="text-lg leading-[30px] text-[#3D3F40]">{t('thank_you_confirm_sentence')}</p>
					<div className="flex justify-center">
						<Button className="mt-50 text-center" active href="/">
							{t('back_to_website')}
							<FaAngleRight className="text-3xl" />
						</Button>
					</div>
				</div>
				<div>
					<h2 className="mt-12 text-3xl font-red-hat text-[#3e4143] font-normal leading-tight">
						{t('can_we_help')}
					</h2>
					{lng === 'de' && (
						<p className="text-lg leading-[30px] text-[#3D3F40]">
							Unser Team hilft dir gerne unter{' '}
							<a className="text-primary" href="mailto:info@simpcar.ch">
								info@simpcar.ch
							</a>{' '}
							oder{' '}
							<a className="text-primary" href="tel:+41315582500">
								+41 (0)31 558 25 00
							</a>
							.
						</p>
					)}
					{lng === 'en' && (
						<p className="text-lg leading-[30px] text-[#3D3F40]">
							Our team will be happy to help you at{' '}
							<a className="text-primary" href="mailto:info@simpcar.ch">
								info@simpcar.ch
							</a>{' '}
							or{' '}
							<a className="text-primary" href="tel:+41315582500">
								+41 (0)31 558 25 00
							</a>
							.
						</p>
					)}
					{lng === 'fr' && (
						<p className="text-lg leading-[30px] text-[#3D3F40]">
							Notre équipe se fera un plaisir de vous aider à{' '}
							<a className="text-primary" href="mailto:info@simpcar.ch">
								info@simpcar.ch
							</a>{' '}
							ou au{' '}
							<a className="text-primary" href="tel:+41315582500">
								+41 (0)31 558 25 00
							</a>
							.
						</p>
					)}
				</div>
			</div>
		</section>
	);
}

export default AutoScribeConfirmation;
