import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { FaQuoteRight } from 'react-icons/fa';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './reviews-section.css';

import { StarIcon } from 'src/assets/icons/star.svg';
import { getInitials } from 'src/utils/string';

interface Props {
	text: string;
	score: number;
	name: string;
	address: string;
	photo?: string;
}

const reviews: Props[] = [
	{
		text: `Vielen Dank für die angenehme Zusammenarbeit, die kompetetnte Beratung und das
		optimale wohlfühl Rundumpacket. Sie werden unsere erste wahl bleiben für die weitere
		Zukunft. Gerne empfehlen wir sie weiter! Vielen herzlichen Dank!`,
		score: 5,
		name: 'Agnes Hämmerli',
		address: 'Bellach'
	},
	{
		text: `Ich habe schon seit längerer Zeit unsere Firmenautos als Auto Abo bei der simpcar.
		Dies ist die passende Lösung für unsere Flotte. Zudem war dass Angebot der simpcar
		einfach das Beste. Ich kann die Firma nur empfehlen.`,
		score: 5,
		name: 'Robert Fankhauser',
		address: 'Bern'
	},
	{
		text: `Super sache, bin sehr zufrieden.`,
		score: 5,
		name: 'Christian Egger',
		address: 'Zürich'
	},
	{
		text: `Hat alles einwandfrei geklappt - supertolle und verantwortungsbewusste Betreuung. Dankeschön!`,
		score: 5,
		name: 'Barbara Furegati-Koller',
		address: 'Zürich'
	},
	{
		text: `Hat alles tip top geklappt, bin sehr happy. Dankeschön an alle!`,
		score: 5,
		name: 'Ursula Lindemann',
		address: 'St. Gallen'
	}
];

function ReviewCard({ text, name, address, photo }: Props) {
	return (
		<div
			className={cx(
				'group rounded-[20px] bg-white p-4 lg:p-30 pb-2.5 m-15 select-none cursor-grab shadow-[1px_0_20px_rgba(0,0,0,0.1)] hover:bg-primary h-[232px] duration-500'
			)}>
			<div className="flex items-center gap-15 mb-[20px]">
				<div className="rounded-full size-20 overflow-hidden border-[3px] border-white bg-primary-100 text-primary flex justify-center items-center">
					{photo ? (
						<img src={photo} />
					) : (
						<span className="text-2xl font-bold font-red-hat">{getInitials(name)}</span>
					)}
				</div>
				<div>
					<p className="text-black text-[16px] lg:text-[20px] font-semibold font-red-hat group-hover:text-white duration-500">
						{name}
					</p>
					<p className="font-normal text-14 leading-5 text-[#5c639] mb-5 group-hover:text-white duration-500">
						{address}
					</p>
					<div className="flex items-center gap-1">
						<StarIcon />
						<StarIcon />
						<StarIcon />
						<StarIcon />
						<StarIcon />
					</div>
				</div>
			</div>
			<p
				className={cx(
					'relative text-[#5e5e5e] text-15 leading-6 mb-[20px] group-hover:text-white duration-500'
				)}>
				{/* <span className="absolute -left-[7px] top-0">“</span> */}
				<span className="line-clamp-3">“ {text} ”</span>
				{/* <span className="absolute right-[7px] bottom-0">”</span> */}
			</p>
			<FaQuoteRight className="absolute top-35 lg:top-50 right-30 lg:right-50 text-4xl lg:text-6xl text-primary opacity-20 group-hover:text-[rgba(255,255,255,0.6)]" />
		</div>
	);
}

function ReviewsSection() {
	const { t, i18n } = useTranslation();
	const lng = i18n.language;

	return (
		<section className="py-50 lg:py-100 bg-[#EAF2DA]">
			<div className="custom-container">
				<div className="mb-30 lg:mb-75">
					{lng === 'de' && (
						<h2 className="text-center text-22 md:text-33 font-medium font-red-hat text-[#3e4143]">
							Was Kunden über <span className="text-primary">simpcar</span> sagen
						</h2>
					)}
					{lng === 'en' && (
						<h2 className="text-center text-22 md:text-33 font-medium font-red-hat text-[#3e4143]">
							What customers say about <span className="text-primary">simpcar</span>
						</h2>
					)}
					{lng === 'fr' && (
						<h2 className="text-center text-22 md:text-33 font-medium font-red-hat text-[#3e4143]">
							Ce que disent les clients de <span className="text-primary">simpcar</span>
						</h2>
					)}
					<h3 className="text-center text-base font-light md:text-lg text-[#0C111F] font-red-hat">
						{t('we_measure_our_success')}
					</h3>
				</div>
				<div>
					<Swiper
						slidesPerView={1}
						spaceBetween={30}
						loop={true}
						pagination={{
							clickable: true,
							bulletActiveClass: 'bullet-active'
						}}
						navigation={true}
						autoplay={{
							delay: 2500,
							disableOnInteraction: true
						}}
						breakpoints={{
							640: {
								slidesPerView: 1,
								spaceBetween: 0
							},
							992: {
								slidesPerView: 2,
								spaceBetween: 0
							},
							1024: {
								slidesPerView: 2,
								spaceBetween: 0
							}
						}}
						modules={[Pagination, Navigation, Autoplay]}
						className="reviews-swiper !px-5 lg:!px-50">
						{reviews.map((item, index) => (
							<SwiperSlide key={index}>
								<ReviewCard
									text={item.text}
									score={item.score}
									name={item.name}
									address={item.address}
									photo={item.photo}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</section>
	);
}

export default ReviewsSection;
