import cx from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { useGetCar } from 'src/api/car';
import CarSpec from 'src/components/auto-subscribe/car-spec';
import AutoScribeConfirmation from 'src/components/auto-subscribe/confirmation';

function AutoSubscribeConfirm() {
	const [searchParams] = useSearchParams();
	const id = searchParams.get('id');
	const km = searchParams.get('km');
	const term = searchParams.get('term');

	const { car, carLoading } = useGetCar(id as string);

	if (carLoading) {
		return null;
	}

	return (
		<div className={cx('pt-100 bg-[#F4F5F8]', 'lg:pt-150')}>
			<CarSpec data={car} km={parseInt(km as string, 10)} term={parseInt(term as string, 10)} />
			<AutoScribeConfirmation />
		</div>
	);
}

export default AutoSubscribeConfirm;
