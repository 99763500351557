import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import CarImage1 from 'src/assets/images/autoabo-schweiz.webp';
import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';

function CheapSubscriptionSecton() {
	const { t, i18n } = useTranslation();

	const { getUrl } = useMultiLngRoutes();

	const lng = i18n.language;

	return (
		<div className="md:max-w-[80%] mx-auto">
			<div className="flex justify-center">
				<img
					className="w-[60px] md:w-[84px] mb-17"
					src={CarImage1}
					title="Auto Abo Schweiz"
					alt="Auto Abo Schweiz"
				/>
			</div>
			<div>
				<h2 className="text-center text-[20px] md:text-27 text-[#3e4143] font-medium font-red-hat leading-tight mb-2">
					{t('cheap_subscription_instead_of')}
				</h2>
				<p className="text-base md:text-xl font-light mb-4 text-center">
					{t('save_cost_with_simpcar')}
				</p>
			</div>
			<p className="mt-6 text-center ">
				{t('save_cost_with_simpcar_1')}
				<br />
				<br />
				{lng === 'de' && (
					<>
						Das <b>Auto Abo</b> ist eine moderne Mobilitätslösung mit einer grossen Auswahl an
						Fahrzeugen. Wir haben für jeden Geschmack und jedes Budget das passende Auto im Abo. Von
						Kleinwagen über Familienfahrzeuge bis hin zu Luxusautos – bei uns findest du das
						Fahrzeug, das perfekt zu dir passt. Und das Beste daran: du kannst das Auto jederzeit
						wechseln, wenn deine Bedürfnisse sich ändern.
					</>
				)}
				{lng === 'en' && (
					<>
						The <b>car subscription</b> is a modern mobility solution with a large selection of
						vehicles. We have the right car subscription for every taste and every budget. From
						small cars to family vehicles to luxury cars – with us you will find the vehicle that
						suits you perfectly. And the best part: you can change cars at any time if your needs
						change.
					</>
				)}

				{lng === 'fr' && (
					<>
						<b>L'abonnement voiture</b> est une solution de mobilité moderne avec un large choix de
						véhicules. Nous avons l'abonnement automobile adapté à tous les goûts et à tous les
						budgets. Des petites voitures aux véhicules familiaux en passant par les voitures de
						luxe – chez nous, vous trouverez le véhicule qui vous convient parfaitement. Et le
						meilleur : vous pouvez changer de voiture à tout moment si vos besoins évoluent.
					</>
				)}
			</p>
			<div className="text-center mt-12">
				<a
					className={cx(
						'border-2 border-primary text-sm md:text-base font-semibold bg-primary text-white px-6 py-2 rounded-full cursor-pointer',
						'hover:bg-white hover:text-primary'
					)}
					href={getUrl('cars')}
					title="Autos im Abo">
					{t('to_the_cars')}
				</a>
			</div>
		</div>
	);
}

export default CheapSubscriptionSecton;
