import TopSection from 'src/components/about-us/top-section';
import SimpleMobilitySection from 'src/components/about-us/simple-mobility-section';
import CareFreeSection from 'src/components/about-us/care-free-section';
import SubscriptionSection from 'src/components/home/subscription-section';
import WhatWeDoSection from 'src/components/home/what-we-do-section';
import AboutUsSection from 'src/components/about-us/about-us-section';
import PackagesSection from 'src/components/about-us/packages-section';

function AboutUs() {
	return (
		<>
			<TopSection />
			<SimpleMobilitySection />
			<CareFreeSection />
			<AboutUsSection />
			<PackagesSection />
			<SubscriptionSection page="about-us" />
			<WhatWeDoSection />
		</>
	);
}

export default AboutUs;
