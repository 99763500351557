import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import BrandImage from 'src/assets/images/auto-abonnement-scheiz.webp';
import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';

function UpgradeMobilitySection() {
	const { t, i18n } = useTranslation();

	const { getUrl } = useMultiLngRoutes();

	const lng = i18n.language;

	return (
		<div className="md:max-w-[80%] mx-auto">
			<img
				className="w-[60px] md:w-[84px] mb-17 mx-auto"
				src={BrandImage}
				alt="Auto-Abo Schweiz"
				title="Auto-Abo Schweiz"
			/>
			<h2 className="text-19 lg:text-27 font-medium font-red-hat text-[#3e4143] text-center">
				{t('car_subscription_upgrade')}
			</h2>
			{lng === 'de' && (
				<>
					<p className="mt-6 text-center text-15 md:text-base">
						Ein <b>Auto-Abo in der Schweiz</b> bietet viele Vorteile. Du kannst dich für ein
						Fahrzeug entscheiden, das perfekt zu deinen Bedürfnissen passt und es so lange nutzen,
						wie du möchtest. Du zahlst eine <b>monatliche Gebühr</b> für unser{' '}
						<b>All-inclusive-Paket</b>, das folgendes abdeckt: Versicherung, Steuern, Service,
						Wartung, Reparaturen und sogar Reifenwechsel sowie die Autobahn-Vignette sind drin. Es
						gibt keine versteckten Kosten oder Überraschungen, und wenn du das Fahrzeug nicht mehr
						benötigst, kannst du es einfach zurückgeben.
					</p>
					<p className="text-center text-15 md:text-base">
						Forsche unsere Autos durch und schliesse heute noch dein <strong>Auto Abo</strong> bei
						<strong>simpcar</strong> ab.
					</p>
				</>
			)}
			{lng === 'en' && (
				<>
					<p className="mt-6 text-center text-15 md:text-base">
						A <b>car subscription in Switzerland</b> offers many benefits. You can choose a vehicle
						that perfectly suits your needs and use it for as long as you want. You pay a{' '}
						<b>monthly fee</b> for our <b>all-inclusive package</b>, which covers the following:
						insurance, taxes, service, maintenance, repairs, and even tire changes, as well as the
						highway vignette. There are no hidden costs or surprises, and if you no longer need the
						vehicle, you can simply return it.
					</p>
					<p className="text-center text-15 md:text-base">
						Explore our cars and sign up for your <strong>car subscription</strong> with{' '}
						<strong>simpcar</strong> today.
					</p>
				</>
			)}
			{lng === 'fr' && (
				<>
					<p className="mt-6 text-center text-15 md:text-base">
						Une <b>abonnement de voiture en Suisse</b> offre de nombreux avantages. Vous pouvez
						choisir un véhicule qui correspond parfaitement à vos besoins et l'utiliser aussi
						longtemps que vous le souhaitez. Vous payez un <b>abonnement mensuel</b> pour notre{' '}
						<b>forfait tout compris</b>, qui comprend ce qui suit : assurance, impôts, service,
						entretien, réparations et même le changement de pneus ainsi que la vignette autoroutière
						sont inclus. Il n'y a pas de coûts cachés ni de surprises, et si vous n'avez plus besoin
						du véhicule, vous pouvez simplement le rendre.
					</p>
					<p className="text-center text-15 md:text-base">
						Explorez nos voitures et souscrivez dès aujourd'hui à votre{' '}
						<strong>abonnement automobile</strong> chez <strong>simpcar</strong>.
					</p>
				</>
			)}
			<br />
			<div className="text-center">
				<a
					className={cx(
						'border-2 border-primary text-sm md:text-base font-semibold bg-primary text-white px-6 py-2 rounded-full cursor-pointer',
						'hover:bg-white hover:text-primary'
					)}
					href={getUrl('cars')}
					title="Auto Abo günstig">
					{t('cheap_cars_by_subscription')}
				</a>
			</div>
		</div>
	);
}

export default UpgradeMobilitySection;
