import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LanguageType } from './types';
import MultiRoutes from './configs/routes';
import Layout from './components/layout';
import Home from './pages/home';
import Cars from './pages/cars';
import CarDetail from './pages/car-detail';
import CarSubscription from './pages/car-subscription';
import AboutUs from './pages/about-us';
import FAQ from './pages/faq';
import PrivacyPolicy from './pages/privacy-policy';
import TermsAndConditions from './pages/terms-conditions';
import Contact from './pages/contact';
import AutoSubscribe from './pages/auto-subscribe';
import Imprint from './pages/imprint';
import Blogs from './pages/blogs';
import BlogDetail from './pages/blog-detail';

import './App.css';
import AutoSubscribeConfirm from './pages/auto-subscribe-confirm';

function App() {
	const { i18n } = useTranslation();

	const lng = i18n.language as LanguageType;

	return (
		<Router>
			<Routes>
				{['en', 'de', 'fr'].map((item) => {
					const lngItem = item as LanguageType;

					return (
						<Route key={lngItem} path={`/${lngItem}`} element={<Layout />}>
							<Route index element={<Home />} />
							<Route
								path={MultiRoutes['car_subscribe_confirm'][lngItem]}
								element={<AutoSubscribeConfirm />}
							/>
							<Route path={MultiRoutes['auto_subscribe'][lngItem]} element={<AutoSubscribe />} />
							<Route path={MultiRoutes['car_detail'][lngItem]} element={<CarDetail />} />
							<Route path={MultiRoutes['cars'][lngItem]} element={<Cars />} />
							<Route
								path={MultiRoutes['car_subscription_switzerland'][lngItem]}
								element={<CarSubscription />}
							/>
							<Route path={MultiRoutes['about_us'][lngItem]} element={<AboutUs />} />
							<Route path={MultiRoutes['faq'][lngItem]} element={<FAQ />} />
							<Route path={MultiRoutes['privacy_policy'][lngItem]} element={<PrivacyPolicy />} />
							<Route
								path={MultiRoutes['terms_conditions'][lngItem]}
								element={<TermsAndConditions />}
							/>
							<Route path={MultiRoutes['imprint'][lngItem]} element={<Imprint />} />
							<Route path={MultiRoutes['contact'][lngItem]} element={<Contact />} />
							<Route path={MultiRoutes['blog'][lngItem]} element={<Blogs />} />
							<Route path={MultiRoutes['blog_details'][lngItem]} element={<BlogDetail />} />
						</Route>
					);
				})}
				<Route path="*" element={<Navigate to={`/${lng}`} />} />
			</Routes>
		</Router>
	);
}

export default App;
