import { useMemo, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { SelectItem } from 'src/types';
import FiltersSection from 'src/components/cars/filters-section';
import CarsListSection from 'src/components/cars/cars-list-section';
import { useSearchCars } from 'src/api/car';

function Cars() {
	const { i18n } = useTranslation();

	const lng = i18n.language;

	const [filters, setFilters] = useState<Record<string, SelectItem[]>>({});

	const plainFilters = useMemo(() => {
		let result = Object.assign({});
		Object.keys(filters).forEach((key) => {
			result[key] = filters[key].map((item) => item.value);
		});
		return result;
	}, [filters]);

	const {
		searchResults: cars,
		total,
		refetch
	} = useSearchCars({
		...plainFilters
	});

	return (
		<div className="pt-100 lg:pt-150">
			<div className="custom-container">
				{lng === 'de' && (
					<h2
						className={cx(
							'text-xl text-[#3e4143] font-red-hat font-medium text-center',
							'md:text-33'
						)}>
						Finde das <span className="text-primary">Auto</span>, das zu dir passt
					</h2>
				)}
				{lng === 'en' && (
					<h2
						className={cx(
							'text-xl text-[#3e4143] font-red-hat font-medium text-center',
							'md:text-33'
						)}>
						Find the <span className="text-primary">car</span> that suits you
					</h2>
				)}

				{lng === 'fr' && (
					<h2
						className={cx(
							'text-xl text-[#3e4143] font-red-hat font-medium text-center',
							'md:text-33'
						)}>
						Trouvez la <span className="text-primary">voiture</span> qui vous convient
					</h2>
				)}
				<FiltersSection
					selected={filters}
					onChange={(val) => {
						setFilters(val);
					}}
				/>
				<CarsListSection data={cars} />
			</div>
		</div>
	);
}

export default Cars;
