import TopSection from 'src/components/faq/top-section';
import QuestionsSection from 'src/components/faq/questions-section';

function FAQ() {
	return (
		<>
			<TopSection />
			<QuestionsSection />
		</>
	);
}

export default FAQ;
