import { Link } from 'react-router-dom';
import ArrowLeft from '../blogs/arrow-left';
import { useGetBlog, useGetSuggested } from '../../api/blog';
import SuggestedPostCard from './suggested-post-card';
import Tag from '../blogs/tag';
import AuthorAvatar from '../blogs/author-avatar';
import AuthorAttribution from '../blogs/author-attribution';
import { removeDescTags } from '../../utils/format-blog';
import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';
import { useTranslation } from 'react-i18next';
import { getImageUrl } from '../../utils/string';

export function SinglePost({ id }: { id: string }) {
	const { blog: post } = useGetBlog(id);

	const { blogs: suggestedPosts } = useGetSuggested(id);

	const { getUrl } = useMultiLngRoutes();

	const { t } = useTranslation();

	return (
		<>
			{post && post.cover[0] && (
				<img
					width={1400}
					height={720}
					className="mb-5 h-[720px] w-full bg-no-repeat object-cover object-center"
					src={getImageUrl(post.cover[0])}
					alt={post.title}
				/>
			)}
			<main className="mx-auto flex flex-col justify-center pt-10">
				<div className="mx-auto flex w-full flex-col items-start justify-center px-4 md:flex-row">
					<div className="mt-4 flex justify-start pb-4 md:justify-center md:pb-0 md:pr-20">
						<Link
							to={getUrl('blog')}
							className="rounded-full border border-zinc-100 bg-white p-2 text-zinc-700 shadow-md">
							<ArrowLeft className="h-4 w-4" />
						</Link>
					</div>
					<div className="mr-20 flex w-full max-w-3xl flex-col justify-start md:w-3/4">
						<h2>
							{!post && <div className="text-center">{t('blog_not_found')}</div>}
							{post && <Link to={getUrl('blog_details', { id: post.id })}>{post.title}</Link>}
						</h2>
						{post && (
							<>
								<div className="flex flex-col justify-between space-y-4 pb-8 md:flex-row md:space-y-0">
									<div className="flex items-center space-x-2 text-zinc-500 md:space-y-0">
										<AuthorAvatar post={post} />
										<AuthorAttribution post={post} />
									</div>
									<div className="flex select-none justify-start space-x-2 md:justify-end">
										{post.categories &&
											post.categories.map((category) => (
												<Tag key={category}>{t(`blog_categories.${category}`)}</Tag>
											))}
									</div>
								</div>
								<hr className="w-full border-t border-zinc-300 pb-8" />
								<div
									dangerouslySetInnerHTML={{
										__html: removeDescTags(post.content) ?? ''
									}}></div>
							</>
						)}
						{suggestedPosts?.length > 0 && (
							<div className="mx-auto mt-8 w-full">
								<hr className="w-full border-t border-zinc-300 pb-8" />
								<div className="flex w-full flex-col">
									<h3 className="pb-3 text-xl font-semibold text-zinc-800">
										{t('suggested_blogs')}
									</h3>
									<div className="flex flex-col space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
										{suggestedPosts
											// .filter((nextPost) => nextPost?.id !== post?.id)
											.slice(0, 2)
											.map((post) => {
												return <SuggestedPostCard key={post.id} post={post} />;
											})}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</main>
		</>
	);
}
