import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import './care-free-section.css';

interface Props {
	className: string;
	value: number;
	key: string;
	label: string;
}

function CareFreeSection() {
	const { t } = useTranslation();

	return (
		<div className="py-5 lg:py-20 px-[#f9f9f9]">
			<div className="custom-container">
				<div className="flex flex-col items-center md:flex-row gap-35">
					<div className="flex-1 md:pr-50">
						<div className="text-lg font-medium mb-[20px]">{t('completely_care_free')}</div>
						<h2 className="font-red-hat text-[20px] md:text-27 font-medium text-[#3e4143]">
							{t('get_subscription_in_few_clicks')}
						</h2>
						<p className="font-normal leading-8 text-15 md:text-lg font-red-hat">
							{t('we_have_constantly_growing_vehicles')}
						</p>
					</div>
					<div className="flex flex-wrap flex-1 w-full md:w-auto">
						<div
							className={cx(
								'py-[20px] w-1/2 flex flex-col items-center border border-[#ebebeb] border-t-0 border-l-0'
							)}>
							<div
								className={cx(
									'text-35 md:text-50 leading-10 font-bold mb-[20px]',
									`tabular-nums animate-[counter_3s_ease-out_forwards] [counter-set:_num_var(--num-years)] before:content-[counter(num)]`
								)}>
								<span className="sr-only">5</span>
							</div>
							<div className="text-base font-normal leading-8 md:text-lg font-red-hat">
								{t('years_of_experience')}
							</div>
						</div>

						<div
							className={cx(
								'py-[20px] w-1/2 flex flex-col items-center border border-[#ebebeb] border-t-0 border-r-0'
							)}>
							<div
								className={cx(
									'text-35 md:text-50 leading-10 font-bold mb-[20px]',
									`tabular-nums animate-[counter_3s_ease-out_forwards] [counter-set:_num_var(--num-employees)] before:content-[counter(num)]`
								)}>
								<span className="sr-only">7</span>
							</div>
							<div className="text-base font-normal leading-8 md:text-lg font-red-hat">
								{t('employees')}
							</div>
						</div>

						<div
							className={cx(
								'py-[20px] w-1/2 flex flex-col items-center border border-[#ebebeb] border-b-0 border-l-0'
							)}>
							<div
								className={cx(
									'text-35 md:text-50 leading-10 font-bold mb-[20px]',
									`tabular-nums animate-[counter_3s_ease-out_forwards] [counter-set:_num_var(--num-cars)] before:content-[counter(num)]`
								)}>
								<span className="sr-only">130</span>
							</div>
							<div className="text-base font-normal leading-8 md:text-lg font-red-hat">
								{t('cars_on_subscription')}
							</div>
						</div>
						<div
							className={cx(
								'py-[20px] w-1/2 flex flex-col items-center border border-[#ebebeb] border-b-0 border-r-0'
							)}>
							<div
								className={cx(
									'text-35 md:text-50 leading-10 font-bold mb-[20px]',
									`tabular-nums animate-[counter_3s_ease-out_forwards] [counter-set:_num_var(--num-hours)] before:content-[counter(num)]`
								)}>
								<span className="sr-only">24</span>
							</div>
							<div className="text-base font-normal leading-8 md:text-lg font-red-hat">
								{t('hours_online')}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CareFreeSection;
