import { SvgIconProps } from 'src/types';

function ExclamationMarkCircleIcon({ className }: SvgIconProps) {
	return (
		<svg
			className={className}
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			width="15"
			height="15"
			viewBox="0 0 15 15">
			<path
				d="M7.5,15c4.1,0,7.5-3.4,7.5-7.5S11.6,0,7.5,0S0,3.4,0,7.5S3.4,15,7.5,15z M7.5,3.8c0.4,0,0.7,0.3,0.7,0.7v3.3
			c0,0.4-0.3,0.7-0.7,0.7S6.8,8.1,6.8,7.7V4.5C6.8,4.1,7.1,3.8,7.5,3.8z M6.6,10.3c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9
			S8,11.3,7.5,11.3S6.6,10.8,6.6,10.3z"
				fill="currentColor"></path>
		</svg>
	);
}

export default ExclamationMarkCircleIcon;
