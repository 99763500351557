import { useTranslation } from 'react-i18next';

import CheckImage from 'src/assets/images/simpcar-check.webp';

interface Props {
	label: string;
	text: string;
}

function WWDItem({ label, text }: Props) {
	return (
		<div>
			<div className="flex justify-center mb-25 lg:block">
				<img
					className="w-10 lg:w-45"
					src={CheckImage}
					title="Autoabo Schweiz"
					alt="Autoabo Schweiz"
				/>
			</div>
			<div className="text-xl font-semibold text-center mb-15 lg:text-left">{label}</div>
			<p className="text-base leading-6 text-center mb-15 lg:text-left">{text}</p>
		</div>
	);
}

function WhatWeDoSection() {
	const { t, i18n } = useTranslation();

	const lng = i18n.language;

	const items: Props[] = [
		{
			label: t('flexible'),
			text: t('monthly_cancellable_switch_another_car')
		},
		{
			label: t('advantageous'),
			text: t('monthly_rate_covers_all_costs')
		},
		{
			label: t('straightforward'),
			text: t('subscription_bookable_online')
		},
		{
			label: t('transparent'),
			text: t('no_nasty_surprises')
		}
	];

	return (
		<section className="py-50 bg-white">
			<div className="custom-container">
				<div className="text-base font-light text-center mb-15 lg:text-left">
					{t('car_subscription_advantages')}
				</div>
				{lng === 'de' && (
					<div className="mb-10 font-semibold leading-8 text-center lg:leading-9 text-22 lg:text-28 font-red-hat lg:text-left">
						Starte durch – mit deinem <span className="text-primary">Auto-Abo</span> von simpcar!
					</div>
				)}
				{lng === 'en' && (
					<div className="mb-10 font-semibold leading-8 text-center lg:leading-9 text-22 lg:text-28 font-red-hat lg:text-left">
						Get started – with your <span className="text-primary">Car Subscription</span> from
						simpcar!
					</div>
				)}
				{lng === 'fr' && (
					<div className="mb-10 font-semibold leading-8 text-center lg:leading-9 text-22 lg:text-28 font-red-hat lg:text-left">
						Commencez – avec votre <span className="text-primary">Abonnement Automobile</span> de
						simpcar !
					</div>
				)}
				<div className="flex flex-wrap lg:flex-nowrap gap-30">
					{items.map((item, index) => (
						<WWDItem key={index} label={item.label} text={item.text} />
					))}
				</div>
			</div>
		</section>
	);
}

export default WhatWeDoSection;
