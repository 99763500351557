import { useMemo, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';
import ServiceImage1 from 'src/assets/images/simpcar-auto-abo-versicherung.webp';
import ServiceImage2 from 'src/assets/images/simpcar-auto-abo-strassenverkehrssteuer.webp';
import ServiceImage3 from 'src/assets/images/simpcar-auto-abo-service-werkstatt.webp';
import ServiceImage4 from 'src/assets/images/simpcar-auto-abo-bereifung.webp';
import ServiceImage5 from 'src/assets/images/simpcar-auto-abo-zulassung.webp';
import ServiceImage6 from 'src/assets/images/simpcar-auto-abo-autobahnvignette.webp';
import CheckIcon from 'src/assets/icons/check-icon.svg';
import { formatNumber } from 'src/utils/number';
import { StepTwoData } from '../form/request-offer-form-2';
import { StepOneData } from './step-1';
import { useGetCar } from 'src/api/car';
import { countryList } from 'src/constants/country';
import { CompanyIndustry } from 'src/constants';

interface Props {
	stepOneData?: StepOneData;
	stepTwoData?: StepTwoData;
	onCheck: (val: boolean) => void;
}

interface ServiceItemProps {
	image: string;
	label: string;
	alt: string;
	title: string;
}

function StepThree({ stepTwoData, stepOneData, onCheck }: Props) {
	const { t, i18n } = useTranslation();
	const { id, term, km } = useParams();

	const { car, carLoading } = useGetCar(id as string);
	const { make, model, transmission, fuel, prices } = car;

	if (carLoading) {
		return null;
	}

	const lng = i18n.language;

	const { getUrl } = useMultiLngRoutes();
	const [checked, setChecked] = useState(false);
	const services: ServiceItemProps[] = [
		{
			image: ServiceImage1,
			alt: 'Auto Abo mit Versicherung',
			title: 'Auto Abo mit Versicherung',
			label: 'Versicherung'
		},
		{
			image: ServiceImage2,
			alt: 'auto abo servie und wartung',
			title: 'auto abo servie und wartung',
			label: 'Service & Wartung'
		},
		{
			image: ServiceImage3,
			alt: 'Auto Abo mit Strassenzulassung',
			title: 'Auto Abo mit Strassenzulassung',
			label: 'Zulassung'
		},
		{
			image: ServiceImage4,
			alt: 'simpcar strassenverkehrssteuer',
			title: 'simpcar strassenverkehrssteuer',
			label: 'Steuern'
		},
		{
			image: ServiceImage5,
			alt: 'Auto Abo mit Bereifung',
			title: 'Auto Abo mit Bereifung',
			label: 'Bereifung'
		},
		{
			image: ServiceImage6,
			alt: 'simpcar autobahnvignette',
			title: 'simpcar autobahnvignette',
			label: 'Vignette'
		}
	];

	const nationality = useMemo(() => {
		const result = countryList.find((item) => item.code === stepTwoData?.nationality);
		return result?.name;
	}, [stepTwoData]);

	const formDataArr = [
		{ label: t('salutation'), value: t(stepTwoData?.gender as string) },
		...(stepTwoData?.gender === 'company'
			? [
					{
						label: t('family_name'),
						value: stepTwoData.companyName
					},
					{
						label: t('industry'),
						value: CompanyIndustry.find((item) => item.value === stepTwoData.companyIndustry)?.label
					},
					{
						label: t('address'),
						value: stepTwoData.companyAddress
					},
					{
						label: t('postal_code'),
						value: stepTwoData.companyZip
					},
					{
						label: t('city'),
						value: stepTwoData.companyCity
					}
				]
			: []),
		{ label: t('firstname'), value: stepTwoData?.firstName },
		{ label: t('lastname'), value: stepTwoData?.lastName },
		{ label: t('address'), value: stepTwoData?.street },
		{ label: t('zip_city'), value: `${stepTwoData?.postalCode ?? ''} ${stepTwoData?.city ?? ''}` },
		{ label: t('nationality'), value: nationality },
		{ label: t('birthday'), value: stepTwoData?.birthday },
		{ label: t('employmentRelationship'), value: t(stepTwoData?.employmentRelationship as string) },
		{ label: t('telephone_number'), value: stepTwoData?.phoneNumber },
		{ label: t('email'), value: stepTwoData?.email }
	];

	const total = useMemo(() => {
		let result: number = prices[parseInt(term as string, 10)][parseInt(km as string, 10)].value;
		if (stepOneData?.deliveryMode === 'deliver') {
			result += 180;
		}
		return result;
	}, [term, km, prices, stepOneData]);

	return (
		<div>
			<h4 className="text-[16px] sm:text-[20px] md:text-22 leading-[26px] text-[#3D3F40] mb-6 font-bold text-center">
				{t('your_subscription_to_simpcar')}
			</h4>
			<p className="text-center text-base md:text-lg leading-[26px] text-[#3D3F40] px-30">
				{t('we_take_care_of_everything_sentence')}
			</p>
			<div className="flex flex-wrap md:flex-nowrap justify-center items-center mt-30 gap-5">
				{services.map((item, index) => (
					<div
						key={index}
						className="border border-[#e9f2d9] rounded-10 text-17 text-center py-15 flex flex-col w-1/3 mx-5">
						<div className="flex justify-center">
							<img className="h-45 mb-2.5" src={item.image} alt={item.alt} />
						</div>
						<div className="text-xs leading-[18px]">{item.label}</div>
					</div>
				))}
			</div>
			<div className="mt-8 p-18 bg-[#F4F5F8] rounded-[10px] sm:p-30 md:p-50 md:mt-10">
				<h5 className="leading-[36px] mb-18 md:mb-9 font-bold text-lg md:text-22 font-red-hat text-[#3D3F40]">
					{t('tenant_information')}
				</h5>
				<div className="flex flex-wrap">
					{formDataArr.map((item, index) => (
						<div key={index} className="w-1/2 md:w-1/3 mb-15 px-5">
							<label className="text-[#3D3F40] text-15 leading-[20px]">{item.label}</label>
							<div className="py-5 font-bold text-[#3D3F40]">{item.value}</div>
						</div>
					))}
				</div>
				<h6 className="leading-[36px] md:mt-50 mb-2.5 md:mb-9 font-bold text-18 md:text-22 font-red-hat text-[#3D3F40]">
					{t('cost_summary')}
				</h6>
				<table className="w-full [&_td]:py-3 [&_tr:not(:first-child)]:border-t [&_tr:not(:first-child)]:border-[#909090]">
					<tbody>
						<tr>
							<td>
								<p className="text-15 md:text-base">
									<b>{make}</b> {model} – {t(transmission)} – {t(fuel)}
								</p>
								<span className="text-14 md:text-base">
									{t('minimum_rental_period')}: {term} {t('months')}. {t('monthly_rental_price')}.
								</span>
							</td>
							<td className="text-right">
								CHF
								<div className="inline-block font-bold w-16">
									{formatNumber(
										prices[parseInt(term as string, 10)][parseInt(km as string, 10)].value
									)}{' '}
								</div>
							</td>
						</tr>
						{stepOneData?.deliveryMode === 'deliver' && (
							<tr className="einmalig_delivery d-none">
								<td>
									<p>
										<b></b>
										{t('home_delivery')} ({t('one_off_180_chf')})
									</p>
								</td>
								<td className="text-right">
									CHF
									<div className="inline-block font-bold w-16">180.00</div>
								</td>
							</tr>
						)}
						<tr>
							<td>
								<p>
									<b>{t('total')}</b>
								</p>
							</td>
							<td id="total_amount_text" className="text-right">
								CHF
								<div className="inline-block font-bold w-16">{formatNumber(total)}</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="flex gap-15 md:my-10 mx-auto max-w-[810px]">
				<span
					className={cx(
						'size-26 bg-white rounded-full p-1 text-primary flex items-center justify-center border border-[#adb5bd] flex-shrink-0 mt-1',
						{ '!bg-primary !border-primary !text-white': checked }
					)}
					onClick={() => {
						setChecked(!checked);
						onCheck(!checked);
					}}>
					<CheckIcon className={cx(!checked && 'opacity-0')} />
				</span>
				{lng === 'de' && (
					<p className="text-15 md:text-17 leading-normal md:leading-[30px] text-[#3D3F40]">
						Ja, ich möchte online ein Auto-Abo bei simpcar ag abschliessen und bestätige, die{' '}
						<a
							href={getUrl('terms_conditions')}
							target="_blank"
							rel="noreferrer"
							className="text-primary">
							allgemeinen Vertragsbedingungen (AVB)
						</a>{' '}
						sowie die{' '}
						<a
							href={getUrl('privacy_policy')}
							target="_blank"
							rel="noreferrer"
							className="text-primary">
							Datenschutzbestimmungen
						</a>{' '}
						gelesen zu haben und erkläre mich damit einverstanden.
					</p>
				)}
				{lng === 'en' && (
					<p className="text-15 md:text-17 leading-normal md:leading-[30px] text-[#3D3F40]">
						Yes, I would like to take out a car subscription online with simpcar ag and confirm that
						I have read the{' '}
						<a
							href={getUrl('terms_conditions')}
							target="_blank"
							rel="noreferrer"
							className="text-primary">
							general terms and conditions (GTC)
						</a>{' '}
						and the{' '}
						<a
							href={getUrl('privacy_policy')}
							target="_blank"
							rel="noreferrer"
							className="text-primary">
							data protection regulations
						</a>{' '}
						and agree to them.
					</p>
				)}
				{lng === 'fr' && (
					<p className="text-15 md:text-17 leader-normal md:leading-[30px] text-[#3D3F40]">
						Oui, je souhaite souscrire un abonnement automobile en ligne auprès de simpcar ag et
						confirmer que{' '}
						<a
							href={getUrl('terms_conditions')}
							target="_blank"
							rel="noreferrer"
							className="text-primary">
							conditions générales de contrat (AVB)
						</a>{' '}
						aussi bien que{' '}
						<a
							href={getUrl('privacy_policy')}
							target="_blank"
							rel="noreferrer"
							className="text-primary">
							politique de confidentialité
						</a>{' '}
						Je l'ai lu et je l'accepte.
					</p>
				)}
			</div>
		</div>
	);
}

export default StepThree;
