import { useTranslation } from 'react-i18next';

import SurpriseImage from 'src/assets/images/surprise.png';
import Button from './button';

interface Props {
	onOpen: () => void;
}

function SurpriseCard({ onOpen }: Props) {
	const { t } = useTranslation();

	return (
		<div className="border border-[#707070] bg-[#F4F5F8] py-45 px-35 text-center flex flex-col items-center justify-center rounded-14 duration-300">
			<h5 className="font-bold text-center mb-[20px] font-red-hat text-[#3e4143]">
				{t('we_not_have_suitable_car')}
			</h5>
			<img className="w-full" src={SurpriseImage} />
			<p className="mt-30 mb-[20px]">{t('fill_out_will_organize_your_dream_car')}</p>
			<Button
				label={t('fill_out_form')}
				className="!text-sm !bg-white !text-primary hover:!text-white hover:!bg-primary w-full uppercase"
				onClick={onOpen}
			/>
		</div>
	);
}

export default SurpriseCard;
