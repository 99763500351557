export function StarIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18.903"
			height="18.041"
			viewBox="0 0 18.903 18.041">
			<path
				id="Vector"
				d="M12.16,6.167l6.077.2a.687.687,0,0,1,.4,1.229l-4.8,3.728,1.689,5.837a.684.684,0,0,1-1.039.76L9.462,14.512,4.434,17.92a.684.684,0,0,1-1.039-.76l1.689-5.837L.267,7.6a.688.688,0,0,1,.4-1.229l6.077-.2L8.812.45a.68.68,0,0,1,1.279,0Z"
				fill="#fbbd05"></path>
		</svg>
	);
}
