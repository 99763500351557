import { Disclosure, Transition } from '@headlessui/react';
import { FaChevronRight } from 'react-icons/fa';
import cx from 'classnames';

export interface QuestionProps {
	question: string;
	answer?: string;
	html?: string;
	selected?: boolean;
	index?: string;
	onClick?: (val: string) => void;
}

function QuestionItem({ question, answer, html, selected, index = '', onClick }: QuestionProps) {
	return (
		<div className="py-3 [&:not(:last-of-type)]:border-b border-[#E0E0E0]">
			<Disclosure defaultOpen={selected}>
				{({ open }) => (
					<>
						<Disclosure.Button
							className={cx(
								'flex justify-between items-center w-full py-0 lg:py-3 text-[#212529] font-semibold rounded-10 text-base lg:text-lg leading-normal gap-2 text-left font-red-hat'
							)}
							onClick={() => {
								onClick?.(index);
							}}>
							{question}
							<div className="w-4">
								<FaChevronRight className={cx({ 'rotate-90': open }, 'duration-300')} />
							</div>
						</Disclosure.Button>
						<Transition
							enter="duration-300 ease-in"
							enterFrom="opacity-0 h-0"
							enterTo="opacity-100 h-full"
							leave="duration-150 ease-out"
							leaveFrom="opacity-100 h-full"
							leaveTo="opacity-0 h-0">
							{answer && (
								<Disclosure.Panel className="py-15 text-15 font-red-hat text-black/70">
									{answer}
								</Disclosure.Panel>
							)}
							{html && (
								<Disclosure.Panel className="py-15">
									<div
										className="text-15 font-red-hat text-black/70"
										dangerouslySetInnerHTML={{ __html: html }}
									/>
								</Disclosure.Panel>
							)}
						</Transition>
					</>
				)}
			</Disclosure>
		</div>
	);
}

export default QuestionItem;
