import { useTranslation } from 'react-i18next';

import CheckIcon from 'src/assets/icons/check-icon.svg';

function InsuranceCard() {
	const { t } = useTranslation();

	const items = [
		t('insurance'),
		t('steer'),
		t('service_maintenance'),
		t('tires'),
		t('permit'),
		t('vignette_every_year'),
		t('multi_driver')
	];

	return (
		<div className="bg-[url('src/assets/images/offerbg.png')] bg-no-repeat bg-cover p-25 rounded-14">
			<h5 className="text-xl font-red-hat text-white mb-7">{t('care_free_package')}</h5>
			<div className="flex flex-col gap-15">
				{items.map((item, index) => (
					<div key={index} className="text-white flex items-center gap-[20px]">
						<span className="size-26 bg-white rounded-full p-1 text-primary flex items-center justify-center">
							<CheckIcon />
						</span>
						<span className="text-15">{item}</span>
					</div>
				))}
			</div>
		</div>
	);
}

export default InsuranceCard;
