import { CarPrice } from 'src/types/car';

export const useGetCarPrice = (prices: CarPrice) => {
	let min = 99999999,
		km = -1,
		month = -1;
	Object.keys(prices).forEach((mItem) => {
		const monthInt = parseInt(mItem, 10);
		Object.keys(prices[monthInt]).forEach((kItem) => {
			const kmInt = parseInt(kItem, 10);
			if (
				prices[monthInt][kmInt].enabled &&
				prices[monthInt][kmInt].value > 0 &&
				prices[monthInt][kmInt].value < min
			) {
				min = prices[monthInt][kmInt].value;
				km = kmInt;
				month = monthInt;
			}
		});
	});

	return {
		price: min,
		km,
		month
	};
};
