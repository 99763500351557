export const countryList = [
	{ code: 'CHE', name: 'Schweiz' },
	{ code: 'BEL', name: 'Belgien' },
	{ code: 'DEU', name: 'Deutschland' },
	{ code: 'FRA', name: 'Frankreich' },
	{ code: 'ITA', name: 'Italien' },
	{ code: 'LIE', name: 'Liechtenstein' },
	{ code: 'LUX', name: 'Luxemburg' },
	{ code: 'NLD', name: 'Niederlande' },
	{ code: 'AUT', name: 'Österreich' },
	{ code: 'PRT', name: 'Portugal' },
	{ code: 'ESP', name: 'Spanien' },
	{ code: 'GBR', name: 'Vereinigtes Königreich Großbritannien und Nordirland' },
	{ code: 'AFG', name: 'Afghanistan' },
	{ code: 'EGY', name: 'Ägypten' },
	{ code: 'ALA', name: 'Åland' },
	{ code: 'ALB', name: 'Albanien' },
	{ code: 'DZA', name: 'Algerien' },
	{ code: 'ASM', name: 'Amerikanisch-Samoa' },
	{ code: 'VIR', name: 'Amerikanische Jungferninseln' },
	{ code: 'AND', name: 'Andorra' },
	{ code: 'AGO', name: 'Angola' },
	{ code: 'AIA', name: 'Anguilla' },
	{ code: 'ATA', name: 'Antarktika (Sonderstatus durch Antarktis-Vertrag)' },
	{ code: 'ATG', name: 'Antigua und Barbuda' },
	{ code: 'GNQ', name: 'Äquatorialguinea' },
	{ code: 'ARG', name: 'Argentinien' },
	{ code: 'ARM', name: 'Armenien' },
	{ code: 'ABW', name: 'Aruba' },
	{ code: 'AZE', name: 'Aserbaidschan' },
	{ code: 'ETH', name: 'Äthiopien' },
	{ code: 'AUS', name: 'Australien' },
	{ code: 'BHS', name: 'Bahamas' },
	{ code: 'BHR', name: 'Bahrain' },
	{ code: 'BGD', name: 'Bangladesch' },
	{ code: 'BRB', name: 'Barbados' },
	{ code: 'BLR', name: 'Belarus (Weißrussland)' },
	{ code: 'BLZ', name: 'Belize' },
	{ code: 'BEN', name: 'Benin' },
	{ code: 'BMU', name: 'Bermuda' },
	{ code: 'BTN', name: 'Bhutan' },
	{ code: 'BOL', name: 'Bolivien' },
	{ code: 'BES', name: 'Bonaire, Sint Eustatius und Saba (Niederlande)' },
	{ code: 'BIH', name: 'Bosnien und Herzegowina' },
	{ code: 'BWA', name: 'Botswana' },
	{ code: 'BVT', name: 'Bouvetinsel' },
	{ code: 'BRA', name: 'Brasilien' },
	{ code: 'VGB', name: 'Britische Jungferninseln' },
	{ code: 'IOT', name: 'Britisches Territorium im Indischen Ozean' },
	{ code: 'BRN', name: 'Brunei Darussalam' },
	{ code: 'BGR', name: 'Bulgarien' },
	{ code: 'BFA', name: 'Burkina Faso' },
	{ code: 'BDI', name: 'Burundi' },
	{ code: 'CHL', name: 'Chile' },
	{ code: 'CHN', name: 'China' },
	{ code: 'COK', name: 'Cookinseln' },
	{ code: 'CRI', name: 'Costa Rica' },
	{ code: 'CIV', name: 'Côte d’Ivoire (Elfenbeinküste)' },
	{ code: 'CUW', name: 'Curaçao' },
	{ code: 'DNK', name: 'Dänemark' },
	{ code: 'DMA', name: 'Dominica' },
	{ code: 'DOM', name: 'Dominikanische Republik' },
	{ code: 'DJI', name: 'Dschibuti' },
	{ code: 'ECU', name: 'Ecuador' },
	{ code: 'SLV', name: 'El Salvador' },
	{ code: 'ERI', name: 'Eritrea' },
	{ code: 'EST', name: 'Estland' },
	{ code: 'FLK', name: 'Falklandinseln' },
	{ code: 'FRO', name: 'Färöer' },
	{ code: 'FJI', name: 'Fidschi' },
	{ code: 'FIN', name: 'Finnland' },
	{ code: 'GUF', name: 'Französisch-Guayana' },
	{ code: 'PYF', name: 'Französisch-Polynesien' },
	{ code: 'ATF', name: 'Französische Süd- und Antarktisgebiete' },
	{ code: 'GAB', name: 'Gabun' },
	{ code: 'GMB', name: 'Gambia' },
	{ code: 'GEO', name: 'Georgien' },
	{ code: 'GHA', name: 'Ghana' },
	{ code: 'GIB', name: 'Gibraltar' },
	{ code: 'GRD', name: 'Grenada' },
	{ code: 'GRC', name: 'Griechenland' },
	{ code: 'GRL', name: 'Grönland' },
	{ code: 'GLP', name: 'Guadeloupe' },
	{ code: 'GUM', name: 'Guam' },
	{ code: 'GTM', name: 'Guatemala' },
	{ code: 'GGY', name: 'Guernsey (Kanalinsel)' },
	{ code: 'GIN', name: 'Guinea' },
	{ code: 'GNB', name: 'Guinea-Bissau' },
	{ code: 'GUY', name: 'Guyana' },
	{ code: 'HTI', name: 'Haiti' },
	{ code: 'HMD', name: 'Heard und McDonaldinseln' },
	{ code: 'HND', name: 'Honduras' },
	{ code: 'HKG', name: 'Hongkong' },
	{ code: 'IND', name: 'Indien' },
	{ code: 'IDN', name: 'Indonesien' },
	{ code: 'IMN', name: 'Insel Man' },
	{ code: 'IRQ', name: 'Irak' },
	{ code: 'IRN', name: 'Iran' },
	{ code: 'IRL', name: 'Irland' },
	{ code: 'ISL', name: 'Island' },
	{ code: 'ISR', name: 'Israel' },
	{ code: 'JAM', name: 'Jamaika' },
	{ code: 'JPN', name: 'Japan' },
	{ code: 'YEM', name: 'Jemen' },
	{ code: 'JEY', name: 'Jersey (Kanalinsel)' },
	{ code: 'JOR', name: 'Jordanien' },
	{ code: 'CYM', name: 'Kaimaninseln' },
	{ code: 'KHM', name: 'Kambodscha' },
	{ code: 'CMR', name: 'Kamerun' },
	{ code: 'CAN', name: 'Kanada' },
	{ code: 'CPV', name: 'Kap Verde' },
	{ code: 'KAZ', name: 'Kasachstan' },
	{ code: 'QAT', name: 'Katar' },
	{ code: 'KEN', name: 'Kenia' },
	{ code: 'KGZ', name: 'Kirgisistan' },
	{ code: 'KIR', name: 'Kiribati' },
	{ code: 'CCK', name: 'Kokosinseln' },
	{ code: 'COL', name: 'Kolumbien' },
	{ code: 'COM', name: 'Komoren' },
	{ code: 'COG', name: 'Kongo' },
	{ code: 'PRK', name: 'Korea, Demokratische Volksrepublik (Nordkorea)' },
	{ code: 'KOR', name: 'Korea, Republik (Südkorea)' },
	{ code: 'XKX', name: 'Kosovo' },
	{ code: 'HRV', name: 'Kroatien' },
	{ code: 'CUB', name: 'Kuba' },
	{ code: 'KWT', name: 'Kuwait' },
	{ code: 'LAO', name: 'Laos, Demokratische Volksrepublik' },
	{ code: 'LSO', name: 'Lesotho' },
	{ code: 'LVA', name: 'Lettland' },
	{ code: 'LBN', name: 'Libanon' },
	{ code: 'LBR', name: 'Liberia' },
	{ code: 'LBY', name: 'Libyen' },
	{ code: 'LTU', name: 'Litauen' },
	{ code: 'MAC', name: 'Macau' },
	{ code: 'MDG', name: 'Madagaskar' },
	{ code: 'MWI', name: 'Malawi' },
	{ code: 'MYS', name: 'Malaysia' },
	{ code: 'MDV', name: 'Malediven' },
	{ code: 'MLI', name: 'Mali' },
	{ code: 'MLT', name: 'Malta' },
	{ code: 'MAR', name: 'Marokko' },
	{ code: 'MHL', name: 'Marshallinseln' },
	{ code: 'MTQ', name: 'Martinique' },
	{ code: 'MRT', name: 'Mauretanien' },
	{ code: 'MUS', name: 'Mauritius' },
	{ code: 'MYT', name: 'Mayotte' },
	{ code: 'MEX', name: 'Mexiko' },
	{ code: 'FSM', name: 'Mikronesien' },
	{ code: 'MDA', name: 'Moldawien (Republik Moldau)' },
	{ code: 'MCO', name: 'Monaco' },
	{ code: 'MNG', name: 'Mongolei' },
	{ code: 'MNE', name: 'Montenegro' },
	{ code: 'MSR', name: 'Montserrat' },
	{ code: 'MOZ', name: 'Mosambik' },
	{ code: 'MMR', name: 'Myanmar (Burma)' },
	{ code: 'NAM', name: 'Namibia' },
	{ code: 'NRU', name: 'Nauru' },
	{ code: 'NPL', name: 'Nepal' },
	{ code: 'NCL', name: 'Neukaledonien' },
	{ code: 'NZL', name: 'Neuseeland' },
	{ code: 'NIC', name: 'Nicaragua' },
	{ code: 'ANT', name: 'Niederländische Antillen' },
	{ code: 'NER', name: 'Niger' },
	{ code: 'NGA', name: 'Nigeria' },
	{ code: 'NIU', name: 'Niue' },
	{ code: 'MNP', name: 'Nördliche Marianen' },
	{ code: 'MKD', name: 'Nordmazedonien' },
	{ code: 'NFK', name: 'Norfolkinsel' },
	{ code: 'NOR', name: 'Norwegen' },
	{ code: 'OMN', name: 'Oman' },
	{ code: 'TLS', name: 'Osttimor (Timor-Leste)' },
	{ code: 'PAK', name: 'Pakistan' },
	{ code: 'PLW', name: 'Palau' },
	{ code: 'PAN', name: 'Panama' },
	{ code: 'PNG', name: 'Papua-Neuguinea' },
	{ code: 'PRY', name: 'Paraguay' },
	{ code: 'PER', name: 'Peru' },
	{ code: 'PHL', name: 'Philippinen' },
	{ code: 'PCN', name: 'Pitcairninseln' },
	{ code: 'POL', name: 'Polen' },
	{ code: 'PRI', name: 'Puerto Rico' },
	{ code: 'TWN', name: 'Republik China (Taiwan)' },
	{ code: 'REU', name: 'Réunion' },
	{ code: 'RWA', name: 'Ruanda' },
	{ code: 'ROU', name: 'Rumänien' },
	{ code: 'RUS', name: 'Russische Föderation' },
	{ code: 'BLM', name: 'Saint-Barthélemy' },
	{ code: 'MAF', name: 'Saint-Martin (franz. Teil)' },
	{ code: 'SPM', name: 'Saint-Pierre und Miquelon' },
	{ code: 'SLB', name: 'Salomonen' },
	{ code: 'ZMB', name: 'Sambia' },
	{ code: 'WSM', name: 'Samoa' },
	{ code: 'SMR', name: 'San Marino' },
	{ code: 'STP', name: 'São Tomé und Príncipe' },
	{ code: 'SAU', name: 'Saudi-Arabien' },
	{ code: 'SWE', name: 'Schweden' },
	{ code: 'SEN', name: 'Senegal' },
	{ code: 'SRB', name: 'Serbien' },
	{ code: 'SYC', name: 'Seychellen' },
	{ code: 'SLE', name: 'Sierra Leone' },
	{ code: 'ZWE', name: 'Simbabwe' },
	{ code: 'SGP', name: 'Singapur' },
	{ code: 'SXM', name: 'Sint Maarten (niederl. Teil)' },
	{ code: 'SVK', name: 'Slowakei' },
	{ code: 'SVN', name: 'Slowenien' },
	{ code: 'SOM', name: 'Somalia' },
	{ code: 'LKA', name: 'Sri Lanka' },
	{ code: 'SHN', name: 'St. Helena' },
	{ code: 'KNA', name: 'St. Kitts und Nevis' },
	{ code: 'LCA', name: 'St. Lucia' },
	{ code: 'VCT', name: 'St. Vincent und die Grenadinen' },
	{ code: 'PSE', name: 'Staat Palästina' },
	{ code: 'ZAF', name: 'Südafrika' },
	{ code: 'SDN', name: 'Sudan' },
	{ code: 'SGS', name: 'Südgeorgien und die Südlichen Sandwichinseln' },
	{ code: 'SSD', name: 'Südsudan' },
	{ code: 'SUR', name: 'Suriname' },
	{ code: 'SJM', name: 'Svalbard und Jan Mayen' },
	{ code: 'SWZ', name: 'Swasiland' },
	{ code: 'SYR', name: 'Syrien' },
	{ code: 'TJK', name: 'Tadschikistan' },
	{ code: 'TZA', name: 'Tansania, Vereinigte Republik' },
	{ code: 'THA', name: 'Thailand' },
	{ code: 'TGO', name: 'Togo' },
	{ code: 'TKL', name: 'Tokelau' },
	{ code: 'TON', name: 'Tonga' },
	{ code: 'TTO', name: 'Trinidad und Tobago' },
	{ code: 'TCD', name: 'Tschad' },
	{ code: 'CZE', name: 'Tschechien' },
	{ code: 'TUN', name: 'Tunesien' },
	{ code: 'TUR', name: 'Türkei' },
	{ code: 'TKM', name: 'Turkmenistan' },
	{ code: 'TCA', name: 'Turks- und Caicosinseln' },
	{ code: 'TUV', name: 'Tuvalu' },
	{ code: 'UGA', name: 'Uganda' },
	{ code: 'UKR', name: 'Ukraine' },
	{ code: 'HUN', name: 'Ungarn' },
	{ code: 'UMI', name: 'United States Minor Outlying Islands' },
	{ code: 'URY', name: 'Uruguay' },
	{ code: 'UZB', name: 'Usbekistan' },
	{ code: 'VUT', name: 'Vanuatu' },
	{ code: 'VAT', name: 'Vatikanstadt' },
	{ code: 'VEN', name: 'Venezuela' },
	{ code: 'ARE', name: 'Vereinigte Arabische Emirate' },
	{ code: 'USA', name: 'Vereinigte Staaten von Amerika' },
	{ code: 'VNM', name: 'Vietnam' },
	{ code: 'WLF', name: 'Wallis und Futuna' },
	{ code: 'CXR', name: 'Weihnachtsinsel' },
	{ code: 'ESH', name: 'Westsahara' },
	{ code: 'CAF', name: 'Zentralafrikanische Republik' },
	{ code: 'CYP', name: 'Zypern' }
];
