import { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Tooltip } from 'react-tooltip';

import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import InfoIcon from 'src/assets/icons/info-icon.svg';
import StepOne from './step-1';
import StepTwo from './step-2';
import StepThree from './step-3';
import Button from '../button';
import { StepOneData } from './step-1';
import { StepTwoData } from '../form/request-offer-form-2';
import { createOrder } from 'src/api/order';
import { Gender } from 'src/types/order';
import { useGetCar } from 'src/api/car';
import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';

function RequestStepsSection() {
	const { t } = useTranslation();
	const { id, term, km } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const { getUrl } = useMultiLngRoutes();

	const { car, carLoading } = useGetCar(id as string);

	const { prices, make, model } = car;

	if (carLoading) {
		return null;
	}

	const termInt = parseInt(term as string, 10);
	const kmInt = parseInt(km as string, 10);

	const [checked, setChecked] = useState(false);
	const [step, setStep] = useState(0);
	const [lsatStep, setLastStep] = useState(0);
	const [submitted, setSubmitted] = useState(false);
	const [stepOneData, setStepOneData] = useState<StepOneData>({});
	const [stepTwoData, setStepTwoData] = useState<StepTwoData | undefined>(undefined);

	const steps = [t('your_subscription'), t('tenant_data'), t('diploma')];

	const handlePrev = () => {
		if (step > 0) {
			setStep(step - 1);
		} else {
			navigate(
				getUrl('car_detail', {
					id: id as string,
					tag: [make, ...model.split(' ')].join('-').toLowerCase()
				})
			);
		}
	};

	const handleNext = async () => {
		setSubmitted(true);
		if (step === 0) {
			if (stepOneData.deliveryDate && stepOneData.deliveryMode) {
				setTimeout(() => {
					setStep(step + 1);
					setLastStep(step + 1);
				}, 500);
				setSubmitted(false);
			}
		} else if (step === 2) {
			try {
				await createOrder({
					carId: id as string,
					duration: termInt,
					km: kmInt,
					price: prices[termInt][kmInt].value,
					gender: stepTwoData?.gender as Gender,
					firstName: stepTwoData?.firstName as string,
					lastName: stepTwoData?.lastName as string,
					phoneNumber: stepTwoData?.phoneNumber as string,
					email: stepTwoData?.email as string,
					street: stepTwoData?.street as string,
					no: stepTwoData?.no as string,
					postalCode: stepTwoData?.postalCode as string,
					city: stepTwoData?.city as string,
					nationality: stepTwoData?.nationality as string,
					birthday: new Date(stepTwoData?.birthday as string),
					employmentRelationship: stepTwoData?.employmentRelationship as string,
					desiredStartDate: stepOneData.deliveryDate,
					isAuto: true,
					deliveryIncluded: stepOneData.deliveryMode === 'deliver',
					companyName: stepTwoData?.companyName as string,
					companyIndustry: stepTwoData?.companyIndustry as string,
					companyAddress: stepTwoData?.companyAddress as string,
					companyZip: stepTwoData?.companyZip as string,
					companyCity: stepTwoData?.companyCity as string
				});
				enqueueSnackbar('Sent your order successfully!');
				navigate(`${getUrl('car_subscribe_confirm')}?id=${id}&km=${km}&term=${term}`);
			} catch (err) {
				console.log(err);
				enqueueSnackbar('Failed to send your order!', { variant: 'error' });
			}
		}
	};

	const handleStepTwoChange = (val: StepTwoData) => {
		setStepTwoData(val);
		setStep(step + 1);
	};

	return (
		<section className="bg-white">
			<div className="custom-container">
				<div className="md:w-2/3 mx-auto py-30 md:pt-50 md:pb-20">
					<div className="flex justify-center mb-30 md:mb-70 gap-55">
						{steps.map((item, index) => (
							<div
								key={item}
								className="flex flex-col items-center gap-2 cursor-pointer"
								onClick={() => {
									if (index <= lsatStep) {
										setStep(index);
									}
								}}>
								<span
									className={cx(
										'bg-primary-100 text-primary size-30 leading-[30px] text-center text-base rounded-full font-medium',
										{ '!bg-primary text-white': step === index },
										'md:size-45 md:text-2xl md:leading-[45px] '
									)}>
									{index + 1}
								</span>
								<span
									className={cx(
										{ 'text-primary font-bold': step === index },
										'text-14',
										'md:text-base'
									)}>
									{item}
								</span>
							</div>
						))}
					</div>
					{step === 0 && (
						<StepOne
							submitted={step === 0 && submitted}
							formData={stepOneData}
							data={car}
							onChange={(val) => {
								setStepOneData(val);
							}}
						/>
					)}
					{step === 1 && (
						<StepTwo
							submitted={step === 1 && submitted}
							formData={stepTwoData}
							onChange={handleStepTwoChange}
						/>
					)}
					{step === 2 && (
						<StepThree
							stepOneData={stepOneData}
							stepTwoData={stepTwoData}
							onCheck={(val) => setChecked(val)}
						/>
					)}
					<div
						className={cx(
							'mt-50 flex flex-col-reverse justify-center items-center gap-x-30 gap-y-2.5',
							'md:flex-row'
						)}>
						<button className="flex items-center text-[#707070]" onClick={handlePrev}>
							<FaAngleLeft className="text-primary text-3xl" />
							{step === 0 ? t('back_to_car') : t('back')}
						</button>
						<Button
							active
							onClick={handleNext}
							type="submit"
							form={step === 1 ? 'request-offer-form-2' : undefined}
							disabled={step === 2 ? !checked : false}>
							{step === 2 ? t('submit_application') : t('next_step')}
							<FaAngleRight className="text-3xl" />
						</Button>
						{step === 2 && (
							<div className="size-30 tooltip">
								<InfoIcon className="size-30" />
							</div>
						)}
					</div>
				</div>
			</div>
			<Tooltip
				anchorSelect=".tooltip"
				place="top"
				style={{ maxWidth: '250px', textAlign: 'center' }}>
				{t('contract_tooltip')}
			</Tooltip>
		</section>
	);
}

export default RequestStepsSection;
