import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

// import HeroImage from 'src/assets/images/simpcar-auto-abo-schweiz.webp';
import GreenLine from 'src/assets/images/green_line_075.svg';
import HowToWork from 'src/assets/images/auto-abo-wie-funktioniert-es.webp';
import CarImage1 from 'src/assets/images/mercedes-auto-abo.png';
import CarImage2 from 'src/assets/images/volov-auto-abo.png';
import MarkImage from 'src/assets/images/location.png';

function HeroSection() {
	const { t } = useTranslation();

	return (
		<section
			className={cx(
				'relative flex justify-center pt-24 pb-16 lg:pb-[120px]',
				"after:absolute after:-bottom-[0px] lg:after:-bottom-[120.5px] after:content-[''] after:bg-[url('src/assets/images/herobg-bottom.png')] after:w-full after:h-1/2 after:bg-[length:100%] after:bg-no-repeat after:bg-bottom after:-z-[1]",
				'pt-[120px] lg:pt-[200px]'
			)}>
			<div className="flex flex-col-reverse custom-container lg:flex-row">
				<div className="flex-1 px-3">
					<p className="text-[#93a2bc] text-base mb-5 text-center lg:text-left">
						{t('greetings_and_welcome')}
					</p>
					<div className="text-[#3e4143] text-26 font-bold mb-25 leading-tight font-red-hat lg:text-5xl lg:mb-45 text-center lg:text-left">
						<div className="inline">{t('car_subscription_switzerland')}</div>.&nbsp;
						<div className="inline">
							{t('clear_with')} <span className="text-primary">simpcar</span>.
						</div>
						<div className="inline">
							&nbsp;{t('a')}&nbsp;
							<div className="relative inline">
								{t('price')}
								<img
									className="w-full absolute right-0 bottom-[-10px]"
									src={GreenLine}
									alt="green_line"
									title="green_line"
								/>
							</div>
							, {t('all_inclusive')}
						</div>
					</div>
					<div className="mb-10 font-medium font-red-hat text-center text-[#4b5c7a] text-15 leading-relaxed md:text-lg lg:text-left lg:mb-65">
						{t('enjoy_limitless_sentence')}
					</div>
					<div className="flex items-center justify-center gap-4 md:gap-35 lg:justify-start">
						<Link
							className={cx(
								'flex justify-center items-center h-[40px] md:h-[60px] border-2 border-primary text-13 md:text-lg w-full max-w-[120px] md:max-w-[200px] rounded-35 duration-150 py-2 px-5 md:py-2.5 md:px-26 bg-primary text-white',
								'hover:bg-transparent hover:text-primary'
							)}
							to="/autos"
							title="Zu den Autos im Abo">
							{t('view_cars')}
						</Link>
						<Link
							to="/auto-abo-schweiz"
							className="flex items-center text-[#313131] text-13 md:text-lg leading-none">
							<span className="flex justify-center items-center bg-primary rounded-full size-10 md:size-12 mr-2.5">
								<img src={HowToWork} width={43} />
							</span>
							{t('how_it_works')}
						</Link>
					</div>
				</div>
				<div className="relative flex-1 px-3 max-[450px]:min-h-[250px] min-h-[290px] sm:min-h-[320px] md:min-h-[480px] lg:min-h-0">
					<img className="absolute w-5/12 animate-from-top sm:w-60 md:w-80" src={MarkImage} />
					<img
						className="absolute w-8/12 animate-from-right sm:w-400 md:w-600 top-50 left-50"
						src={CarImage2}
					/>
					<img
						className="absolute w-8/12 animate-from-right-1 top-20 sm:w-350 sm:top-28 left-130 md:w-550 md:top-36 md:left-150"
						src={CarImage1}
					/>
				</div>
			</div>
		</section>
	);
}

export default HeroSection;
