import { useTranslation } from 'react-i18next';

import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';
import Image from 'src/assets/images/simpcar-auto-abonnieren.webp';
import CircleCheck from 'src/assets/icons/circle-check.svg';

import './simple-mobility-section.css';

function SimpleMobilitySection() {
	const { t, i18n } = useTranslation();
	const lng = i18n.language;

	const { getUrl } = useMultiLngRoutes();

	const texts = [
		t('many_years_experience'),
		t('talented_team_with_great_motivation'),
		t('simple_digital_process'),
		t('uncomplicated_customer_friendly')
	];

	return (
		<div className="py-10 lg:py-20">
			<div className="custom-container">
				<div className="flex flex-col w-full gap-30 md:flex-row">
					<div className="relative flex-1 md:px-35">
						<div className="shape1 -top-8 -right-[0.6rem] transform-[translate3d(0px,-9px,0px)] absolute size-28 rounded-full z-[1]"></div>
						<div className="bg-primary-100 -bottom-8 -left-[0.4rem] transform-[translate3d(0px,14px,0px)] absolute size-28  rounded-full z-[1]"></div>
						<img className="max-w-full w-full relative z-[2] rounded-10" src={Image} />
					</div>
					<div className="flex flex-col justify-center flex-1">
						<h2 className="text-xl md:text-27 text-[#3e4143] font-red-hat font-medium mb-2">
							{lng === 'de' && (
								<>
									<span className="text-primary">simpcar</span> - die simple Mobilität der Zukunft
								</>
							)}
							{lng === 'en' && (
								<>
									<span className="text-primary">simpcar</span> - the simple mobility of the future
								</>
							)}
							{lng === 'fr' && (
								<>
									<span className="text-primary">simpcar</span> - la mobilité simple du futur
								</>
							)}
						</h2>
						<p className="leading-6 text-15 md:text-base mb-25">
							{t('we_are_young_dynamic_sentence')}
						</p>
						<ul className="flex flex-col flex-wrap w-full md:flex-row text-15 md:text-base">
							{texts.map((text, index) => (
								<li
									key={index}
									className="float-left w-full md:w-1/2 relative pl-[28px] pr-15 mb-15">
									<img
										className="absolute left-0 top-[2px] text-[#cde1a8] size-[20px]"
										src={CircleCheck}
										alt="circle-check"
									/>
									<span>{text}</span>
								</li>
							))}
						</ul>
						<div className="flex justify-center mt-25 md:block">
							<a
								className="py-2.5 px-26 bg-primary rounded-35 font-medium duration-300 text-white border-2 border-solid border-primary hover:text-primary hover:bg-white"
								href={getUrl('car_subscription_switzerland')}
								title="auto abo schweiz so funktioniert es">
								{t('this_is_how_it_works')}
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SimpleMobilitySection;
