import { useState } from 'react';

import Car from '../home/car';
import Drawer from '../drawer';
import InsuranceCard from '../insurance-card';
import SurpriseCard from '../surprise-card';
import CloseIcon from 'src/assets/icons/close-icon.svg';
import DreamCarForm from '../form/dream-car-form';
import PackagesCard from '../packages-card';
import { ICarItem } from 'src/types/car';

interface Props {
	data: ICarItem[];
}

function CarsListSection({ data }: Props) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="grid grid-cols-1 py-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 lg:py-70 gap-x-15 gap-y-30">
			{data.map((item, index) => (
				<Car key={index} data={item} />
			))}
			<PackagesCard />
			<InsuranceCard />
			<SurpriseCard onOpen={() => setIsOpen(true)} />
			<Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
				<>
					<div className="bg-primary p-30">
						<div className="w-[calc(100%-60px)] text-white">
							<div className="text-base font-bold md:text-xl mb-15">
								Teile uns mit, welches Auto du gern hättest
							</div>
							<div className="text-sm md:text-base">
								Unser Team macht sich auf die Suche nach dem gewünschten Auto. Hierbei nutzen wir
								unser Netzwerk und geben alles, um dir das Auto-Abo anbieten zu können.
							</div>
						</div>
						<span
							className="absolute flex items-center justify-center bg-white rounded-full cursor-pointer right-35 top-35 size-35 text-primary"
							onClick={() => {
								setIsOpen(false);
							}}>
							<CloseIcon className="w-15" />
						</span>
					</div>
					<div className="p-30">
						<DreamCarForm onClose={() => setIsOpen(false)} />
					</div>
				</>
			</Drawer>
		</div>
	);
}

export default CarsListSection;
