import { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';
import QuestionItem, { QuestionProps } from './question-item';

function QuestionsSection() {
	const { t } = useTranslation();
	const { getUrl } = useMultiLngRoutes();
	const [selected, setSelected] = useState('');

	const questions: Record<string, QuestionProps[]> = {
		'Allgemeine Infos': [
			{
				question: 'Was ist in deinem Auto Abo alles enthalten?',
				html: `
				<h4 class="mb-2"> Folgendes ist in deinem Auto Abo inbegriffen:</h4>
				<ul class="list-disc pl-7 mb-[20px]">
					<li>Versicherung (Vollkasko & Haftpflicht)</li>
					<li>Fahrzeugsteuern</li>
					<li>Zulassung an deinem Wohnsitz</li>
					<li>Winter- und Sommerreifen, inkl. Reifenwechsel und –Lagerung</li>
					<li>Service und Wartung</li>
					<li>Autobahnvignette (jedes Jahr)</li>
				</ul>
				<h4>Wie du siehst, ist im simpcar Auto-Abo alles rund um dein Auto inbegriffen. Nur noch tanken musst du selber.</h4>
			`
			},
			{
				question: 'Was sind Voraussetzungen, um ein Auto Abo abschliessen zu können?',
				html: `
					<p>
						Damit du ein <a href="${getUrl('car_subscription_switzerland')}" title="Auto Abo" class="text-primary">Auto Abo</a> bei der simpcar abschliessen kannst, benötigst du einen anerkannten Führerschein der Kategorie B sowie einen Wohnsitz in der Schweiz. Bei Führerscheinen aus dem Ausland werden wir eine individuelle Prüfung vornehmen. Mit deinen Angaben prüfen wir zudem deine Bonität. Sollten Betreibungen und Verlustscheine vorliegen, müssen wir deinen Antrag leider ablehnen.
					</p>
				`
			},
			{
				question: 'Kann eine Firma ein Auto Abo abschliessen?',
				html: `
				<p>
					Jede Firma mit einem Sitz in der Schweiz kann bei uns ein Auto Abo abschliessen. Für Firmen existieren spezielle Auto Abo Firmenpakete. Für mehr Infos kontaktiere uns auf 
					<a href="mailto:info@simpcar.ch" class="text-primary">info@simpcar.ch</a> oder ruf uns unter der Nummer <a href="tel:+41315582500" class="text-primary">031 558 25 00</a>
				</p>
			`
			},
			{
				question: 'Welche Laufzeiten stehen zur Verfügung?',
				answer:
					'Unsere Abos kannst du wahlweise mit einer Laufzeit von 3, 6, 12, 24 oder 36 Monaten abschliessen. Nach Ablauf der entsprechenden Mindestlaufzeit kannst du dein Abo mit einer Frist von 30 Tagen auf Ende des Monats kündigen.'
			},
			{
				question: 'Kann ich mein Auto nach Hause bringen lassen?',
				answer:
					'Selbstverständlich kannst du dein neues Auto zu dir nach Hause bringen lassen. Wir müssen dir aber eine kleine Pauschale von CHF 180.— verrechnen. Wahlweise kannst du dein Auto auch kostenlos an unserem Hauptsitz in Säriswil, BE abholen. Bei jeder Übergabe, ob Heimlieferung oder Abholung, erhältst du eine persönliche Einführung zum Auto.'
			},
			{
				question: 'Wie funktioniert die Bezahlung?',
				answer:
					'Nachdem Du die ersten zwei Monatsraten (1. Monatsrate im Voraus und eine Monatsrate als Kaution) bezahlt hast, kannst du entweder dein Auto bei uns abholen oder wir bringen dir es gegen einen kleinen Aufpreis von CHF 180.- an jeden beliebigen Ort in der Schweiz.'
			},
			{
				question:
					'Was geschieht, falls ich die in meinem Abo inbegriffene Anzahl Kilometer überschreite?',
				answer:
					'Falls du deine Abo-Kilometer überschreitest, werden deine Mehrkilometer im Rahmen der Schlussabrechnung nach der Rückgabe deines Autos abgerechnet. Solltest du merken, dass dein Kilometer-Paket nicht reicht, kannst du dich mit uns in Kontakt setzen und wir finden eine passende Lösung. Allfällige Minderkilometer, das heisst nicht beanspruchte Abo-Kilometer, können dir nicht gutgeschrieben werden.'
			},
			{
				question:
					'Ich habe bereits ein eigenes Auto, möchte es jedoch loswerden. Was mache ich mit meinem alten Auto?',
				html: `
					Da unser Partner, die Automobile Wohlensee AG, auch den An- und Verkauf von Autos anbietet, hast du dort die Möglichkeit, ein attraktives Angebot für dein aktuelles Auto einzuholen. <a href="https://www.automobile-wohlensee.ch/autoankauf" title="Autoankauf Schweiz" target="_blank" class="text-primary">Hier holst du dir deine Offerte</a>
				`
			}
		],
		'Rund ums Auto': [
			{
				question: 'Bietet die simpcar ag auch Elektroautos an?',
				answer:
					'Ja, wir bieten dir auch Elektroautos im Abo an (z. B. Tesla Model S). Das Sortiment an Elektroautos ist momentan noch überschaubar. Wir sind jedoch mit Hochtouren daran, das Sortiment an Elektroautos zu erweitern.'
			},
			{
				question: 'Darf ich mit meinem Auto ins Ausland fahren?',
				answer:
					'Ja, mit deinem Auto darfst du ins Ausland fahren. Bedenke dabei, dass du bei Fahrten ins Ausland allfällige erforderliche Dokumente und zusätzliches Sicherheitszubehör im Auto mitführen musst.'
			},
			{
				question: 'Darf eine andere Person mein Auto fahren?',
				answer:
					'Ja, Mehrfahrer sind erlaubt. Dein Auto im Abo kann beispielsweise von deiner Familie oder deinen Freunden gefahren werden.'
			},
			{
				question: 'Wie und wo kann ich meine Sommer- bzw. Winterreifen wechseln lassen?',
				answer:
					'Vereinbare hierzu einen Termin bei der simpcar ag in Säriswil, BE. Du kannst anschliessend dein Auto zum vereinbarten Termin vorbeibringen und der Reifenwechsel wird umgehend von unserer internen Werkstatt durchgeführt. Falls deine Reifen nicht mehr über genügend Profil verfügen, montieren wir dir ebenfalls fabrikneue Reifen.'
			},
			{
				question: 'Wo und wie lasse ich den Service meines Autos durchführen?',
				answer:
					'Sobald du eine Meldung bezüglich des Serviceintervalls oder eine sonstige Meldung auf dem Bordcomputer erhältst, melde dich bei der SimpCar AG und vereinbare einen Termin, um den Service durchzuführen. Sollte es etwas länger dauern, bekommst du selbstverständlich einen Ersatzwagen von uns zur Verfügung gestellt.'
			},
			{
				question: 'Was mache ich bei einem Unfall oder einer Panne?',
				answer:
					'Im Falle von notwendigen Wartungs- oder Reparaturarbeiten sowie bei einem Unfall, stellt die simpcar ag dem Kunden ein Ersatzauto sowie einen 24h-Pannendienst zur Verfügung. Die Kosten für das Ersatzauto und der Pannendienst sind im monatlichen Entgelt enthalten.'
			}
		],
		'Rückgabe und Stornierung': [
			{
				question: 'Kann ich mein Auto Abo stornieren?',
				answer:
					"Nach Abschluss des Abonnementvertrages und der Fahrzeugübergabe kannst du grundsätzlich erst zum Ende der von dir gewählten Mindestlaufzeit kündigen. Solltest du dein Autoabonnement jedoch vor Ablauf der vereinbarten Vertragsdauer kündigen wollen, stellen wir dir die uns entstandenen Kosten in Rechnung. Diese setzen sich zusammen aus einer Pauschalgebühr von CHF 1'500.- sowie dem Differenzmietpreis, der sich aus der effektiven Dauer deines Abonnements ergibt."
			},
			{
				question: 'Wie erfolgt die Rückgabe meines Autos bei Ende des Abos?',
				answer:
					'Bei Ende des Auto Abos kannst du dein Auto an unserem Hauptsitz in Säriswil, BE abgeben. Bitte vereinbare dafür mit einem unserer Mitarbeitenden einen Termin für die Rückgabe. Gemeinsam überprüfen wir das Auto auf allfällige Schäden und Gebrauchsspuren. Normale Gebrauchsspuren gehören zur Abnützung eines Autos und werden nicht in Rechnung gestellt. Schäden, die nicht von der Vollkaskoversicherung übernommen werden oder oberhalb des vereinbarten Selbstbehalts liegen, müssen wir dir in Rechnung stellen.'
			},
			{
				question: 'Wie und wann kann ich das Abo künden?',
				html: `
				<div>
					Nach Ablauf der Mindestlaufzeit kannst du dein <a href="${getUrl('car_subscription_switzerland')}" title=" Auto-Abo Schweiz" class="text-primary">Auto Abo</a> jeder Zeit jeweils auf Ende des Monats mit einer Frist von 30 Tagen künden. Schicke uns dazu bitte eine E-Mail an <a href="mailto:info@simpcar.ch" class="text-primary">info@simpcar.ch</a> oder schreibe uns einen eingeschriebenen Brief an simpcar ag, Heidmoosweg 15 in 3049 Säriswil.
				</div>
			`
			},
			{
				question: 'Besteht die Möglichkeit das Auto nach Ende der Laufzeit zu kaufen?',
				html: `
				<div>
					Selbstverständlich kannst du das Auto nach Ablauf der Abo-Laufzeit kaufen. Kontaktiere uns auf <a href="mailto:info@simpcar.ch" class="text-primary">info@simpcar.ch</a> oder ruf uns unter der Nummer <a href="tel:+41315582500" class="text-primary">031 558 25 00</a> an. Gerne unterbreiten wir dir ein passendes Angebot für dein Auto.
				</div>
			`
			}
		]
	};

	return (
		<section className="pt-6 lg:pt-50">
			<div className="custom-container">
				{Object.keys(questions).map((oKey, oIndex) => (
					<div key={`qg-${oIndex}-${selected}`} className={cx('flex flex-col lg:flex-row mb-50')}>
						<h2 className="text-19 lg:text-25 text-[#212529] font-medium font-red-hat mb-4 lg:pt-4 lg:w-1/3">
							{oKey}
						</h2>
						<div className="px-4 py-4 bg-white lg:px-10 rounded-2xl lg:w-2/3">
							{questions[oKey].map((qItem, qIndex) => (
								<QuestionItem
									key={`q-${qIndex}-${selected}`}
									selected={`${oKey}-${qIndex}` === selected}
									index={`${oKey}-${qIndex}`}
									question={qItem.question}
									answer={qItem.answer}
									html={qItem.html}
									onClick={(val) => {
										setSelected(val);
									}}
								/>
							))}
						</div>
					</div>
				))}
			</div>
		</section>
	);
}

export default QuestionsSection;
