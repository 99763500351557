import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import cx from 'classnames';

interface TextareaProps extends UseFormRegisterReturn {
	label: string;
	wrapperClass?: string;
	isError?: boolean;
	isOptional?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
	({ wrapperClass, name, label, isError, isOptional, ...rest }: TextareaProps, ref) => (
		<div
			className={cx(
				'w-full bg-white rounded-15 pt-2.5 pb-5 px-15 flex flex-col items-start border',
				{ 'border-red-700': isError },
				wrapperClass
			)}>
			<label className="text-[#8D8E93] text-13">
				{label} {isOptional ? '(optional)' : ''}
			</label>
			<textarea
				className="w-full border-none bg-none text-base font-bold text-[#8D8E93] py-5 outline-none"
				id={name}
				name={name}
				rows={4}
				{...rest}
				ref={ref}
			/>
		</div>
	)
);

Textarea.displayName = 'Textarea';

export default Textarea;
