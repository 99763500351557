import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import BrandImage from 'src/assets/images/autoabo-schweiz-günstig.webp';
import Image1 from 'src/assets/images/simpcar-auto-abo.webp';
import Image2 from 'src/assets/images/simpcar-auto-konfigurieren.webp';
import Image3 from 'src/assets/images/simpcar-abo-online-abschliessen.webp';
import Image4 from 'src/assets/images/simpcar-abo-lieferung.webp';

interface Props {
	image: string;
	imageAlt: string;
	imageTitle: string;
	title: string;
	description: string;
}

function Box({
	number,
	image,
	imageAlt,
	imageTitle,
	title,
	description
}: Props & { number: number }) {
	return (
		<div
			className={cx(
				'group relative flex-1 text-center',
				{ 'lg:mt-50': number % 2 === 0 },
				{ 'after:opacity-0': number === 4 },
				{ 'after:-scale-x-100': number % 2 === 0 },
				'after:hidden lg:after:block after:absolute after:-right-10 after:top-10 after:rotate-90 after:content-[""] after:bg-contain after:bg-center after:w-10 after:h-70 after:bg-no-repeat',
				'after:bg-[url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNDYuNjQ0IiBoZWlnaHQ9IjEwOC45MTIiIHZpZXdCb3g9IjAgMCA0Ni42NDQgMTA4LjkxMiI+DQogIDxkZWZzPg0KICAgIDxjbGlwUGF0aCBpZD0iY2xpcC1wYXRoIj4NCiAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMTIiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDEyIiB3aWR0aD0iNDYuNjQ0IiBoZWlnaHQ9IjEwOC45MTIiIGZpbGw9IiM5MmJkNDQiLz4NCiAgICA8L2NsaXBQYXRoPg0KICA8L2RlZnM+DQogIDxnIGlkPSJHcm91cF80MyIgZGF0YS1uYW1lPSJHcm91cCA0MyIgY2xpcC1wYXRoPSJ1cmwoI2NsaXAtcGF0aCkiPg0KICAgIDxwYXRoIGlkPSJQYXRoXzM4IiBkYXRhLW5hbWU9IlBhdGggMzgiIGQ9Ik0xMi41NTMsMTA4LjkxMmMtMi43MzUtMS4yODMtNC40MzEtMS41MTctNC45NzItMi40MzMtMi4wMTEtMy40MTItNC4xNjgtNi44ODYtNS4zMTQtMTAuNjI5LTMuMDk0LTEwLjEtMi43MjYtMjAuNC0uNjY1LTMwLjY5LDMuNS0xNy41LDExLjQ5My0zMi45ODgsMjEuMy00Ny42NjcsMS4wNDgtMS41NjcsMi4wMTYtMy4xOSwyLjk5LTQuOC4wNi0uMS0uMTQ2LS4zNjMtLjM0OC0uODIxLTIuOS43MzEtNS44NTQsMS42MzItOC44NzYsMi4xNzhhMTAuNzQ0LDEwLjc0NCwwLDAsMS00Ljk0OS0uMkEzLjcyNSwzLjcyNSwwLDAsMSw5LjQ3OCwxMS4yYTYuMDY3LDYuMDY3LDAsMCwxLDEuNzQ4LTMuNjVjLjYxNy0uNjgxLDEuOS0uODE0LDIuOTA5LTEuMDczLDcuOTM2LTIuMDMzLDE1Ljg0NC00LjE5NSwyMy44MzEtNiw2Ljg0OC0xLjU1LDkuMDQuNjA5LDguNjMxLDcuMzgtLjQxOSw2LjkzLS40NjcsMTMuODgyLS43NjQsMjAuODItLjA5MiwyLjE0Ni0uNDY1LDQuMjgxLS43NDIsNi43LTUuNTg2LTIuNDkyLTUuNTg2LTIuNDkyLTcuNTU3LTIzLjg2LTguMjY3LDkuMi0xMy44LDE5LjIyNi0xOC45NTQsMjkuNDZBMTA5LjQsMTA5LjQsMCwwLDAsOC4xOTUsNzMuNDE0Yy0xLjgzMywxMS42NjguNDU5LDIyLjg5NSw0LjM1OCwzNS41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDAuMDAxKSIgZmlsbD0iIzkyYmQ0NCIvPg0KICA8L2c+DQo8L3N2Zz4NCg==")]'
			)}>
			<div
				className={cx(
					'group-hover:overflow-hidden group-hover:bg-primary relative flex size-177 rounded-full mt-0 mx-auto mb-4 lg:mb-50 border border-primary bg-white duration-300'
				)}>
				<img
					className="w-40 m-auto group-hover:animate-to-top"
					src={image}
					title={imageTitle}
					alt={imageAlt}
				/>
				<span className="group-hover:hidden inline-block absolute top-0 right-2.5 size-10 bg-primary text-center rounded-full border-4 border-white text-white font-bold text-xs leading-8">
					{number.toString().padStart(2, '0')}
				</span>
			</div>
			<h3 className="font-bold leading-6 mb-2.5 text-lg text-[#707070] font-red-hat">{title}</h3>
			<p className="text-base leading-6 text-[#707070]">{description}</p>
		</div>
	);
}

function SubscriptionSection({ page = 'home' }: { page?: string }) {
	const { t } = useTranslation();

	const boxes: Props[] = [
		{
			image: Image1,
			imageAlt: 'Autos im Online-Abo',
			imageTitle: 'Autos im Online-Abo',
			title: t('choose_car'),
			description: t('use_the_search_function_find_the_car')
		},
		{
			image: Image2,
			imageAlt: 'Auto Abo online konfigurieren',
			imageTitle: 'Auto Abo online konfigurieren',
			title: t('configure_subscription'),
			description: t('put_together_subscription_services')
		},
		{
			image: Image3,
			imageAlt: 'Auto-Abo online abschliessen',
			imageTitle: 'Auto-Abo online abschliessen',
			title: t('book_online'),
			description: t('book_your_desired_car_online')
		},
		{
			image: Image4,
			imageAlt: 'Auto-Abo online Lieferung',
			imageTitle: 'Auto-Abo online Lieferung',
			title: t('car_delivered_to_you'),
			description: t('as_soon_as_all_formalities_completed')
		}
	];

	return (
		<section className="py-30 lg:py-100">
			<div className="custom-container">
				<div className="flex flex-col items-center text-center mb-50 lg:mb-75">
					<img
						className="w-16 lg:w-1/12 mb-17"
						src={BrandImage}
						title="Günstigstes Auto-Abo in der Schweiz"
						alt="Günstigstes Auto-Abo in der Schweiz"
					/>
					<h2 className="text-22 lg:text-33 color-[#3e4143] text-center font-red-hat font-medium">
						{page === 'home' && (
							<>
								{t('car_subscription_in_switzerland')}{' '}
								<span className="lowercase text-primary">{t('this_is_how_it_works')}</span>
							</>
						)}
						{page === 'car-subscription' && <>{t('this_is_how_car_subscription_works')}</>}
						{page === 'about-us' && (
							<>
								{t('this_is_how_car_subscription_works_1')}{' '}
								<span className="text-primary">simpcar</span>
							</>
						)}
					</h2>
					<h3 className="mt-2.5 text-base lg:text-lg text-[#0C111F] font-light font-red-hat">
						{t('get_worry_free_driving')}
					</h3>
				</div>
				<div className="flex flex-col md:flex-row md:flex-wrap lg:flex-nowrap gap-30">
					{boxes.map((item, index) => (
						<Box
							key={index}
							number={index + 1}
							image={item.image}
							imageAlt={item.imageAlt}
							imageTitle={item.imageTitle}
							title={item.title}
							description={item.description}
						/>
					))}
				</div>
			</div>
		</section>
	);
}

export default SubscriptionSection;
