import { useState } from 'react';
import cx from 'classnames';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import { useTranslation } from 'react-i18next';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

import './cars-preview.css';

import { ICarItem } from 'src/types/car';
import { getImageUrl } from 'src/utils/string';

interface Props {
	data: ICarItem;
}

function CarsPreview({ data }: Props) {
	const { t } = useTranslation();
	const { interiorImages } = data;

	const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);

	return (
		<div className={cx('my-30')}>
			<h3 className="text-lg lg:text-xl mb-15 font-red-hat text-[#3e4143]">{t('pictures')}</h3>
			<Swiper
				slidesPerView={1}
				spaceBetween={10}
				navigation={true}
				thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
				modules={[FreeMode, Navigation, Thumbs]}
				className="cars-preview-swiper mb-25">
				{interiorImages.map((item, index) => (
					<SwiperSlide key={index}>
						<img src={getImageUrl(item)} />
					</SwiperSlide>
				))}
			</Swiper>
			<Swiper
				onSwiper={(swiper: SwiperClass) => {
					setThumbsSwiper(swiper);
				}}
				spaceBetween={10}
				slidesPerView={4}
				freeMode={true}
				watchSlidesProgress={true}
				modules={[FreeMode, Navigation, Thumbs]}
				className="cars-thumbs-swiper">
				{interiorImages.map((item, index) => (
					<SwiperSlide key={index}>
						<img src={getImageUrl(item)} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}

export default CarsPreview;
