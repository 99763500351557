import useSWR from 'swr';
import { useMemo } from 'react';

import { fetcher, endpoints } from 'src/configs/axios';
import { ICarItem, SearchParams } from 'src/types/car';

export function useGetCar(id: string) {
	const URL = id ? [endpoints.car.details, { params: { id } }] : '';

	const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

	const memoizedValue = useMemo(
		() => ({
			car: data?.data as ICarItem,
			carLoading: isLoading,
			carError: error,
			carValidating: isValidating
		}),
		[data?.data, error, isLoading, isValidating]
	);

	return memoizedValue;
}

export function useGetCars() {
	const URL = endpoints.car.list;

	const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

	const memoizedValue = useMemo(
		() => ({
			cars: (data?.data as ICarItem[]) || [],
			carsLoading: isLoading,
			carsError: error,
			carsValidating: isValidating,
			carsEmpty: !isLoading && !data?.data.length
		}),
		[data?.data, error, isLoading, isValidating]
	);

	return memoizedValue;
}

export function useSearchCars(params?: SearchParams) {
	const URL = [endpoints.car.search, { params: params || {} }];

	const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, {
		keepPreviousData: true
	});

	const memoizedValue = useMemo(
		() => ({
			total: data?.total || 0,
			searchResults: (data?.data as ICarItem[]) || [],
			searchLoading: isLoading,
			searchError: error,
			searchValidating: isValidating,
			searchEmpty: !isLoading && !data?.data.length,
			refetch: mutate
		}),
		[data?.data, data?.total, error, isLoading, isValidating, mutate]
	);

	return memoizedValue;
}
