interface Props {
	className?: string;
}

export default function SearchIcon({ className }: Props) {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="18.495"
			height="18.503"
			viewBox="0 0 18.495 18.503">
			<path
				id="search"
				d="M18.289,17.332l-4.89-4.89a7.6,7.6,0,1,0-.915.915l4.886,4.894a.647.647,0,0,0,.915-.915ZM3.124,12.005a6.307,6.307,0,1,1,4.461,1.849,6.307,6.307,0,0,1-4.461-1.849Z"
				transform="translate(0.021 0.062)"
				fill="currentColor"></path>
		</svg>
	);
}
