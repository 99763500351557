import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';

function CallToActionSection() {
	const { t } = useTranslation();

	const { getUrl } = useMultiLngRoutes();

	return (
		<section className="bg-[#3e4143] text-white text-22 py-35">
			<div className="flex flex-col items-center justify-between gap-5 custom-container md:flex-row">
				<div className="mb-4 text-17 md:mb-0 sm:text-22">{t('do_you_want_to_know_more')}</div>
				<a
					className={cx(
						'py-3 px-26 bg-primary rounded-35 text-base font-medium duration-300 text-white border-2 border-primary',
						'hover:bg-transparent hover:text-primary'
					)}
					href={getUrl('contact')}
					title="Kontaktiere uns">
					{t('contact_us')}
				</a>
			</div>
		</section>
	);
}

export default CallToActionSection;
