function TopSection() {
	return (
		<div className="h-80 md:h-400 bg-primary-100">
			<div className="custom-container h-full">
				<div className="flex items-center justify-center h-full">
					<div className="pt-24">
						<h1 className="text-[20px] md:text-32 mb-2.5 font-red-hat font-normal md:font-bold text-[#3e4143] text-center">
							Häufig gestellte <span className="text-primary">Fragen</span> unserer Kunden:innen
						</h1>
						<div className="text-15 md:test-base text-center leading-8">
							Hier findest du eine Zusammenstellung der wichtigsten Informationen.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TopSection;
