import { useTranslation } from 'react-i18next';

import GoogleImage from 'src/assets/icons/icon-google.svg';
import StarImage from 'src/assets/icons/icon-star.svg';

export function GoogleReviewsSection() {
	const { t } = useTranslation();

	return (
		<section className="mt-50 pb-4 hidden lg:block">
			<div className="custom-container flex gap-9 items-center">
				<img src={GoogleImage} alt="icon-google" title="Google" className="w-50" />
				<div className="flex justify-center flex-col">
					<div className="flex mb-2 gap-1">
						<img src={StarImage} alt="icon-star" title="Star" className="w-14" />
						<img src={StarImage} alt="icon-star" title="Star" className="w-14" />
						<img src={StarImage} alt="icon-star" title="Star" className="w-14" />
						<img src={StarImage} alt="icon-star" title="Star" className="w-14" />
						<img src={StarImage} alt="icon-star" title="Star" className="w-14" />
						<span className="ml-2 text-sm text-primary">{t('5_out_of_5_stars')}</span>
					</div>
					<div className="text-15">{t('from_53_reviews')}</div>
				</div>
			</div>
		</section>
	);
}

export default GoogleReviewsSection;
