import SubscriptionSection from 'src/components/car-subscription/subscription-section';
import CheapSubscriptionSecton from 'src/components/car-subscription/cheap-subscription-section';
import CarSpecSection from 'src/components/car-subscription/car-spec-section';
import SubscriptionSection1 from 'src/components/home/subscription-section';
import UpgradeMobilitySection from 'src/components/car-subscription/upgrade-mobility-section';
import CarBrandSection from 'src/components/car-subscription/car-brand-section';

function CarSubscription() {
	return (
		<>
			<SubscriptionSection />
			<div className="bg-white">
				<div className="custom-container py-6 sm:py-20">
					<CheapSubscriptionSecton />
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<CarSpecSection />
					<SubscriptionSection1 page="car-subscription" />
					<br />
					<br />
					<UpgradeMobilitySection />
					<br />
					<br />
					<CarBrandSection />
				</div>
			</div>
		</>
	);
}

export default CarSubscription;
