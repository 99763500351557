import { useForm, UseFormReset } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import Turnstile from 'react-turnstile';

import { phoneRegExp } from 'src/constants';
import ArrowRight from 'src/assets/icons/arrow-right.svg';
import Input from '../input';
import Button from '../button';
import Textarea from '../text-area';
import PhoneNumberInput from '../phone-input';
import { TURNSTILE_SITE_KEY } from 'src/configs/config';

import '../radio.css';

type FormType = 'Form1' | 'Form2' | 'Form3' | 'Form4';

interface Props {
	type: FormType;
	onSend: (
		data: { [x: string]: string | undefined },
		reset: UseFormReset<{
			[x: string]: string | undefined;
		}>
	) => void;
}

function ContactForm({ type, onSend }: Props) {
	const { t, i18n } = useTranslation();
	const { language } = i18n;

	let obj: Record<string, yup.StringSchema<string | undefined, yup.AnyObject, undefined, ''>> = {
		gender: yup.string().required(),
		firstName: yup.string().required(),
		lastName: yup.string().required(),
		phoneNumber: yup.string().required().matches(phoneRegExp),
		token: yup.string()
	};

	if (type !== 'Form2') {
		obj['email'] = yup.string().required().email();

		if (type === 'Form3' || type === 'Form4') {
			obj = {
				...obj,

				address: yup.string().required(),
				postalCode: yup.string().required(),
				city: yup.string().required(),
				subscriptionContract: yup.string().required(),
				carInQuestion: yup.string().required()
			};

			if (type === 'Form4') {
				obj['desiredCar'] = yup.string().required();
			}
		}
	} else {
		obj['availableFromTo'] = yup.string().required();
	}

	if (type === 'Form1' || type === 'Form2') {
		obj['message'] = yup.string().required();
	} else {
		obj['message'] = yup.string().optional();
	}

	const validationSchema = yup.object(obj);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
		getValues
	} = useForm({ resolver: yupResolver(validationSchema), mode: 'onChange' });

	const { token, phoneNumber } = getValues();

	return (
		<form
			id="contact-form"
			className="flex flex-col gap-25"
			onSubmit={handleSubmit((data) => onSend(data, reset))}>
			<div className="w-full flex gap-[20px] items-center">
				<div className="flex items-center gap-3">
					<input id="mr" type="radio" value="mr" {...register('gender')} />
					<label htmlFor="mr" className="text-white">
						{t('mr')}
					</label>
				</div>
				<div className="flex items-center gap-3">
					<input id="mrs" type="radio" value="mrs" {...register('gender')} />
					<label htmlFor="mrs" className="text-white">
						{t('mrs')}
					</label>
				</div>
				{!!errors['gender'] && (
					<span className="font-normal text-[80%] text-danger">{t('required_field')}</span>
				)}
			</div>
			<div className="w-full flex flex-col md:flex-row gap-25">
				<Input
					label={t('firstname')}
					isError={!!errors['firstName']}
					{...register('firstName', { required: true })}
				/>
				<Input
					label={t('lastname')}
					isError={!!errors['lastName']}
					{...register('lastName', { required: true })}
				/>
			</div>
			{(type === 'Form3' || type === 'Form4') && (
				<div className="w-full flex flex-col md:flex-row gap-25">
					<Input
						label={t('address')}
						isError={!!errors['address']}
						wrapperClass="md:!w-1/2"
						{...register('address', { required: true })}
					/>
					<div className="md:!w-1/2 flex flex-col md:flex-row gap-25">
						<Input
							label={t('postal_code')}
							isError={!!errors['postalCode']}
							wrapperClass="md:!w-1/3"
							{...register('postalCode', { required: true })}
						/>
						<Input
							label={t('city')}
							isError={!!errors['city']}
							wrapperClass="md:!w-2/3"
							{...register('city', { required: true })}
						/>
					</div>
				</div>
			)}
			<div className="w-full flex flex-col md:flex-row gap-25">
				<PhoneNumberInput
					value={phoneNumber}
					label={t('telephone_number')}
					isError={!!errors['phoneNumber']}
					{...register('phoneNumber', { required: true })}
					onPhoneNumberChange={(val) => setValue('phoneNumber', val)}
				/>
				{type === 'Form2' ? (
					<Input
						label={t('available_from_to')}
						isError={!!errors['availableFromTo']}
						{...register('availableFromTo', { required: true })}
					/>
				) : (
					<Input
						label={t('email_address')}
						isError={!!errors['email']}
						{...register('email', { required: true })}
					/>
				)}
			</div>
			{(type === 'Form3' || type === 'Form4') && (
				<div className="w-full flex flex-col md:flex-row gap-25">
					<Input
						label={t('cancel_subscription_contract')}
						isError={!!errors['subscriptionContract']}
						{...register('subscriptionContract', { required: true })}
					/>
					<Input
						label={t('regarding_car')}
						isError={!!errors['carInQuestion']}
						{...register('carInQuestion', { required: true })}
					/>
				</div>
			)}
			{type === 'Form4' && (
				<div className="w-full flex flex-col md:flex-row gap-25">
					<Input
						label={t('desired_car')}
						isError={!!errors['desiredCar']}
						wrapperClass="md:!w-1/2"
						{...register('desiredCar', { required: true })}
					/>
					<div className="w-1/2 hidden md:block" />
				</div>
			)}
			<Textarea
				label={t('your_message')}
				isOptional={['Form3', 'Form4'].includes(type)}
				isError={!!errors['message']}
				{...register('message')}
			/>
			<div className="flex justify-between items-center">
				<Button form="contact-form" type="submit" variant="revert">
					{t('submit_form')} <ArrowRight className="ml-2" />
				</Button>
				<Turnstile
					sitekey={TURNSTILE_SITE_KEY}
					language={language}
					onVerify={(token) => setValue('token', token)}
				/>
			</div>
		</form>
	);
}

export default ContactForm;
