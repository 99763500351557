import { ASSET_BASE_URL } from 'src/configs/config';

export const getInitials = (name: string) => {
	return name
		.toUpperCase()
		.split(' ')
		.map((item) => item[0])
		.join('');
};

export const getImageUrl = (url: string): string => {
	if (!url) return '';
	return `${ASSET_BASE_URL}/${url}`;
};
