import { Menu, Transition } from '@headlessui/react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const { Button: MenuButton, Item: MenuItem, Items: MenuItems } = Menu;

import USFlagImage from 'src/assets/icons/flag-us.svg';
import DEFlagImage from 'src/assets/icons/flag-germany.svg';
import FRFlagImage from 'src/assets/icons/flag-france.svg';

interface Props {
	className?: string;
}

type Flag = 'en' | 'fr' | 'de';

const flags: Record<Flag, { label: string; image: string }> = {
	de: {
		label: 'Deutsch',
		image: DEFlagImage
	},
	en: {
		label: 'English',
		image: USFlagImage
	},
	fr: {
		label: 'Français',
		image: FRFlagImage
	}
};

function LanguageSelector({ className }: Props) {
	const { i18n } = useTranslation();

	const selected = i18n.language as Flag;

	const handleLanguageChange = (val: Flag) => {
		i18n.changeLanguage(val);
	};

	return (
		<div className={cx('relative', className)}>
			<Menu>
				<MenuButton
					className={cx(
						'inline-flex justify-center items-center gap-2 rounded-full size-10  shadow-inner shadow-white/10  aria-expanded:bg-primary',
						'hover:bg-primary/80'
					)}>
					<img className="rounded" src={flags[selected].image} width={24} />
				</MenuButton>
				<Transition
					enter="transition ease-out duration-75"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95">
					<MenuItems className="absolute right-0 w-52 origin-bottom-right bg-primary/15 backdrop-blur-lg rounded-10 p-1 text-sm/6 text-white">
						{Object.keys(flags).map((key) => (
							<MenuItem key={key}>
								<button
									className={cx(
										'group flex w-full items-center gap-4 rounded-lg py-1.5 px-3 font-semibold bg-[rgba(145, 158, 171, 0.16] mb-1 text-[#212B36]',
										'hover:bg-primary/30',
										{ 'bg-primary/30': selected === key }
									)}
									onClick={() => {
										handleLanguageChange(key as Flag);
									}}>
									<img className="rounded" src={flags[key as Flag].image} width={24} />
									<span>{flags[key as Flag].label}</span>
								</button>
							</MenuItem>
						))}
					</MenuItems>
				</Transition>
			</Menu>
		</div>
	);
}

export default LanguageSelector;
