import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import { useTranslation } from 'react-i18next';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import './car-brand-section.css';

import CarImage1 from 'src/assets/images/car-Audi-Logo.jpg';
import CarImage2 from 'src/assets/images/car-BMW-Logo.jpg';
import CarImage3 from 'src/assets/images/car-Jeep-Logo.jpg';
import CarImage4 from 'src/assets/images/car-Mazda-Logo.jpg';
import CarImage5 from 'src/assets/images/car-Mercedes-Logo.jpg';
import CarImage6 from 'src/assets/images/car-Mini-Logo.jpg';
import CarImage7 from 'src/assets/images/car-Renault-Logo.jpg';
import CarImage8 from 'src/assets/images/car-Skoda-Logo.jpg';
import CarImage9 from 'src/assets/images/car-Toyota-Logo.jpg';
import CarImage10 from 'src/assets/images/car-Volvo-Logo.jpg';
import CarImage11 from 'src/assets/images/car-VW-Logo.jpg';

interface Props {
	image: string;
	label: string;
	alt: string;
}

function CarBrand({ image, label, alt }: Props) {
	return (
		<div className="flex flex-col items-center m-15 pb-2.5 text-center uppercase">
			<div className="flex items-center justify-center h-100">
				<img src={image} alt={alt} />
			</div>
			<span className="text-15">{label}</span>
		</div>
	);
}

function CarBrandSection() {
	const { t } = useTranslation();

	const carBrands: Props[] = [
		{
			image: CarImage1,
			label: 'Audi',
			alt: 'Audi Logo'
		},
		{
			image: CarImage2,
			label: 'BMW',
			alt: 'BMW Logo'
		},
		{
			image: CarImage4,
			label: 'Mazda',
			alt: 'Mazda Logo'
		},
		{
			image: CarImage5,
			label: 'Mercedes',
			alt: 'Mercedes Logo'
		},
		{
			image: CarImage6,
			label: 'Mini',
			alt: 'Mini Logo'
		},
		{
			image: CarImage8,
			label: 'Skoda',
			alt: 'Skoda Logo'
		},
		{
			image: CarImage3,
			label: 'Jeep',
			alt: 'Jeep Logo'
		},
		{
			image: CarImage7,
			label: 'Renault',
			alt: 'Renault Logo'
		},
		{
			image: CarImage9,
			label: 'Toyota',
			alt: 'Toyota Logo'
		},
		{
			image: CarImage10,
			label: 'Volvo',
			alt: 'Volvo Logo'
		},
		{
			image: CarImage11,
			label: 'VW',
			alt: 'VW Logo'
		}
	];

	return (
		<div className="pb-12">
			<h2 className="text-center text-19 lg:text-27 font-medium font-red-hat text-[#3e4143] mb-2">
				{t('car_brands_on_subscription')}
			</h2>
			<Swiper
				className="my-swiper"
				autoplay={{
					delay: 2500,
					disableOnInteraction: false
				}}
				loop={true}
				slidesPerView={1}
				spaceBetween={10}
				breakpoints={{
					480: {
						slidesPerView: 2,
						spaceBetween: 20
					},
					640: {
						slidesPerView: 3,
						spaceBetween: 20
					},
					768: {
						slidesPerView: 4,
						spaceBetween: 40
					},
					1024: {
						slidesPerView: 5,
						spaceBetween: 40
					},
					1200: {
						slidesPerView: 6,
						spaceBetween: 40
					}
				}}
				navigation={true}
				modules={[Navigation, Autoplay]}>
				{carBrands.map((item, index) => (
					<SwiperSlide key={index}>
						<CarBrand {...item} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}

export default CarBrandSection;
