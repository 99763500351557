import { useRef } from 'react';
import { closeSnackbar, SnackbarProvider as NotistackProvider } from 'notistack';
import { FaTimes } from 'react-icons/fa';

import Button from 'src/components/button';

// ----------------------------------------------------------------------

type Props = {
	children: React.ReactNode;
};

export default function SnackbarProvider({ children }: Props) {
	const notistackRef = useRef<any>(null);

	return (
		<NotistackProvider
			ref={notistackRef}
			maxSnack={5}
			preventDuplicate
			autoHideDuration={3000}
			variant="success" // Set default variant
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			// iconVariant={{
			//   info: (
			//     <StyledIcon color="info">
			//       <Iconify icon="eva:info-fill" width={24} />
			//     </StyledIcon>
			//   ),
			//   success: (
			//     <StyledIcon color="success">
			//       <Iconify icon="eva:checkmark-circle-2-fill" width={24} />
			//     </StyledIcon>
			//   ),
			//   warning: (
			//     <StyledIcon color="warning">
			//       <Iconify icon="eva:alert-triangle-fill" width={24} />
			//     </StyledIcon>
			//   ),
			//   error: (
			//     <StyledIcon color="error">
			//       <Iconify icon="solar:danger-bold" width={24} />
			//     </StyledIcon>
			//   ),
			// }}
			// Components={{
			//   default: StyledNotistack,
			//   info: StyledNotistack,
			//   success: StyledNotistack,
			//   warning: StyledNotistack,
			//   error: StyledNotistack,
			// }}
			// with close as default
			action={(snackbarId) => (
				<FaTimes className="mr-2" width={16} onClick={() => closeSnackbar(snackbarId)} />
			)}>
			{children}
		</NotistackProvider>
	);
}
