import { useTranslation } from 'react-i18next';

import MultiRoutes from 'src/configs/routes';
import { LanguageType } from 'src/types';

export const useMultiLngRoutes = () => {
	const { i18n } = useTranslation();
	const lng = i18n.language;

	const getUrl = (url: string, params?: Record<string, number | string>) => {
		let route = MultiRoutes[url][lng as LanguageType];
		if (params) {
			Object.keys(params).forEach((key) => {
				route = route.replace(`/:${key}`, `/${params[key]}`);
			});
		}

		return route;
	};

	return {
		getUrl
	};
};
