interface Props {
	className?: string;
}

function ArrowRightIcon({ className }: Props) {
	return (
		<svg
			className={className}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			width="9.3"
			height="16"
			fill="currentColor"
			stroke="currentColor"
			viewBox="0 0 9.3 16">
			<g>
				<path
					className="st1"
					d="M0,14.7c0-0.2,0.1-0.4,0.2-0.5c0.9-0.9,1.8-1.8,2.6-2.6C4,10.4,5.2,9.3,6.3,8.2C6.4,8,6.4,8,6.3,7.8 c-2-2-4-4-6-6C0.2,1.8,0.2,1.7,0.1,1.6C0,1.4,0,1.2,0.1,1C0.4,0.7,0.7,0.4,1,0.1c0.2-0.2,0.5-0.2,0.7,0c0,0,0.1,0.1,0.1,0.1 C4.2,2.7,6.6,5.1,9,7.5c0.4,0.4,0.4,0.6,0,1c-2.4,2.4-4.8,4.8-7.2,7.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.2,0.2-0.5,0.2-0.7,0 c-0.3-0.3-0.6-0.6-0.9-0.9C0,14.9,0,14.8,0,14.7z"></path>
			</g>
		</svg>
	);
}

export default ArrowRightIcon;
