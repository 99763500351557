import { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useEventListener } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Logo from 'src/assets/images/logo.svg';
import AgentCall from 'src/assets/icons/icon-agent-call.svg';
import CloseIcon from 'src/assets/icons/close-icon.svg';
import { BarsIcon } from 'src/assets/icons/bars.svg';
import LanguageSelector from 'src/components/language-selector';
import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';

function Header() {
	const { t } = useTranslation();
	const location = useLocation();

	const { getUrl } = useMultiLngRoutes();

	const [showMenu, setShowMenu] = useState(false);
	const [isFixed, setIsFixed] = useState(true);

	const links = [
		{
			href: getUrl('cars'),
			label: t('our_cars'),
			title: 'Unsere Autos im Abo'
		},
		{
			href: getUrl('car_subscription_switzerland'),
			label: t('this_is_how_it_works'),
			title: 'Auto Abo günstig in der Schweiz'
		},
		{
			href: getUrl('about_us'),
			label: t('about_simpcar'),
			title: 'simpcar ag'
		},
		{
			href: getUrl('blog'),
			label: 'Blog',
			title: 'simpcar ag Blog'
		},
		{ href: getUrl('faq'), label: 'FAQ', title: 'häufige fragen zum auto abo' }
	];

	const onScroll = () => {
		const scrollTop = window.scrollY;
		setIsFixed(scrollTop <= 50);
	};

	useEventListener('scroll', onScroll);

	return (
		<header
			className={cx(
				isFixed
					? 'absolute px-4 lg:pb-2 lg:pt-[20px] xl:shadow-none bg-white lg:bg-transparent'
					: 'fixed px-2.5 bg-white sm:px-0',
				'top-0 py-2.5 w-full flex justify-center items-center z-header transition duration-150 shadow-[0_4px_2px_rgba(0,0,0,0.05)] lg:shadow-[0_2px_20px_rgba(0,0,0,0.05)]'
			)}>
			<div className="flex items-center justify-between custom-container">
				<button className="block xl:hidden" onClick={() => setShowMenu(!showMenu)}>
					<BarsIcon />
				</button>
				<Link to={getUrl('index')} title="Startseite">
					<img
						className="my-2 h-35 sm:h-45 sm:my-2 lg:my-6"
						src={Logo}
						alt="simpcar auto abo schweiz"
						title="simpcar auto abo schweiz"
					/>
				</Link>
				<nav className="items-center justify-center flex-1 hidden xl:flex">
					<ul className="flex justify-between items-center gap-1 p-1 rounded-35 bg-white shadow-[0_0_1px_0_#23232387]">
						{links.map((item) => (
							<li key={item.href} className="text-lg">
								<Link
									className={cx(
										'inline-block text-primary rounded-38 py-3 px-8 text-base font-medium leading-5',
										'hover:bg-primary hover:text-white',
										{ 'bg-primary text-white': item.href === location.pathname }
									)}
									to={item.href}
									title={item.title}>
									{item.label}
								</Link>
							</li>
						))}
					</ul>
				</nav>
				<nav
					className={cx(
						'fixed top-0 bg-white xl:hidden w-full h-full transition-all duration-500 ease-out z-10',
						showMenu ? 'left-0' : 'left-[-100%]'
					)}>
					<div className="p-6">
						<Link to={getUrl('index')} title="Startseite">
							<img
								className="my-2 h-35 sm:h-45 sm:my-2 lg:my-6"
								src={Logo}
								alt="simpcar auto abo schweiz"
								title="simpcar auto abo schweiz"
							/>
						</Link>
						<span
							className="absolute right-4 lg:right-35 top-4 lg:top-35 size-35 bg-white rounded-full flex justify-center items-center cursor-pointer text-primary"
							onClick={() => setShowMenu(!showMenu)}>
							<CloseIcon className="w-15" />
						</span>
					</div>
					<div className="mobileMenu bg-primary mr-6 rounded-e-15 p-6">
						<div className="welcome-txt font-semibold text-white mb-4">
							{t('welcome_to_simpcar')}
						</div>
						<ul className="flex flex-col">
							{links.map((item) => (
								<li key={item.href} className="py-4 border-t border-[#d5d5d5] text-xl">
									<Link
										className={cx('block px-0 text-white leading-5', {
											'text-white': item.href === location.pathname
										})}
										to={item.href}
										title={item.title}
										onClick={() => setShowMenu(false)}>
										{item.label}
									</Link>
								</li>
							))}
						</ul>
					</div>
				</nav>
				<LanguageSelector className="mr-2" />
				<Link
					className={cx(
						'py-2.5 px-7 text-white bg-primary rounded-35 text-base transition duration-150 leading-5 hidden',
						'hover:bg-transparent hover:text-primary',
						'xl:inline-block'
					)}
					to={getUrl('contact')}>
					{t('contact')}
				</Link>
				<Link
					className={cx('bg-white rounded-full transition duration-150 inline-block', 'xl:hidden')}
					to={getUrl('contact')}>
					<img src={AgentCall} className="w-26 lg:w-53" />
				</Link>
			</div>
		</header>
	);
}

export default Header;
