import { useTranslation } from 'react-i18next';

import Logo from 'src/assets/images/logo.svg';
import Image1 from 'src/assets/images/simpcar_telefon.webp';
import Image2 from 'src/assets/images/simpcar_adresse.webp';
import Image3 from 'src/assets/images/simpcar_email.webp';
import GoogleImage from 'src/assets/icons/icon-google.svg';
import StarImage from 'src/assets/icons/icon-star.svg';
import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';

import CallToActionSection from 'src/components/home/call-to-action-section';

function Footer() {
	const { t, i18n } = useTranslation();

	const lng = i18n.language;

	const { getUrl } = useMultiLngRoutes();

	return (
		<footer className="bg-[#F4F5F8]">
			<CallToActionSection />
			<div className="pt-50 custom-container flex gap-30 flex-col flex-wrap lg:flex-nowrap md:flex-row">
				<div className="md:w-5/12">
					<div className="mb-25">
						<img className="w-40" src={Logo} alt="simpcar" title="simpcar" />
					</div>
					{lng === 'de' && (
						<p className="leading-6 text-15 max-w-[95%]">
							Das <b>Auto-Abo</b> von <b>simpcar</b> ist eine flexible Alternative zum Kauf oder
							Leasing eines Fahrzeugs. Es bietet die Möglichkeit, ein Fahrzeug für einen
							festgelegten Zeitraum zu nutzen, ohne sich um die Kosten und die Wartung kümmern zu
							müssen.
						</p>
					)}
					{lng === 'en' && (
						<p className="leading-6 text-15 max-w-[95%]">
							The <b>simpcar car subscription</b> is a flexible alternative to buying or leasing a
							vehicle. It offers the opportunity to use a vehicle for a set period of time without
							having to worry about costs and maintenance.
						</p>
					)}

					{lng === 'fr' && (
						<p className="leading-6 text-15 max-w-[95%]">
							<b>L'abonnement automobile simpcar</b> est une alternative flexible à l'achat ou à la
							location d'un véhicule. Il offre la possibilité d’utiliser un véhicule pendant une
							durée déterminée sans avoir à se soucier des coûts et de l’entretien.
						</p>
					)}
					<div className="mt-25">
						<div className="mt-15">
							<div className="flex gap-15 items-center mb-15">
								<img
									className="w-30"
									src={Image1}
									title="Simpcar AG Telefon Nummer"
									alt="simpcar_telefon"
								/>
								<a className="text-base text-[#707070]" href="tel:+41315582500">
									031 558 25 00
								</a>
							</div>
							<div className="flex gap-15 items-center mb-15">
								<img
									className="w-30"
									src={Image2}
									title="Simpcar AG Adresse"
									alt="simpcar_adresse"
								/>
								<div className="text-[#707070]">
									Heidmoosweg 15
									<br />
									3049 Säriswil
								</div>
							</div>
							<div className="flex gap-15 items-center mb-15">
								<img className="w-30" src={Image3} title="Simpcar Email" alt="simpcar_email" />
								<a className="text-[#707070] text-base" href="mailto:info@simpcar.ch">
									info@simpcar.ch
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="md:w-3/12">
					<p className="font-roboto font-bold text-base mb-2.5">{t('the_car_subscription')}</p>
					<div className="leading-loose">
						<a
							className="text-[#707070] text-sm"
							href={getUrl('car_subscription_switzerland')}
							title="Auto Abo in der Schweiz">
							{t('this_is_how_it_works')}
						</a>
					</div>
					<div className="leading-loose">
						<a
							className="text-[#707070] text-sm"
							href={getUrl('cars')}
							title="Auto im Abo in Schweiz">
							{t('explore_cars')}
						</a>
					</div>
					<div className="leading-loose">
						<a
							className="text-[#707070] text-sm"
							href={getUrl('faq')}
							title="Häufige Fragen zum Auto-Abo">
							{t('FAQ')}
						</a>
					</div>
					<p className="font-roboto font-bold text-base mt-6 mb-2.5">simpcar</p>
					<div className="leading-loose">
						<a
							className="text-[#707070] text-sm"
							href={getUrl('about_us')}
							title="Über die Firma simpcar ag">
							{t('about_us')}
						</a>
					</div>
					<div className="leading-loose">
						<a
							className="text-[#707070] text-sm"
							href={getUrl('contact')}
							title="simpcar kontaktieren">
							{t('contact')}
						</a>
					</div>
				</div>
				<div className="md:w-4/12">
					<div className="flex gap-15 items-center">
						<img src={GoogleImage} alt="icon-google" title="Google" className="w-10" />
						<div className="flex justify-center flex-col">
							<div className="flex mb-2 gap-1">
								<img src={StarImage} alt="icon-star" title="Star" className="w-3" />
								<img src={StarImage} alt="icon-star" title="Star" className="w-3" />
								<img src={StarImage} alt="icon-star" title="Star" className="w-3" />
								<img src={StarImage} alt="icon-star" title="Star" className="w-3" />
								<img src={StarImage} alt="icon-star" title="Star" className="w-3" />
								<span className="ml-2 text-xs text-primary">{t('5_out_of_5_stars')}</span>
							</div>
							<div className="text-13">{t('from_53_reviews')}</div>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-35 bg-primary py-[20px]">
				<div className="custom-container">
					<div className="flex flex-wrap md:flex-nowrap justify-between items-center">
						<p className="text-15 text-white">
							<a
								className="text-white"
								href={getUrl('terms_conditions')}
								title="Allgemeine Geschäftsbedingungen">
								{t('terms_conditions')}
							</a>{' '}
							–{' '}
							<a className="text-white" href={getUrl('privacy_policy')} title="Datenschutz">
								{t('privacy_policy')}
							</a>{' '}
							–{' '}
							<a className="text-white" href={getUrl('imprint')} title="Impressum">
								{t('impressum')}
							</a>
						</p>
						<p className="text-15 text-white">© 2024 simpcar. {t('all_rights_reserved')}.</p>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
