import { useTranslation } from 'react-i18next';
import ServiceImage1 from 'src/assets/images/simpcar-auto-abo-versicherung.webp';
import ServiceImage2 from 'src/assets/images/simpcar-auto-abo-strassenverkehrssteuer.webp';
import ServiceImage3 from 'src/assets/images/simpcar-auto-abo-service-werkstatt.webp';
import ServiceImage4 from 'src/assets/images/simpcar-auto-abo-bereifung.webp';
import ServiceImage5 from 'src/assets/images/simpcar-auto-abo-zulassung.webp';
import ServiceImage6 from 'src/assets/images/simpcar-auto-abo-autobahnvignette.webp';
import BrandImage from 'src/assets/images/mercedes-auto-abo.png';

interface SpecProps {
	image: string;
	label: string;
	description: string;
	alt: string;
	title: string;
}

function SpecItem({ image, label, description, alt, title }: SpecProps) {
	return (
		<div className="flex">
			<div className="size-50 rounded-md">
				<img src={image} alt={alt} title={title} />
			</div>
			<div className="w-[calc(100%-50px)] pl-15">
				<h4 className="text-lg mb-2.5 font-red-hat font-normal text-[#3e4143]">{label}</h4>
				<p className="leading-[22px]">{description}</p>
			</div>
		</div>
	);
}

function CarSpecSection() {
	const { t } = useTranslation();

	const specs: SpecProps[] = [
		{
			image: ServiceImage1,
			label: t('insurance'),
			description: t('insurance_description'),
			alt: t('insurance_alt'),
			title: t('insurance_alt')
		},
		{
			image: ServiceImage2,
			label: t('steer'),
			description: t('steer_description'),
			alt: t('steer_alt'),
			title: t('steer_alt')
		},
		{
			image: ServiceImage3,
			label: t('service_maintenance'),
			description: t('service_maintenance_description'),
			alt: t('service_maintenance_alt'),
			title: t('service_maintenance_alt')
		},
		{
			image: ServiceImage4,
			label: t('tires'),
			description: t('tires_description'),
			alt: t('tires_alt'),
			title: t('tires_alt')
		},
		{
			image: ServiceImage5,
			label: t('permit'),
			description: t('permit_description'),
			alt: t('permit_alt'),
			title: t('permit_alt')
		},
		{
			image: ServiceImage6,
			label: t('sticker'),
			description: t('sticker_description'),
			alt: t('sticker_alt'),
			title: t('sticker_alt')
		}
	];

	return (
		<div className="flex gap-30 flex-wrap md:flex-nowrap px-15 md:px-0">
			<div className="w-full md:w-3/12">
				<ul>
					{specs.slice(0, 3).map((item, index) => (
						<li key={`left-${index}`} className="mb-35">
							<SpecItem {...item} />
						</li>
					))}
				</ul>
			</div>
			<div className="w-full md:w-1/2 flex justify-center items-center">
				<img
					className="max-w-full"
					src={BrandImage}
					alt="Mercedes Benz Auto Abo"
					title="Mercedes Benz Auto Abo"
				/>
			</div>
			<div className="w-full md:w-3/12">
				<ul>
					{specs.slice(3, 6).map((item, index) => (
						<li key={`left-${index}`} className="mb-35">
							<SpecItem {...item} />
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default CarSpecSection;
