export function extractDescription(content: string) {
	const regex = /\[desc\](.*?)\[\/desc\]/s;
	const match = content.match(regex);

	if (match && match[1]) {
		return `<p>${match[1]}</p>`;
	}

	return '';
}

export function removeDescTags(content: string) {
	return content.replace(/\[\/?desc\]/g, '');
}
