import { useTranslation } from 'react-i18next';

import GreenlineImage from 'src/assets/images/green_line_075.svg';
import CarImage from 'src/assets/images/auto-abo-schweiz-günstig.webp';

function SubscriptionSection() {
	const { t, i18n } = useTranslation();

	const lng = i18n.language;

	return (
		<section className="sm:h-560 bg-[#edf3e1]">
			<div className="custom-container h-full">
				<div className="flex justify-center items-center h-full text-center">
					<div className="pt-24">
						{lng === 'de' && (
							<h1 className="mb-3 text-xl sm:text-32 font-normal sm:font-bold font-red-hat text-[#3e4143]">
								Auto{' '}
								<span className="relative">
									Abo
									<img
										className="absolute w-full left-0 -bottom-[2px]"
										src={GreenlineImage}
										alt="green_line"
										title="green_line"
									/>
								</span>{' '}
								Schweiz
							</h1>
						)}
						{lng === 'fr' && (
							<h1 className="mb-3 text-xl sm:text-32 font-normal sm:font-bold font-red-hat text-[#3e4143]">
								<span className="relative">
									Abonnement
									<img
										className="absolute w-full left-0 -bottom-[16px]"
										src={GreenlineImage}
										alt="green_line"
										title="green_line"
									/>
								</span>{' '}
								voiture Suisse
							</h1>
						)}

						{lng === 'en' && (
							<h1 className="mb-3 text-xl sm:text-32 font-normal sm:font-bold font-red-hat text-[#3e4143]">
								Car{' '}
								<span className="relative">
									Subscription
									<img
										className="absolute w-full left-0 -bottom-[16px]"
										src={GreenlineImage}
										alt="green_line"
										title="green_line"
									/>
								</span>{' '}
								Switzerland
							</h1>
						)}
						<div className="mb-35">
							<span>{t('subscribe_instead_of')}</span>
						</div>
						<img src={CarImage} alt="Auto Abo günstig" title="Auto Abo günstig" />
					</div>
				</div>
			</div>
		</section>
	);
}

export default SubscriptionSection;
