export const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const birthdayRegExp = /\b(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4})\b/;

export const Residences = [
	{
		value: 'residence-1',
		label: 'Aufenthaltsbewilligung C (Niederlassungsbewilligung)'
	},
	{
		value: 'residence-8',
		label: 'Aufenthaltsbewilligung Ci (Aufenthaltsbewilligung mit Erwerbstätigkeit)'
	},
	{
		value: 'residence-2',
		label: 'Aufenthaltsbewilligung B (Niederlassungsbewilligung)'
	},
	{
		value: 'residence-9',
		label: 'Aufenthaltsbewilligung B (Flüchtling)'
	},
	{
		value: 'residence-3',
		label: 'Aufenthaltsbewilligung L (Kurzaufenthalbewilligung)'
	},
	{
		value: 'residence-4',
		label: 'Aufenthaltsbewilligung G (Grenzgänger)'
	},
	{
		value: 'residence-10',
		label: 'Aufenthaltsbewilligung F (Vorläufig aufgenommene Ausländer)'
	},
	{
		value: 'residence-11',
		label: 'Aufenthaltsbewilligung N (Asylsuchende)'
	},
	{
		value: 'residence-12',
		label: 'Aufenthaltsbewilligung S (Schutzbedürftige)'
	},
	{
		value: 'residence-5',
		label: 'Diplomatenausweis'
	},
	{
		value: 'residence-6',
		label: 'Andere Aufenthaltsbewilligung'
	},
	{
		value: 'residence-7',
		label: 'Keine oder in Erstellung/Bearbeitung'
	}
];

export const CompanyIndustry = [
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_1', label: 'Auto & Verkehr' },
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_2', label: 'Bauen & Renovieren' },
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_3', label: 'Behörden und Verbände' },
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_4', label: 'Bildung und Wissenschaft' },
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_5', label: 'Computer & Elektronik' },
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_6', label: 'Dienstleistung' },
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_8', label: 'Finanzdienstleistung' },
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_16', label: 'Fitness' },
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_15', label: 'Food-Lieferdienst' },
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_7', label: 'Freizeit & Reisen' },
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_9', label: 'Gesundheit und Wellness' },
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_10', label: 'Gross- & Einzelhandel' },
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_12', label: 'Hotel & Gastronomie' },
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_11', label: 'Immobilien' },
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_13', label: 'Wohnen & Einrichten' },
	{ value: 'COM_INSURANCE_BUSINESS_COMPANY_BRANCH_14', label: 'Sonstige' }
];

export const CATEGORY_OPTIONS = [
	{ value: 'Bus', label: 'Bus' },
	{ value: 'Cabriolet', label: 'Cabriolet' },
	{ value: 'Coupé', label: 'Coupé' },
	{ value: 'Kleinwagen', label: 'Kleinwagen' },
	{ value: 'Kombi', label: 'Kombi' },
	{ value: 'Kompaktvan', label: 'Kompaktvan' },
	{ value: 'Limousine', label: 'Limousine' },
	{ value: 'Pick-up', label: 'Pick-up' },
	{ value: 'SUV / Geländewagen', label: 'SUV / Geländewagen' }
];

export const TIRES_CATEGORY_OPTIONS = [
	{ value: 'A', label: 'A' },
	{ value: 'B', label: 'B' },
	{ value: 'C', label: 'C' },
	{ value: 'D', label: 'D' },
	{ value: 'E', label: 'E' },
	{ value: 'F', label: 'F' },
	{ value: 'G', label: 'G' }
];

export const CAR_BRANDS = [
	'VW',
	'Seat',
	'Mercedes-Benz',
	'Fiat',
	'Mazda',
	'Toyota',
	'Skoda',
	'Dacia',
	'Citroen',
	'Renault',
	'Audi',
	'MINI',
	'Volvo',
	'Suzuki',
	'Opel',
	'Peugeot',
	'BMW',
	'Hyundai',
	'Kia',
	'Ford',
	'Tesla',
	'Smart',
	'Honda',
	'Jeep'
];

export const CAR_SEATS = [2, 4, 5, 7];
