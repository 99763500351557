import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import QuestionItem, { QuestionProps } from '../faq/question-item';

const questions: QuestionProps[] = [
	{
		question: 'Was ist ein Auto-Abo und wie funktioniert es?',
		answer:
			'Ein Auto-Abo ist eine flexible Alternative zum Kauf oder Leasing eines Autos. Es ermöglicht dir, ein Auto für eine bestimmte Zeit zu mieten, ohne sich um Reparaturen, Wartung oder Versicherungen kümmern zu müssen. Du wählst ein Fahrzeug aus der verfügbaren Flotte aus, legst die Laufzeit und Kilometerzahl fest und erhälst das Fahrzeug für die vereinbarte Zeit. Während dieser Zeit sind alle Wartungs- und Versicherungskosten abgedeckt und du zahlt nur einen monatlichen Mietpreis'
	},
	{
		question: 'Welche Kosten fallen beim Auto-Abo an?',
		answer:
			'Die Kosten für das Auto-Abo hängen von verschiedenen Faktoren ab, wie der Laufzeit, Kilometerzahl und dem ausgewählten Fahrzeug. Die genauen Kosten kannst auf unserer Website berechnen und allfällige Zusatzservices transparent prüfen.'
	},
	{
		question: 'Kann ich das Fahrzeug während der Laufzeit des Auto-Abos wechseln? ',
		answer:
			'Ja, es ist mögliche das Fahrzeug während der Laufzeit des Auto-Abos zu wechseln. Hierfür muss eine Frist von einem Monat eingehalten werden und das bestehende Abo muss mindestens sechs Monate aktiv sein'
	},
	{
		question: 'Welche Vorteile oder Nachteile bringt das Auto-Abo mit sich? ',
		html: `
				<p>
					Das Auto-Abo Modell wird immer beliebter, da Kunden gegen eine monatliche Gebühr ein Auto nutzen können, ohne es besitzen zu müssen. Hier sind einige potenzielle Vor- und Nachteile eines Auto-Abonnements
				</p>
				<br>
				<h3>Vorteile des Auto-Abo's:</h3>
				<ol style="padding-left:20px">
					<li>1. Flexibilität: Ein <strong>Auto-Abonnement</strong> bietet Flexibilität, da Kunden die Möglichkeit haben, das Fahrzeug je nach Bedarf zu wechseln. Dies ermöglicht es den Nutzern, verschiedene Fahrzeugmodelle auszuprobieren und an ihre wechselnden Anforderungen anzupassen.</li>
					<li>2. Keine langfristige Bindung: Im Gegensatz zu einem Autokauf oder Leasingvertrag gibt es bei uns keine langfristige Bindung. Kunden können das Abonnement monatlich kündigen, wenn sie das Auto nicht mehr benötigen.</li>
					<li>3. Inklusive Kosten: Die monatliche Gebühr beinhaltet Versicherung, Wartung, Reparaturen und andere laufende Kosten. Kunden müssen sich daher nicht um diese zusätzlichen Ausgaben kümmern und haben eine bessere Kontrolle über ihr Budget.</li>
					<li>4. Keine Anzahlung: Im Gegensatz zum Autokauf oder Leasing erfordert das Auto-Abo keine hohe Anzahlung. Kunden müssen lediglich die monatliche Abonnementgebühr und eine Monatsmiete Kaution entrichten.</li>
				</ol>
				<br>
				<h3>Nachteile des Auto-Abo's:</h3>
				<ol style="padding-left:20px">
					<li>1. Langfristige Kosten: Obwohl die monatliche Gebühr alle laufenden Kosten abdeckt zahlt man für die Nutzung des Fahrzeugs, während man beim Kauf eines Autos einen Vermögenswert aufbaut.</li>
					<li>2. Kilometerbegrenzung: Der Auto-Abo-Vertrag sieht eine fest vereinbarte Anzahl von Kilometern pro Monat inbegriffen. Wenn Kunden diese Grenze überschreiten, müssen sie zusätzliche Gebühren zahlen. Dies kann für Personen mit hohem Kilometerbedarf unpraktisch oder kostspielig sein.</li>
					<li>3. Verfügbarkeit von Modellen: Je nach Standort und Nachfrage kann die Verfügbarkeit bestimmter Fahrzeugmodelle eingeschränkt sein. Kunden haben möglicherweise nicht immer Zugang zu ihrem Wunschfahrzeug.</li>
					<li>4. Kein Eigentum: Bei einem Auto-Abonnement besitzt der Kunde das Fahrzeug nicht. Dies bedeutet, dass er i.d.R. keine Möglichkeit hat, das Fahrzeug nach Ablauf des Abonnements zu behalten oder weiterzuverkaufen.</li>
				</ol>
				<br>
				<p>Es ist wichtig, die individuellen Bedürfnisse und Prioritäten abzuwägen, bevor man sich für ein Auto-Abonnement entscheidet.</p>
			`
	},
	{
		question: 'Gibt es eine Mindestvertragslaufzeit und welche Kündigungsfristen gibt es?',
		answer:
			'Die Mindestvertragslaufzeit beträgt in der Regel 3, 6 oder 12 Monate. Danach kannst du das Abo monatlich kündigen. Die Kündigungsfrist beträgt dabei in der Regel einen Monat.'
	},
	{
		question: 'Auto-Abo vs. Leasing und Autokauf ',
		html: `
			<p>
				Ein <strong>Auto-Abo</strong> ist eine flexible Alternative zum Kauf oder Leasing eines Autos. Es lohnt sich für Menschen, die keine langfristige Bindung an ein bestimmtes Fahrzeug wünschen oder sich nicht verpflichten möchten, grosse Summen für den Kauf oder die Finanzierung eines Autos zu investieren. Das Auto-Abo bietet die Möglichkeit, das Auto für einen bestimmten Zeitraum zu mieten, in der Regel zwischen einem und zwölf Monaten, und beinhaltet oft alle Kosten wie Versicherung, Wartung und Reparaturen. Dies kann besonders attraktiv sein für Menschen, die keine hohe finanzielle Belastung auf einmal tragen möchten oder die sich nicht sicher sind, welches Auto am besten zu ihnen passt. Ein weiterer Vorteil des Auto-Abos ist, dass es oft auch die Möglichkeit bietet, das Fahrzeug jederzeit zu wechseln, je nach den Bedürfnissen und Vorlieben des Kunden.
			</p>
			<br>
			<p>Der wichtigste Unterschied zwischen dem Auto-Abo und dem Kauf eines Autos ist, dass beim Auto-Abo der Kunde das Fahrzeug nur für einen bestimmten Zeitraum gegen eine monatliche Gebühr nutzt und das Auto danach zurückgibt oder gegen ein anderes Modell tauscht. Beim Kauf hingegen wird der Kunde Eigentümer des Fahrzeugs und kann es langfristig nutzen oder verkaufen. Ein weiterer Unterschied besteht darin, dass beim Auto-Abo alle Kosten wie Versicherung, Wartung und Reparaturen in der monatlichen Gebühr enthalten sind, während der Käufer eines Autos diese Kosten selbst tragen muss.</p>
			<br>
			<p>Die Entscheidung zwischen Auto-Abo und Kauf hängt von den individuellen Bedürfnissen und Vorlieben ab. Das Auto-Abo kann eine gute Option sein, wenn man ein flexibles Mobilitätskonzept wünscht und keine langfristige Bindung eingehen möchte. Der Kauf eines Autos kann hingegen eine bessere Option sein, wenn man das Fahrzeug langfristig nutzen und möglicherweise auch verkaufen möchte</p>
		`
	},
	{
		question: 'Weshalb ist die Nachfrage nach Auto-Abos in der Schweiz am steigen? ',
		html: `
			<p>In der Schweiz gewinnt das Geschäftsmodell mit Auto-Abos immer mehr an Bedeutung. Immer mehr Menschen suchen nach einer flexiblen Möglichkeit, ein Auto zu fahren, ohne es zu besitzen. Auto-Abos bieten genau das: Man zahlt eine monatliche Gebühr und kann dann je nach Bedarf auf ein Auto zugreifen. Es gibt mehrere Gründe für das wachsende Interesse an Auto-Abos in der Schweiz:</p>
			<br>
			<ol style="padding-left:20px">
				<li>1. <strong>Flexibilität</strong>: Auto-Abos bieten Flexibilität, bieten Flexibilität, da die Nutzer je nach Bedarf das Fahrzeug wechseln können. Dies ist insbesondere für Personen attraktiv, die sich nicht langfristig an ein bestimmtes Fahrzeug binden wollen.</li>
				<br>
				<li>2. <strong>Kostentransparenz</strong>: Durch die Zusammenfassung aller Kosten in einer monatlichen Gebühr bieten Auto-Abos eine transparente Preisstruktur. Dies erleichtert die Planung der Ausgaben und vermeidet unerwartete Kosten.</li>
				<br>
				<li>3. <strong>Keine Anfangsinvestition</strong>: Im Gegensatz zum Kauf oder Leasing eines Autos erfordern Auto-Abos in der Regel keine grosse Anfangsinvestition. Dies macht sie finanziell erschwinglicher, insbesondere für Personen, die nicht über das für einen Autokauf erforderliche Eigenkapital verfügen.
				</li>
				<br>
				<li>4. <strong>Vielfältige Fahrzeugauswahl</strong>: Auto-Abonnement-Anbieter bieten oft eine grosse Auswahl an Fahrzeugmodellen an, vom Kleinwagen bis zum Luxusauto. Diese Vielfalt ermöglicht es den Kunden, das Fahrzeug auszuwählen, das ihren Bedürfnissen und ihrem Lebensstil am besten entspricht.</li>
				<br>
				<li>5. <strong>Inkludierte Dienstleistungen</strong>: Viele Auto-Abo-Pakete beinhalten zusätzliche Dienstleistungen wie Versicherung, Wartung und Pannenhilfe. Dies reduziert den administrativen Aufwand und die Sorgen der Kunden im Zusammenhang mit der Fahrzeugwartung.
				</li>
				<br>
				<li>6. <strong>Urbanisierung und Umweltbewusstsein</strong>: In urbanen Gebieten, wo der Besitz eines eigenen Autos mit Parkplatzproblemen und Umweltbelastungen verbunden sein kann, bevorzugen viele Menschen alternative Mobilitätslösungen wie Auto-Abos. Diese ermöglichen es ihnen, auf eine breitere Palette von Transportmitteln zuzugreifen, wenn sie sie benötigen, ohne sich um Parkplätze oder Emissionen sorgen zu müssen.</li>
				<br>
				<li>7. <strong>Technologische Innovationen</strong>: Fortschritte in der Technologie haben die Verwaltung von Auto-Abos effizienter und benutzerfreundlicher gemacht. Mobile Apps ermöglichen es den Nutzern, Fahrzeuge zu reservieren, ihre Abonnementdetails zu verwalten und sogar Fahrzeuge zu öffnen und zu starten, alles mit nur wenigen Klicks auf ihrem Smartphone.</li>
				<br>
				<li>8. <strong>Zusammenfassung</strong>: Die steigende Nachfrage nach Auto-Abonnements in der Schweiz ist das Ergebnis eines wachsenden Bedarfs an flexiblen und kostengünstigen Mobilitätslösungen. Durch die Kombination von Flexibilität, Kostentransparenz, Fahrzeugvielfalt und Inklusivleistungen erfüllen Auto-Abos die Bedürfnisse einer breiten Kundschaft, vom Stadtbewohner bis zum umweltbewussten Autofahrer.
				</li>
			</ol>
		`
	},
	{
		question: 'Wann und für wen lohnt sich ein Auto-Abo?',
		html: `
			<p>Ein <strong>Auto-Abo</strong> ist eine flexible Alternative zum Kauf oder Leasing eines Autos. Es lohnt sich für Menschen, die keine langfristige Bindung an ein bestimmtes Fahrzeug wünschen oder sich nicht verpflichten möchten, grosse Summen für den Kauf oder die Finanzierung eines Autos zu investieren. Das Auto-Abo bietet die Möglichkeit, das Auto für einen bestimmten Zeitraum zu mieten, in der Regel zwischen einem und zwölf Monaten, und beinhaltet oft alle Kosten wie Versicherung, Wartung und Reparaturen. Dies kann besonders attraktiv sein für Menschen, die keine hohe finanzielle Belastung auf einmal tragen möchten oder die sich nicht sicher sind, welches Auto am besten zu ihnen passt. Ein weiterer Vorteil des Auto-Abos ist, dass es oft auch die Möglichkeit bietet, das Fahrzeug jederzeit zu wechseln, je nach den Bedürfnissen und Vorlieben des Kunden.</p>
		`
	},
	{
		question: 'Warum soll ich mich für simpcar entscheiden?',
		html: `
			<p>Simpcar ist ein Startup, das sich auf Auto-Abos spezialisiert hat. Ein Auto-Abo ist im Wesentlichen eine Art langfristige Miete, bei der Kunden ein Auto für einen bestimmten Zeitraum nutzen können, ohne sich um Wartung, Reparaturen oder Versicherung kümmern zu müssen.</p>
			<br>
			<p>Die Vorteile eines Auto-Abos sind zahlreich. Zum einen ermöglicht es Kunden, flexibel auf ihre Mobilitätsbedürfnisse zu reagieren, ohne sich auf einen langfristigen Kauf oder Leasingvertrag festlegen zu müssen. Zum anderen sind alle Kosten, ausser Kraftstoff, in der monatlichen Rate enthalten, was eine transparente und planbare finanzielle Situation schafft.</p>
			<br>
			<p>Darüber hinaus entfällt bei einem Auto-Abo der Wertverlust, der normalerweise beim Kauf eines Fahrzeugs auftritt. Auch können Kunden bei Bedarf jederzeit das Modell oder die Ausstattung wechseln, was eine grosse Auswahl an Fahrzeugen und eine hohe Flexibilität bietet.</p>
			<br>
			<p>Insgesamt bietet unser Auto-Abo eine moderne und kosteneffiziente Alternative zum klassischen Autokauf oder Leasing.</p>
			<br>
			<p>Unsere Kunden sind äusserst zufrieden mit unseren Services, insbesondere mit unserem Auto-Abo und Kundendienst. Wir haben uns zum Ziel gesetzt, unseren Kunden eine erstklassige Erfahrung zu bieten, und das spiegelt sich in den vielen positiven Bewertungen wider, die wir von ihnen erhalten haben.</p>
			<br>
			<p>Unser Kundendienstteam ist immer bereit, schnell und professionell auf Fragen und Anliegen zu reagieren und unseren Kunden jederzeit zu helfen. Wir schätzen das Feedback unserer Abonnementen und nutzen es, um uns kontinuierlich zu verbessern.</p>
			<br>
			<p>Auch unser Auto-Abo wird von unseren Kunden hoch geschätzt, da es ihnen eine flexible, bequeme und kosteneffiziente Alternative zum Autokauf bietet. Die monatliche Rate enthält alle Kosten, außer Kraftstoff, und es gibt keine versteckten Gebühren oder langfristigen Verpflichtungen. Kunden können jederzeit das Modell oder die Ausstattung wechseln, was ihnen eine große Auswahl an Fahrzeugen und eine hohe Flexibilität bietet.</p>
			<br>
			<p>Wir sind stolz darauf, dass unsere Kunden mit unseren Services und unserem Auto-Abo zufrieden sind und werden uns weiterhin bemühen, ihre Erwartungen zu übertreffen.</p>
		`
	}
];

function QuestionsSection() {
	const { t } = useTranslation();
	const [selected, setSelected] = useState('');

	return (
		<section className=" bg-white">
			<div className="custom-container pt-10">
				<div className="mx-auto pb-12 md:max-w-[80%] ">
					<h3 className="mb-6 font-normal text-center text-primary font-red-hat text-22 lg:text-28">
						{t('frequently_asked_questions')}
					</h3>
					<div className="my-2.5 bg-white py-4 px-4 lg:px-10 rounded-2xl">
						{questions.map((item, index) => (
							<QuestionItem
								key={`${index}-${selected}`}
								selected={`${index}` === selected}
								index={`${index}`}
								question={item.question}
								answer={item.answer}
								html={item.html}
								onClick={(val) => {
									setSelected(val);
								}}
							/>
						))}
					</div>
				</div>
			</div>
		</section>
	);
}

export default QuestionsSection;
